import React from 'react';
import { message, Tooltip } from 'antd';
import { findSubStringExits, isValid, isValidArray, isValidString } from './../../utils/utilities';
import { SeatClickTicketPopUp } from './../../components/ticket-summary-components/seat-click-ticket-popup.component';
import {
    ACTION_BOOK_BBA_TICKET_ALLOWED,
    ACTION_BOOK_BBO_TICKET_ALLOWED
} from './../../utils/constant';
import { ShowMultiplePassengers } from './show-multiple-passengers.component';

const isPassengerEntryExits=(object)=>{
    let {
        full_name,
        passengers
    } = object;
    if(isValid(full_name)){
        return true;
    }else{
        if(isValidArray(passengers)&&passengers.length>0){
            let bool = false;
            passengers.forEach((ele)=>{
                if(isValid(ele.full_name)){
                    bool = true;
                }   
            })
            return bool
        }else{
            return false;
        }
    }
} 

const getGender=(seatGender)=>{
    if(isValid(seatGender)&&seatGender==="F"){
        return "("+seatGender+")"
    }else{
        return ''
    }
}

const getTranformValue =(val)=>{
    if(isValid(val)){
        return val+'deg';
    }
    return '0deg';
}


const getReserveTranformValue =(val)=>{
    if(isValid(val)){
        return -val+'deg';
    }
    return '0deg';
}


export const Seat = (props) => {
    let {
        classname,
        object,
        onSeatCLick,
        isSelected,
        showFarePopOver,
        dontAllowSelection=false,
        changeOldLayout = false
    } = {...props};

    const updateSelectedSeat = (e) => {
        if(!dontAllowSelection){
            isSelected = onSeatCLick(object);
        }
    };

    classname = (isSelected) ?  classname + " selected" : classname;

    const styles = { 
        transform: `rotate(${getTranformValue(object.seat_orientation)})` ,
        transformOrigin: '0'
    };

    const seatNoStyles = { 
        transform: `rotate(${getReserveTranformValue(object.seat_orientation)})` ,
        // transformOrigin: '0'
    };
    
        let boarded = '';
        if(object.boarded){
            if(object.sleeper ==1){
                boarded = <div style={{marginTop:'18px'}}>👋</div>
            }else{
                boarded = <div style={{position:'absolute',fontSize:'8px',top:0,right:1}}>👋</div>
            }
        }
    if(object.seat_number==="Empty"){
        if(object.sleeper){
            return <div className='sleeperGap' style={styles}></div>
        }else{
            return <div className='seatGap' style={styles}></div>
        }
    }

    if(changeOldLayout){
        let passengers = object.passengers;
        return(
            <div className={classname}
                style={styles}
                onClick={(e) => {
                    if(["Confirmed", "Blocked"].includes(object.seat_status)) {
                        updateSelectedSeat(e)
                    }
                    else {
                        message.error("Cannot select empty seats for change seat layout!")
                    }
                }} data-testid = {object.seat_number} id={object.seat_number}
            >
                <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                {isValidArray(passengers)&&passengers.length>0&&
                    <ShowMultiplePassengers passengers={object.passengers} />
                }
            </div>
        )
    }
    else if(object.seat_status==="Blocked"){
        let pickUp='',dropUp='';

        let toolTipInfo = (object.full_name === null || object.phone_number === null) ? 
        `Blocked by ${object.blocked_by}` :
        `Blocked by ${object.blocked_by} for ${object.full_name} (${object.phone_number})`;

        let classNameOTA ='';
        if(findSubStringExits(object.blocked_by,'redbus')||findSubStringExits(object.blocked_by,'abhibus')||findSubStringExits(object.blocked_by,'paytm')||findSubStringExits(object.blocked_by,'maven')){
            classNameOTA="ota-blocked";
        }
        
        if(isValid(object.pickup_location)){
            pickUp = `Pick up: ${object.pickup_location} `;
        }

        if(isValid(object.drop_location)){
            dropUp = `Drop: ${object.drop_location}`;
        }

        let passengers = isValid(object.passengers)?object.passengers:[];
        return (
            <Tooltip title={<div>
                {isValidString(toolTipInfo)&&
                    <>
                        <span>{toolTipInfo}</span><br />
                    </>
                }
                {isValidString(pickUp)&&
                    <>
                        <span>{pickUp}</span><br />
                    </>
                }
                {isValidString(dropUp)&&
                    <>
                        <span>{dropUp}</span><br />
                    </>
                }
            </div>} trigger={"hover"} >
                <div className={`${classname} ${classNameOTA}`} style={styles} 
                     onClick={(e) => {
                        if(isValid(object.ticket_id)){
                            if(global.userName===object.blocked_by){
                                updateSelectedSeat(e);
                            }else{
                                if(global.allowedActions[ACTION_BOOK_BBO_TICKET_ALLOWED]){
                                    updateSelectedSeat(e);
                                }else{
                                    message.error("You dont have permission to book seat!")
                                }
                            }
                        }else{
                            if(global.allowedActions[ACTION_BOOK_BBA_TICKET_ALLOWED]){
                                updateSelectedSeat(e);
                            }else{
                                message.error("You dont have permission to book seat!")
                            }
                        }
                    }} data-testid = {object.seat_number} id={object.seat_number}>
                        <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                        <div className="seat-gender" style={seatNoStyles}>{getGender(object.gender)}</div>
                        {boarded}
                        {isValidArray(passengers)&&passengers.length>1&&
                            <ShowMultiplePassengers passengers={passengers} />
                        }
                    {/* <div className="seat-line"/> */}
                </div>
            </Tooltip>
        );
    }else if(object.seat_status==="Confirmed"){
        let pickUp='',dropUp='';
        let bookedName = (object.full_name === null || object.phone_number === null) ? 
        `Booked by ${object.blocked_by}` :
        `Booked by ${object.blocked_by} for ${object.full_name} (${object.phone_number}) `;

        if(isValid(object.pickup_location)){
            pickUp = `Pick up: ${object.pickup_location} `;
        }

        if(isValid(object.drop_location)){
            dropUp = `Drop: ${object.drop_location}`;
        }

        let passengers = object.passengers;
        let children = <div>
                            <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                            <div className="seat-gender" style={seatNoStyles}>{getGender(object.gender)}</div>
                            {boarded}
                        </div>
        let ticketIds = [];
        if(isValidArray(passengers)){
            passengers.forEach(element => {
                if(passengers.length == 1){
                    bookedName = `Booked by ${object.blocked_by} for ${element.full_name} (${element.phone_number})`;    
                    if(isValid(element.pickup_location_name)){
                        pickUp = `Pick up: ${element.pickup_location_name} `;
                    }
            
                    if(isValid(element.drop_location_name)){
                        dropUp = `Drop: ${element.drop_location_name}`;
                    }
                }
                ticketIds.push(element.ticket_id);
            });
        }else{
            ticketIds.push(object.ticket_id);
        }
        if(isPassengerEntryExits(object)){
            return (
                <SeatClickTicketPopUp 
                ticketId = {ticketIds}
                seatNumber = {object.seat_number}
                gender = {object.gender} 
                agentId = {object.agent_id}
                children= {
                        <div className={classname}  style={styles}
                             data-testid = {object.seat_number} id={object.seat_number}>
                                {(isValidArray(passengers)&&passengers.length > 1)?
                                    <div>
                                        {children}
                                        <ShowMultiplePassengers passengers={object.passengers} />
                                    </div>
                                    :
                                <Tooltip title={<div>
                                    {isValidString(bookedName)&&
                                        <>
                                            <span>{bookedName}</span><br />
                                        </>
                                    }
                                    {isValidString(pickUp)&&
                                        <>
                                            <span>{pickUp}</span><br />
                                        </>
                                    }
                                    {isValidString(dropUp)&&
                                        <>
                                            <span>{dropUp}</span><br />
                                        </>
                                    }
                                </div>} trigger={"hover"}>
                                   {children}
                                </Tooltip>
                                }
                        </div>
                    }
                />         
        )
    }else{
        return(
            <div className={classname} 
            style={styles}            
            onClick={(e) => {updateSelectedSeat(e)}} data-testid = {object.seat_number} id={object.seat_number}>
                           <Tooltip title={<div>
                                    {isValidString(bookedName)&&
                                        <>
                                            <span>{bookedName}</span><br />
                                        </>
                                    }
                                    {isValidString(pickUp)&&
                                        <>
                                            <span>{pickUp}</span><br />
                                        </>
                                    }
                                    {isValidString(dropUp)&&
                                        <>
                                            <span>{dropUp}</span><br />
                                        </>
                                    }
                            </div>} trigger={"hover"}>
                            {children}
                            </Tooltip>
        </div>
        )
    }
}else{
    let passengers = object.passengers;
    if(showFarePopOver){
        let toolTipInfo = `Fare: ${object.fare}`;
        if(isValidArray(passengers)&&passengers.length > 0){
            return (
                <div 
                className={classname} 
                style={styles} 
                onClick={(e) => {updateSelectedSeat(e)}} data-testid = {object.seat_number} id={object.seat_number}
                >
                    <Tooltip title={toolTipInfo} trigger={"hover"} >
                        <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                    </Tooltip>
                    <ShowMultiplePassengers passengers={object.passengers} />
                </div>
            );
        }else{
            return (
                <Tooltip title={toolTipInfo} trigger={"hover"} >
                    <div className={classname} 
                        style={styles}            
                        onClick={(e) => {updateSelectedSeat(e)}} data-testid = {object.seat_number} id={object.seat_number}>
                                <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                    </div>
                </Tooltip>
            );
        }
        
    }else{
        return (
            <div className={classname} 
                style={styles}            
                onClick={(e) => {updateSelectedSeat(e)}} data-testid = {object.seat_number} id={object.seat_number}>
                    <div className="seat-number" style={seatNoStyles}>{object.seat_number}</div>
                    {isValidArray(passengers)&&passengers.length>0&&
                        <ShowMultiplePassengers passengers={object.passengers} />
                    }
            </div>
        );
    }
}   
};
