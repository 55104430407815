import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveOperators, getTravelsDetailsByOperatorCode, updateTravelConfig } from "../../actions/admin.action";
import { activeOperatorsSelector, selectedOperatorSelector } from "../../selector/admin.selector";
import { Divider, Col, Form, Select, Row, Checkbox, Input ,Button, message} from 'antd';
import '../../styles/operator-config-page.style.scss';
import { isValidArray } from "../../utils/utilities";
const { Option } = Select;

const printOperatorOption = (option) => {
    return <Option  value={option.operator_code} data={option} key={option.operator_code}>{option.name}</Option>
};


export const OperatorConfigPage = () => {

    const dispatch = useDispatch();
    const activeOperators = useSelector(activeOperatorsSelector);
    const selectedOperator = useSelector(selectedOperatorSelector);
    
    const [form] = Form.useForm();

    useEffect(()=>{
        dispatch(getActiveOperators());    
    },[]);

    const onFinish=(values)=>{
        dispatch(updateTravelConfig(selectedOperator.operator_code,values,()=>{
            message.success("Updated successfully.")
        },()=>{}));
    }

    const onFinishFailed=()=>{

    }

    useEffect(()=>{
        if(isValidArray(selectedOperator)){
            form.setFieldsValue({
                use_remarks:selectedOperator.use_remarks,
                blocked_tickets_in_trip_sheet:selectedOperator.blocked_tickets_in_trip_sheet == 1?true:false,
                overdue_amount:selectedOperator.overdue_amount,
                send_payment_link:selectedOperator.send_payment_link == 1?true:false,
                whatsapp_message:selectedOperator.whatsapp_message==1?true:false,
                whatsapp_number:selectedOperator.whatsapp_number,
                whatsapp_image:selectedOperator.whatsapp_image,
                time_required_before_close:selectedOperator.time_required_before_close,
                is_use_review_feature:selectedOperator.is_use_review_feature == 1?true:false,
                send_marketing_sms:selectedOperator.send_marketing_sms == 1 ? true:false,
                maximum_ladies_reservation:selectedOperator.maximum_ladies_reservation,
                is_reschedule:selectedOperator.is_reschedule == 1 ? true :false,
                app_url:selectedOperator.app_url,
                show_booking_form_in_copilot:selectedOperator.show_booking_form_in_copilot == 1 ? true : false,
                about_tours:selectedOperator.about_tours,
                google_analytics_id:selectedOperator.google_analytics_id,
                dynamic_pricing_enabled:selectedOperator.dynamic_pricing_enabled == 1?true:false,
                dynamic_pricing_provider:selectedOperator.dynamic_pricing_provider,
                vender_id:selectedOperator.vender_id,
                vender_key:selectedOperator.vender_key,
                admin_client_url:selectedOperator.admin_client_url,
                multiple_partner:selectedOperator.multiple_partner == 1 ? true:false
            });
        }
    },[selectedOperator]);

    return (
        <div className="operator-config-page">
                <Row justify="center" align="center">
                    {isValidArray(activeOperators)?
                        <Col>
                            <Select
                                htmlType="submit"
                                className="operatorSelect"
                                showSearch
                                placeholder="Select operator"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value)=>{
                                    dispatch(getTravelsDetailsByOperatorCode(value,()=>{},()=>{}));
                                }}
                            >
                                {activeOperators.map(printOperatorOption)}
                            </Select>                         
                        </Col>
                        :null      
                    }
                </Row>
                <Form
                    initialValues={{
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    >
                        <div className="blockAgentPortal">
                            <Divider orientation="left">Agent Portal</Divider>
                            <Row gutter={[16,16]}>
                                <Col>
                                    <Form.Item
                                        label={"Use Remarks"}
                                        name="use_remarks"
                                        rules={[
                                            {
                                            required: false,
                                            message: 'Please input your operator!',
                                            },
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Blocked tickets in trip sheet"}
                                        name="blocked_tickets_in_trip_sheet"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                            required: false,
                                            message: 'Please input your operator!',
                                            },
                                        ]}
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Overdue Amount"}
                                        name="overdue_amount"
                                        rules={[
                                            {
                                            required: false
                                            },
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Send Payment Link"}
                                        name="send_payment_link"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="blockAgentPortal">
                            <Divider orientation="left">WhatsApp</Divider>
                            <Row gutter={[16,16]}>
                                <Col>
                                    <Form.Item
                                        label={"Whatsapp Message"}
                                        name="whatsapp_message"
                                        rules={[
                                            {
                                            required: false
                                            },
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>                            
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Whatsapp Number"}
                                        name="whatsapp_number"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>                            
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Whatsapp Image"}
                                        name="whatsapp_image"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>                            
                                </Col>
                            </Row>
                        </div>
                        <div className="blockAgentPortal">
                            <Divider orientation="left">Backend</Divider>
                            <Row gutter={[16,16]}>
                                <Col>
                                    <Form.Item
                                        label={"Time Required Before Close"}
                                        name="time_required_before_close"
                                        rules={[
                                            {
                                            required: false
                                            },
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>                            
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Use Review Feature"}
                                        name="is_use_review_feature"
                                        rules={[
                                            {
                                            required: false
                                            },
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Send Marketing SMS"}
                                        name="send_marketing_sms"
                                        rules={[
                                            {
                                            required: false
                                            },
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>          
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Maximum Ladies Reservation"}
                                        name="maximum_ladies_reservation"
                                        rules={[
                                            {
                                            required: false
                                            },
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Reschedule Allowed"}
                                        name="is_reschedule"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <div className="blockAgentPortal">
                            <Divider orientation="left">Apps</Divider>
                            <Row gutter={[16,16]}>
                                <Col>
                                    <Form.Item
                                        label={"App URL"}
                                        name="app_url"
                                        rules={[
                                            {
                                            required: false,
                                            message: 'Please input your operator!',
                                            },
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Show Booking Form In Copilot"}
                                        name="show_booking_form_in_copilot"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="blockAgentPortal">
                            <Divider orientation="left">Customer Website</Divider>
                            <Row>
                                <Col>
                                    <Form.Item
                                        label={"About Tours"}
                                        name="about_tours"
                                        rules={[
                                            {
                                            required: false,
                                            message: 'Please input your operator!',
                                            },
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Google Analytics Id"}
                                        name="google_analytics_id"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="blockAgentPortal">
                            <Divider orientation="left">Dynamic pricing</Divider>
                            <Row gutter={[16,16]}>
                                <Col>
                                    <Form.Item
                                        label={"Dynamic Pricing Enabled"}
                                        name="dynamic_pricing_enabled"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Dynamic Pricing Provider"}
                                        name="dynamic_pricing_provider"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Vender Id"}
                                        name="vender_id"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Vender Key"}
                                        name="vender_key"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        >
                                        <Input />
                                    </Form.Item>    
                                </Col>
                            </Row>
                        </div>
                        <div className="blockAgentPortal">
                            <Divider orientation="left">Others</Divider>
                            <Row>
                                <Col>
                                    <Form.Item
                                        label={"Admin Client URL"}
                                        name="admin_client_url"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                    >
                                        <Input style={{maxWidth:'500px'}}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={"Multiple Partne"}
                                        name="multiple_partner"
                                        rules={[
                                            {
                                            required: false
                                            }
                                        ]}
                                        valuePropName="checked"
                                        >
                                        <Checkbox />
                                    </Form.Item>                            
                                </Col>
                            </Row>
                        </div>
                        <Row justify="center" align="center" gutter={[16,16]}>
                            <Col>
                                <Form.Item label={null}>
                                    <Button type="primary" htmlType="submit">
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                </Form>
        </div>
    );                      
}


