import React ,{useContext, useEffect, useState} from "react";
import { Button , InputNumber , Table  , Row , Col , Tooltip , Typography,Modal, message, Input, Switch } from "antd";
import { checkActionAllowed, isValidString , getArrayfromString, isValidArray, isValid, isValidNumber  } from "../../utils/utilities";
import { ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL } from './../../utils/constant';
import './edit-fare.style.scss';
import { getAPIDateFormat, getFormatedDate } from './../../utils/date.utils';
import { getRoutesWithFareJson ,updateScheduleTripFare } from './edit-fare.module';
import moment from "moment";
import {
    ArrowDownOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    MinusOutlined,
    CopyOutlined
   } from '@ant-design/icons';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { OriginDestionationFilter } from "../common-components/origin-destination-filter/origin-destination-filter.component";
import { DeviceContext } from "../device-provider/device-provider";
import ZoomComponent from './../custom-components/zoom-component/zoom.component';
import { getViacitiesDetails, updateTripDynamicPricing } from "../../actions/admin.action";
import { roundNumber } from "../../utils/math.utils";
import { DynamicPriceBtn } from "./dynamic-price.component";

const {
    Text 
} = Typography;

let plusBtn='plus',minusBtn='minus';


export const EditFareComponent = (props) => {

    const{
        travelDate,
        scheduleId,
        seatGroupIds,
        selectedBus,
        onFareUpdateSuccess,
        tripRouteId,
        supportedFare,
        onCancel,
        selectedDates,
        showGetPriceButton=false,
        tripDynamicPricing,
        onGetPriceClick=()=>{},
        showTravelDate=true
    }=props;

    const [routes, setRoutes] = useState([]);
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [originsFiltered, setOriginsFiltered] = useState([]);
    const [destinationsFiltered, setDestinationsFiltered] = useState([]);
    const [value, setValue] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [btnType, setBtnType] = useState(null);
    const [errorHandle, setErrorHandle] = useState(null);

    const dispatch = useDispatch();

    const {
        isMobile
    }=useContext(DeviceContext);

    const dynamicPricingEnabled = global.dynamicPricingEnabled;

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }

    useEffect(()=>{
        showModal();
    },[travelDate])
    
    const showModal = () => {
        if(isValid(travelDate)){
            setLoading(true);
            getViacitiesDetails(scheduleId,moment(travelDate),(data)=>{
                let {formatedData,filteredOrigins,filteredDestinations} = getRoutesWithFareJson(data,seatGroupIds);
                setOrigins(filteredOrigins);
                setDestinations(filteredDestinations);
                setRoutes(formatedData);
                setFilteredRoutes(formatedData);
                setLoading(false);
            },()=>{},0);
        }
    };

    const onOriginChange =(values)=>{
        let formatedData = [];
        setOriginsFiltered(values);
        routes.forEach(element => {
            if((values.indexOf(element.origin) !== -1 || values.length === 0) && (destinationsFiltered.length === 0 || destinationsFiltered.indexOf(element.destination) !== -1)){
                formatedData.push(element);
            }
        });
        setFilteredRoutes(formatedData);
    }

    const onDestionationChange =(values)=>{
        let formatedData = [];
        setDestinationsFiltered(values);
        routes.forEach(element => {
            if((values.indexOf(element.destination) !== -1 || values.length === 0) && (originsFiltered.length === 0 || originsFiltered.indexOf(element.origin) !== -1)){
                formatedData.push(element);
            }
        });
        setFilteredRoutes(formatedData);
    }

    const handleOk = () => {
    };

    const handleCancel = () => {
        onCancel();
    };

    function onChangeDynamicPricing(checked) {
        updateTripDynamicPricing(scheduleId, travelDate, checked, (data)=>{message.success(data)}, (err)=> {message.error(err)});
      }
      
      const formateErrorJsonObject = (fare,index,key,errFormat)=>{
        
        if(fare<201){
            if(!isValidArray(errFormat)){
                errFormat = {}
            }
            if(isValidArray(errFormat[index])){
                errFormat[index].push(key);
            }else{
                errFormat[index] = [key];
            }
            
        }
        return errFormat;
      }
 
    const handleUpdateFare=()=>{
        let errFormat = null;
        let tempFilteredRoutes = [...filteredRoutes];
        
        if (!supportedFare) {
            tempFilteredRoutes.map((tempRoutes,index) => {
                let fareJson = tempRoutes.fare_json;
                if(isValidString(fareJson)){
                    fareJson = JSON.parse(fareJson);
                    let fare = -1;
                    for (const [key, value] of Object.entries(fareJson)) {
                        errFormat = formateErrorJsonObject(value,index,key,errFormat);
                        if (fare<0) {
                            fare = value;
                        }
                        else {
                            if (fare>value) {
                                fare = value;
                            }
                        }
                    }
                    if (fare>0) {
                        tempFilteredRoutes[index]['fare'] = fare;
                    }
                }
            })
        }else{
            tempFilteredRoutes.map((tempRoutes,index) => {
                errFormat = formateErrorJsonObject(tempRoutes.fare,index,'fare',errFormat);
                let fareJson = tempRoutes.fare_json;
                if(isValidString(fareJson)){
                    fareJson = JSON.parse(fareJson);
                    for (const [key, value] of Object.entries(fareJson)) {
                        errFormat = formateErrorJsonObject(value,index,key,errFormat);
                    }
                }
            })
        }
        
        if(isValidArray(errFormat)){
            setErrorHandle(errFormat);
            message.error("Minimum fare should be 200");
        }else{
            setErrorHandle(errFormat);
            setLoading(true);
            updateScheduleTripFare(tempFilteredRoutes,selectedDates,scheduleId,selectedBus,true,null,()=>{
                setLoading(false);
                handleOk();
                onFareUpdateSuccess(tripRouteId);
            },(emsg)=>{
                setLoading(false);
                handleOk();
                message.error(emsg);
            });
        }
    }
    
    const getFare=(intialFare,addAmt)=>{
        if(isValid(addAmt)){
            if(btnType == plusBtn){
                return roundNumber(intialFare+addAmt);
            }else{
                return roundNumber(intialFare-addAmt);
            }

        }else{
            return intialFare;
        }
    }

    if(checkActionAllowed(ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL)){

        const copyFirstFareToAllRoutes =()=>{
            let fare = null , seatsArray = [] ,fareJson ={} , fareJsonString ='';

            for(var i=0;i<filteredRoutes.length;i++){
                    if(i == 0){
                        if(isValid(seatGroupIds)){
                            seatsArray=getArrayfromString(seatGroupIds);
                        }
                        if(supportedFare){
                            fare = filteredRoutes[0]['fare'];
                        }else{
                            let fareJson = filteredRoutes[0]['fare_json'];
                            let fareJsonObject=JSON.parse(fareJson);
                            fare = fareJsonObject[seatsArray[0]];
                        }
                        seatsArray.forEach((key)=>{
                                // Note first fare
                                fareJson[key] = fare;
                        });
                        fareJsonString = JSON.stringify(fareJson);
                    }

                    filteredRoutes[i]["fare"] = fare;
                    filteredRoutes[i]["fare_json"] = fareJsonString;
                    filteredRoutes[i]["is_fare_edited"] = true;
                    
                }
            setFilteredRoutes([...filteredRoutes]);
          }

          const increORdecreFareBy=(amt)=>{
            let fare = null , seatsArray = [] ,fareJson ={} , fareJsonString ='';

            for(var i=0;i<filteredRoutes.length;i++){
                    if(i == 0){
                        if(isValid(seatGroupIds)){
                            seatsArray=getArrayfromString(seatGroupIds);
                        }
                    }

                    filteredRoutes[i]["fare"] = getFare(filteredRoutes[i]["fare"],amt);
                    let fareJsonObject=JSON.parse(filteredRoutes[i]['fare_json']);

                    seatsArray.forEach((key)=>{
                        fareJson[key] = getFare(fareJsonObject[key],amt);
                    });
                    fareJsonString = JSON.stringify(fareJson);
                    
                    filteredRoutes[i]["fare_json"] = fareJsonString;
                    filteredRoutes[i]["is_fare_edited"] = true;
                    
                }
            setFilteredRoutes([...filteredRoutes]);
            resetModal()
          }

          const resetModal=()=>{
            setIsVisible(false);
            setValue(0);
          }

          const copyFirstRowFareToAllRoutes =()=>{
            for(var i=0;i<filteredRoutes.length;i++){
                    filteredRoutes[i]["fare"]=filteredRoutes[0]['fare'];
                    let fareJson = filteredRoutes[0]['fare_json'];
                    filteredRoutes[i]["fare_json"]=fareJson;
                    filteredRoutes[i]["is_fare_edited"]=true;
                }
            setFilteredRoutes([...filteredRoutes]);
          }

        const columns=[
            {
                title: '',
                dataIndex: 'origin',
                key: 'origin',
                render: (text,row) => {
                    return (
                        <div>
                            {row.origin} - {row.destination}
                        </div>
                    )
                }
            }
        ];

        // let childrenColumns = [];

        if(supportedFare === 1){
            columns.push({
                title: <div>
                            <Tooltip placement="topLeft" title={"With Out GST"}>
                                <Text>Fare</Text>
                            </Tooltip>
                       </div>,
                dataIndex: 'fare',
                key: 'fare',
                render: (text,rowIndex ,viewIndex) => {
                    let dataIndex='fare';
                    let key = rowIndex.id;
                    const index = filteredRoutes.findIndex(item => key === item.id);
                    return renderComponentTable({text,rowIndex ,viewIndex,dataIndex,index});
                    }
            });
        }

        if(isValidString(seatGroupIds)){
            let seatsArray=getArrayfromString(seatGroupIds);
            seatsArray.forEach(fareJsonKey => {
                columns.push({
                title: <div>
                                <Tooltip placement="topLeft" title={"With Out GST"}>
                                    <Text>{fareJsonKey}</Text>
                                </Tooltip>
                        </div>,
                dataIndex:'fare_json',
                key: 'fare_json',
                render: (text,rowIndex ,viewIndex) => {
                  let dataIndex='fare_json';
                  let key = rowIndex.id;
                  const index = filteredRoutes.findIndex(item => key === item.id);
                  return renderComponentTable({text,rowIndex ,viewIndex,dataIndex,index,fareJsonKey});
                  }
              })
            });
          }

        const renderComponentTable=({text,dataIndex,index,fareJsonKey})=>{
            let fareJsonObject={};
            let asError =false;
            if(!isValid(fareJsonKey)){
                asError = isValidArray(errorHandle)&&errorHandle[index].indexOf("fare")!=-1
            }else{
                asError = isValidArray(errorHandle)&&errorHandle[index].indexOf(fareJsonKey)!=-1
            }
            if(isValidString(fareJsonKey)&&isValidString(text)){
              fareJsonObject=JSON.parse(text);
              text=fareJsonObject[fareJsonKey];
            }
                if(text<201){
                    asError =true;
                }else{
                    asError = false;
                }
                
              return {
                props: {
                  className:'',
                },
               children:  <div style={{maxWidth:'60px'}}>
                              <Row type="flex" justify='space-between' >
                                <Col>
                                    <InputNumber 
                                            className={asError?"fare-input-error edit-fare-input":"edit-fare-input"}
                                            value={text}
                                            onChange={(value)=>{
                                                // let value = e.target.value;
                                                if(isValid(value) && value.length > 0){
                                                    value = Number(value);
                                                }
                                                if(isValidString(fareJsonKey)){
                                                    fareJsonObject[fareJsonKey]=value;
                                                    filteredRoutes[index][dataIndex]=JSON.stringify(fareJsonObject);
                                                }else{
                                                    filteredRoutes[index][dataIndex]=value;
                                                }
                                                filteredRoutes[index].is_fare_edited=true;
                                                setFilteredRoutes([...filteredRoutes]);
                                                }}
                                    />
                                </Col>
                              </Row>
                           </div>
              };
            }

            if(tripDynamicPricing){
                return (<div className="marginTop marginBottom marginLeft">
                    <Row>
                        <Col>
                            <DynamicPriceBtn 
                                scheduleId={scheduleId}
                                travelDates={[getAPIDateFormat(travelDate)]}
                                tripDynamicPriceEnabled={tripDynamicPricing}
                            />
                        </Col>
                        <Col offset= {4}>
                            <span>
                                <strong>Dynamic Pricing: </strong>
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        defaultChecked={tripDynamicPricing}
                                        onChange= {onChangeDynamicPricing}
                                    />
                            </span>
                        </Col>
                    </Row>
                </div>)
            }else{
                return (
                    <div className="marginLeft tab-block">
                        <ZoomComponent isZoomEnabled={isMobile}> 
                                <div style={{marginTop:'10px'}}>
                                    {showTravelDate?
                                        <div className="fontWeightBold">
                                            Travel Date : {getFormatedDate(travelDate,true,true,true,'.')}
                                        </div>
                                        :
                                        null
                                    }
                                    <OriginDestionationFilter 
                                        origins={origins}
                                        destinations={destinations}
                                        onOriginChange={onOriginChange}
                                        onDestionationChange={onDestionationChange}
                                        origin={originsFiltered}
                                        destination={destinationsFiltered}
                                    />
                                    <div>
                                        <Row className="buttons-block" gutter={[4,4]}>
                                            <Col className="cancel-button-root">
                                                <Button className="book-button" onClick={handleCancel}>
                                                    <CloseOutlined 
                                                        size={'large'}
                                                    />
                                                </Button>
                                            </Col>
                                            {showGetPriceButton?
                                                <Col className="cancel-button-root">
                                                    <Button className="get-fare-button" onClick={onGetPriceClick}>Get Fare</Button>
                                                </Col>
                                                :null
                                            }
                                            <Col className="copy-button-root" >
                                                <Button onClick={copyFirstRowFareToAllRoutes}>
                                                        <CopyOutlined 
                                                                size={'large'}
                                                            /> 
                                                        <ArrowDownOutlined 
                                                            size={'large'}
                                                            type="arrow-down" />
                                                </Button>
                                            </Col>
                                            <Col className="copy-button-root" >
                                                <Button onClick={copyFirstFareToAllRoutes}>
                                                    <CopyOutlined 
                                                        size={'large'}
                                                    />
                                                </Button>
                                            </Col>
                                            <Col className="cancel-button-root">
                                                <Button className="book-button" onClick={()=>{
                                                    setBtnType(plusBtn);
                                                    setIsVisible(true);
                                                }}>
                                                <PlusOutlined 
                                                        size={'large'}
                                                />
                                                </Button>
                                            </Col>
                                            <Col className="cancel-button-root">
                                                <Button className="book-button" onClick={()=>{
                                                    setBtnType(minusBtn);
                                                    setIsVisible(true);
                                                }}>
                                                <MinusOutlined  
                                                    size={'large'}
                                                />
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button onClick={handleUpdateFare} className="update-fare-button">
                                                    <CheckOutlined 
                                                        size={'large'}
                                                    />
                                                </Button>
                                            </Col>
                                            {dynamicPricingEnabled&&isValidNumber(tripDynamicPricing)?
                                            <Col offset= {4}>
                                                <span>
                                                    <strong>Dynamic Pricing: </strong>
                                                    <Switch
                                                        checkedChildren="Yes"
                                                        unCheckedChildren="No"
                                                        defaultChecked={tripDynamicPricing}
                                                        onChange= {onChangeDynamicPricing}
                                                    />
                                                </span>
                                            </Col>
                                            :null    
                                            }
                                        </Row>
                                        {isValidArray(filteredRoutes)&&filteredRoutes.length>0?
                                            <div className="edit-fare-table-root">
                                                <Table
                                                    dataSource={filteredRoutes}
                                                    columns={columns}
                                                    pagination={false} 
                                                    className="edit-fare-table"
                                                />
                                            </div>   
                                            :null
                                        }
                                    </div>
                                    <Modal
                                    title={plusBtn==btnType?"Increase all fares":"Decrease all fares"}
                                    visible={isVisible}
                                    okText="Apply"
                                    onOk={()=>{
                                        increORdecreFareBy(value);
                                    }}
                                    onCancel={resetModal}
                                    >
                                            {"Rs "}
                                            <InputNumber 
                                            value={value} 
                                            onChange={(data)=>{
                                                setValue(data);
                                            }}/>
                                    </Modal>
                                </div>
                                </ZoomComponent>
                    </div>
                )
            }
    }else{
        return null
    }
}
