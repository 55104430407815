import React, { useState } from "react";
import { Form , Button , Radio ,Row ,Col , DatePicker , Checkbox , Typography , Table} from 'antd';
import { getRangeOptions , getPaginationDetails, getDefaultPageSize} from './reports.module';
import {  fetchBlockedSheet } from './../../actions/reports.action';
import { getAPIDateFormat , convertDateStringToDate ,getLocalDate , getLocalDateAndTime} from './../../utils/date.utils';
import { ScheduleSelect } from './../../components/search-components/schedule-select/schedule-select.component';
import { isValid, isValidArray } from "../../utils/utilities";
import { AgentsSelect } from './../../components/search-components/agents-select/agents-select.component';
import { Printer } from './../../components/custom-components/custom-component';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import * as XLSX from 'xlsx';


const {Text} =Typography;

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const generateJsonToExport=(filters,filteredBlockedTickets)=>{

    let reportsBlockedXmlData=[];
    if(isValidArray(filteredBlockedTickets)&&filteredBlockedTickets.length>0){
        filteredBlockedTickets.forEach(element => {
            let bookingTIme=getLocalDateAndTime(element.booking_time);
            let startDate,releaseTime;
            if(isValid(element.start_date)){
                 startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]));
            }
            if(isValid(element.release_time)){
                 releaseTime = getLocalDate(convertDateStringToDate(element.release_time.split('T')[0]));
            }
            reportsBlockedXmlData.push([
                element.pnr,element.seat_number,element.name,
                element.email_id,bookingTIme,element.phone_number,
                element.description,startDate,releaseTime
            ])
        });
    }

    let agentName=filters.isSelectAllAgents?'all':filters.agentName;
    let dateType =filters.isBookingDate?"Booking":"Travel";
    let generatedTime = getLocalDateAndTime(new Date());

 
  var worksheet = XLSX.utils.aoa_to_sheet([
    [], // A1
    [], // A2
    [ "Report","Agents","From Date", "To Date","Type of date" , "Generated day/time"], // A3
    ["Blocking report",agentName,filters.fromDate,filters.toDate,dateType,generatedTime],
    [],
    [],
    [
        "PNR", "Seat nos" , "Agency" , 
        "Email" , "Booking time" , "Phone no" , 
        "Trip desc","Travel date" , "Release time"
    ],
    ...reportsBlockedXmlData
  ]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Block Seats Report');
  XLSX.writeFile(workbook, 'block-seats-report.xlsx');
}

const getSchedules=(data)=>{
    let formatedData=[];
    if(isValidArray(data)&&data.length>0){
        data.forEach(element => {
            if(isValid(element.description)&&formatedData.indexOf(element.description)===-1){
                formatedData.push(element.description);
            } 
        });
    }
    return formatedData;
}

const getFilterDetails=({
    scheduleDesc,
    isSelectAllSchedules,
    isSelectAllAgents,
    agentName
},
data)=>{
        let formatedData=[];
        if(isValidArray(data)&&data.length>0){
            data.forEach(element => {
                if((scheduleDesc===element.description||isSelectAllSchedules)&&(agentName===element.name||isSelectAllAgents)){
                    formatedData.push(element);
                }
            });
        }
        return formatedData;
}


const getTicketsByAgentName=(tickets,agentName,isSelectAllAgents)=>{
    let formatedTickets=[];
    if(isSelectAllAgents){
        return tickets;
    }else if(isValid(agentName)&&isValidArray(tickets)&&tickets.length>0){
        tickets.forEach(element => {
            if(element.name===agentName){
                formatedTickets.push(element)
            }
        });
    }
    return formatedTickets;
}



const RenderReport=({fromDate,toDate,isBookingDate,agentName,blockedTickets,pagination,isSelectAllAgents})=>{

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const cancelTicketsColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'Seat nos',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Agency',
            key:'name',
            dataIndex : 'name'
        },
        {
            title:'Email',
            key:'email_id',
            dataIndex : 'email_id'
        },
        {
            title:'Booking time',
            key:'booking_time',
            dataIndex : 'booking_time',
            render : (text, record, index) => {
                if(isValid(record.booking_time)){
                    return getLocalDateAndTime(record.booking_time)
                }else{
                    return ''
                }
            }
        },
        {
            title:'Phone No',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Trip description',
            key:'description',
            dataIndex : 'description'
        },
        {
            title:'Travel date',
            key:'start_date',
            dataIndex : 'start_date',
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return record.start_date.split('T')[0]
                }else{
                    return ''
                }
            }
        },
        {
            title:'Release time',
            key:'release_time',
            dataIndex : 'release_time',
            render : (text, record, index) => {
                if(isValid(record.release_time)){
                    return getLocalDate(convertDateStringToDate(record.release_time.split('T')[0]))
                }else{
                    return ''
                }
            }
        }
    ];

    return(
        <div>
            <Row justify="center" gutter={[16,16]}>
                <Col>
                    <Text strong>Blocked tickets report</Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>From date : </Text>{fromDate}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>To date : </Text>{toDate}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Type of date : </Text>{isBookingDate?"Booking Date":"Travel Date"}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                            <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,0]}>
                <Col>
                    <Text strong>Agent : {isSelectAllAgents?"All agents":agentName}</Text>
                </Col>
            </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table 
                        className="bordered-table"
                        columns={cancelTicketsColumns}
                        dataSource={getTicketsByAgentName(blockedTickets,agentName,isSelectAllAgents)}
                        pagination={getPaginationDetails(pagination,setPage, getDefaultPageSize, setPaginationSize)}
                    />
               </Col>
           </Row>
        </div>
    )
}

export const BlockedSeatsReport = () => {

    const [form] = Form.useForm();
    const [filtersForm] = Form.useForm();
    const dispatch=useDispatch();

    const [showReport,setShowReport]=useState(false);
    let [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
        isSelectAllAgents:true,
        isSelectAllSchedules:false,
        agentNames:undefined,
        schedules:undefined
    });
    const [blockedTickets,setBlockedTickets]=useState(undefined);
    const [filteredBlockedTickets,setFilteredBlockedTickets]=useState(undefined);

    const onSuccess=(data)=>{
        setBlockedTickets(data.blockedTransactions);
        setFilteredBlockedTickets(data.blockedTransactions);
        filters.agentNames=data.agents;
        filters.schedules=getSchedules(data.blockedTransactions);
        setFilters({...filters});
        setShowReport(true);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange,
            isBooking
        }=values;
        setLoading(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setShowReport(false);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        filters.isBookingDate=isBooking;
        filters.agentNames=undefined;
        filters.schedules=undefined;
        filtersForm.resetFields();
        setFilters({...filters});
        fetchBlockedSheet(fromDate,toDate,isBooking,onSuccess,onFailure);
    }

    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                        <Form.Item name="isBooking" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                            <Radio.Group>
                                <Radio value={true}>Booking Date</Radio>
                                <Radio value={false}>Travel Date</Radio>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {isValidArray(filters.schedules)||isValidArray(filters.agentNames)?
                        <Form
                        form={filtersForm}
                        initialValues={{isSelectAllSchedules:true,isSelectAllAgents:true}}
                        onFinish={(values)=>{
                            let {
                                returnId,
                                isSelectAllSchedules,
                                isSelectAllAgents,
                                agentName
                            }=values;
                            let filteredData=getFilterDetails({
                                scheduleDesc:returnId,
                                isSelectAllSchedules:isSelectAllSchedules,
                                isSelectAllAgents:isSelectAllAgents,
                                agentName:agentName
                            },blockedTickets);
                            setFilteredBlockedTickets([...filteredData]);
                        }}
                        >
                            <Row gutter={[16,16]}>
                                <Col>
                                    <ScheduleSelect 
                                        schedules={filters.schedules}
                                        optionKey="description"
                                        showLabel={true}
                                        isManditory={false}
                                    />
                                </Col>
                                <Col>
                                    <Form.Item name="isSelectAllSchedules"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <AgentsSelect 
                                        agents={filters.agentNames}
                                        isManditory={false}
                                        showLabel={true}
                                    />
                                </Col>
                                <Col>
                                    <Form.Item name="isSelectAllAgents"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button htmlType="submit">Filter</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        :
                        null
                }
                {showReport&&
                <div>
                     <Row gutter={16}>
                        <Col>
                        <Button onClick={()=>{
                                generateJsonToExport(filters,filteredBlockedTickets);
                            }}>Export</Button>
                        </Col>
                        <Col>
                            <Printer 
                                    showComponent={false}
                                    zoomValue={0.5}
                                    renderComponent={()=>{
                                        return (
                                        <RenderReport 
                                            fromDate={filters.fromDate}
                                            toDate={filters.toDate}
                                            isBookingDate={filters.isBookingDate}
                                            agentName={filters.agentName}
                                            isSelectAllAgents={filters.isSelectAllAgents}
                                            blockedTickets={filteredBlockedTickets}
                                            pagination={false}
                                        />
                                )
                            }} />
                        </Col>
                    </Row>
                    <RenderReport 
                        fromDate={filters.fromDate}
                        toDate={filters.toDate}
                        isBookingDate={filters.isBookingDate}
                        agentName={filters.agentName}
                        isSelectAllAgents={filters.isSelectAllAgents}
                        blockedTickets={filteredBlockedTickets}
                        pagination={true}
                    />
                </div>
                }
            </div>
    )
}