import { message } from "antd";
import { REFRESH_CACHE_FOR_BOARDING_POINTS_URL, REFRESH_CACHE_FOR_DROP_POINTS_URL } from "../../../utils/api-url";
import { AGENT_TO_VIEW_NEW_TIMING_TAB, AGENT_TO_VIEW_PASSENGER_TAB, AGENT_TO_VIEW_LOGS_TAB, VIEW_ADMIN_TAB_IN_SEARCH_PAGE, ADMIN_CHANGE_SEAT_LAYOUT } from "../../../utils/constant"
import { postCall } from "../../../utils/network.util";
import { checkActionAllowed, isValid, isValidArray } from "../../../utils/utilities"


export const getShowTicketsConfirmation=()=>{
    if(checkActionAllowed(AGENT_TO_VIEW_PASSENGER_TAB)){
        return true;
    }else{
        return false;
    }
}

export const getShowNewTiming=(schedule)=>{
    if(checkActionAllowed(AGENT_TO_VIEW_NEW_TIMING_TAB)&&isValidArray(schedule.pickupTimings)&&isValidArray(schedule.pickupTimings[0])&&isValid(schedule.pickupTimings[0].trip_id)){
        return true;
    }else{
        return false;
    }
}

export const isLogsTabAllowed = ()=> {
    if(checkActionAllowed(AGENT_TO_VIEW_LOGS_TAB)){
        return true;
    }else{
        return false;
    }
}

export const isAdminTabAllowed = ()=> {
    if(checkActionAllowed(VIEW_ADMIN_TAB_IN_SEARCH_PAGE)){
        return true;
    }else{
        return false;
    }
}

export const isChangeLayoutTabAllowed = ()=> {
    if(checkActionAllowed(ADMIN_CHANGE_SEAT_LAYOUT)){
        return true;
    }else{
        return false;
    }
}

export const refreshBoardingPoints = (scheduleId,tripId,onSuccess=()=>{},onFailure=()=>{}) =>{
    postCall(REFRESH_CACHE_FOR_BOARDING_POINTS_URL,{scheduleId,tripId})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS"){
           message.success(findresponce.payload);
         }
      })
}

export const refreshDropPoints = (scheduleId,tripId,onSuccess=()=>{},onFailure=()=>{}) =>{
    postCall(REFRESH_CACHE_FOR_DROP_POINTS_URL,{scheduleId,tripId})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS"){
            message.success(findresponce.payload);
          }
      })
}