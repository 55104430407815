/**
 * All global constants can be defined here
 * @author Manne Hareesh
 */

import { checkActionAllowed, isValid, isValidArray } from "./utilities";

// Static Messages
export const FAILURE_STATUS = "FAILURE";
export const SUCCESS_STATUS = "SUCCESS";

export const PRE_PAID_USER ="Prepaid";
export const POST_PAID_USER ="Postpaid";

export const PRIVILEGE_MASTER = "Master User";
export const PRIVILEGE_REPORTS = "Reports";
export const PRIVILEGE_HEAD_OFFICE = "Head Office";

export const DAFIDY_USER = "Dafidy user";
export const QB_USER = "QB";
export const OTA_USER = "OTA";

export const AGENT_TRANSACTION_PENDING = 'Pending';
export const AGENT_TRANSACTION_COMPLETED = 'Completed';
export const AGENT_TRANSACTION_FAILED = 'Failed';

export const showUpdatebtn=()=>{
    if(checkActionAllowed(AGENT_TO_VIEW_NEW_TIMING_TAB)){
        return true;
    }else{
        return false;
    }
}

export const dafidyUserCheck=()=>{
    if(global.privilege === DAFIDY_USER){
        return true;
    }else{
        return false;
    }
}

export const isQBUserCheck=()=>{
    if(global.privilege === QB_USER){
        return true;
    }else{
        return false;
    }
}

export const isOTAUser = () => {
    if(global.privilege === OTA_USER){
        return true;
    }else{
        return false;
    }
}


export const removeHttp = (url)=> {
    if(isValid(url)){
        url = url.replace(/www./, '');
        return url.replace(/^https?:\/\//, '');
    }else{
        return '';
    }
}

export const getAdminUrl=()=>{
    if(isValid(global.adminClientUrl)){
        return global.adminClientUrl;
    }else{
        let webSiteUrl = removeHttp(global.webSiteUrl);
        return `https://admin.${webSiteUrl}`;
    }
}

//Constants for notification messages
 
export const NOTIFICATION_CANCEL_SMS_MESSAGE = `Dear Customer,Due to unforeseen reasons,your bus on $date has been cancelled.We apologize for the inconvenience-$operator_name`;
export const NOTIFICATION_CANCEL_EMAIL_MESSAGE = `Dear Customer,Thank you so much for choosing $operator_name. Due to some unforseen reasons, the bus that you were scheduled to travel on $date from $origin to $destination has been cancelled. We sincerely apologize for the inconvenience. We will try our very best to avoid such misfortunes in the future.Thank you so much for your understanding,-$operator_name`
export const NOTIFICATION_DELAY_SMS_MESSAGE = `Dear Customer,Due to unforeseen reasons,your bus on $date has been delayed by $delaytime mins.We apologize for the inconvenience-$operator_name`;
export const NOTIFICATION_DELAY_EMAIL_MESSAGE = `Dear Customer,Thank you so much for choosing $operator_name. Due to some unforseen reasons, the bus that you were scheduled to travel on $date from $origin to $destination has been delayed by $delaytime mins. We sincerely apologize for the inconvenience. We will try our very best to avoid such misfortunes in the future.Thank you so much for your understanding,- $operator_name`;
 
//TIME ZONE
export const IST_OFFSET = 330;
 
//Data types for data table component
export const DSTRING = "string";
export const DNUMBER = "number";
export const DDATE = "date";
export const DDATETIME = "datetime";
export const DCOMPONENT = "component";

export const RED_BUS_USER_NAME = "redbus";
export const ABHI_BUS_USER_NAME = "abhibus";
export const PAYTM_BUS_USER_NAME = "paytm";
 
//Allowed actions for user
export const SHOW_AGENT_DASHBOARD = "SHOW_AGENT_DASHBOARD";
export const ACTION_VIEW_SEARCH_PAGE_ALLOWED = "SEARCH_PAGE_ALLOWED";//done
export const ACTION_VIEW_REPORTS_PAGE_ALLOWED = "REPORTS_PAGE_ALLOWED";//done
export const ACTION_TO_VIEW_TRIP_SHEET_PERMISSION_PAGE = "ACTION_TO_VIEW_TRIP_SHEET_PERMISSION_PAGE";
export const ACTION_VIEW_CANCEL_PAGE_ALLOWED = "VIEW_CANCEL_PAGE_ALLOWED";//done
export const ACTION_VIEW_NOTIFICATION_PAGE_ALLOWED = "NOTIFICATION_PAGE_ALLOWED";//done
export const ACTION_VIEW_BUS_CANCELLATION_PAGE_ALLOWED = "BUS_CANCELLATION_PAGE_ALLOWED";//done
export const ACTION_VIEW_STAFF_PAGE_ALLOWED = "ACTION_VIEW_STAFF_PAGE_ALLOWED";//done
export const ACTION_VIEW_AGENTS_PAGE_ALLOWED = "AGENTS_PAGE_ALLOWED";//done
export const ACTION_VIEW_AGENTS_TRANSACTION_PAGE_ALLOWED = "AGENTS_TRANSACTION_PAGE_ALLOWED";//done
export const ACTION_SEND_OTA_EMAILS = "ACTION_SEND_OTA_EMAILS";//done

//Allowed actions
export const ACTION_BOOK_TICKET_ALLOWED = "BOOK_TICKET_ALLOWED";//done
export const ACTION_BOOK_AVAILABLE_TICKET_ALLOWED = "BOOK_AVAILABLE_TICKET_ALLOWED";
export const ACTION_BOOK_BBA_TICKET_ALLOWED = "BOOK_BBA_TICKET_ALLOWED";//Blocked By Admin//done
export const ACTION_BOOK_BBO_TICKET_ALLOWED = "BOOK_BBO_TICKET_ALLOWED";//Blocked By Other//done
export const ACTION_BOOK_BBS_TICKET_ALLOWED = "BOOK_BBS_TICKET_ALLOWED";//Blocked By Self//done(not required)
 
export const ACTION_BLOCK_TICKET_ALLOWED = "BLOCK_TICKET_ALLOWED";//done
export const ACTION_PHONE_BLOCK_ALLOWED = "ACTION_PHONE_BLOCK_ALLOWED";//done
export const ACTION_RELEASE_TICKET_ALLOWED = "RELEASE_TICKET_ALLOWED";//done
export const ACTION_RELEASE_BBO_TICKET_ALLOWED = "RELEASE_BBO_TICKET_ALLOWED";//not required
export const ACTION_RELEASE_BBA_TICKET_ALLOWED = "RELEASE_BBA_TICKET_ALLOWED";//not required
export const ACTION_RELEASE_BBS_TICKET_ALLOWED = "RELEASE_BBS_TICKET_ALLOWED";//not required
export const ACTION_SEAT_GENDER_UPDATE_ALLOWED = "SEAT_GENDER_UPDATE_ALLOWED";//done
export const ACTION_CANCEL_TICKET_ALLOWED = "CANCEL_TICKET_ALLOWED";//done
export const ACTION_CANCEL_BKBO_TICKET_ALLOWED = "CANCEL_BKBO_TICKET_ALLOWED";
export const ACTION_CANCEL_BKBS_TICKET_ALLOWED = "CANCEL_BKBS_TICKET_ALLOWED";
export const ACTION_BLOCK_ALL_TICKETS_ALLOWED = "BLOCK_ALL_TICKETS_ALLOWED";//done
export const ACTION_SEAT_NUMBER_UPDATE_ALLOWED = "SEAT_NUMBER_UPDATE_ALLOWED";//done
export const ACTION_TO_UPDATE_AGENT_IN_TICKET = "ACTION_TO_UPDATE_AGENT_IN_TICKET";
export const ACTION_TO_VIEW_SET_GENDER_BTN = "ACTION_TO_VIEW_SET_GENDER_BTN";//done

//Cancellation related actionss
export const ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED = "SEND_CANCEL_NOTIFICATION_ALLOWED";//done
export const ACTION_OVERRIDE_CANCELLATION_PENALTY_ALLOWED = "OVERRIDE_CANCELLATION_PENALTY_ALLOWED";//done

// Allowed reports for user
export const ACTION_VIEW_BLOCKED_TICKETS_REPORT_ALLOWED = "BLOCKED_TICKETS_REPORT_ALLOWED";
export const ACTION_VIEW_BOOKED_TICKETS_REPORT_ALLOWED = "BOOKED_TICKETS_REPORT_ALLOWED";//done
export const ACTION_VIEW_TICKET_SHARING_REPORT_ALLOWED = "TICKET_SHARING_REPORT_ALLOWED";//done
export const ACTION_VIEW_SETTLEMENT_REPORT_ALLOWED = "SETTLEMENT_REPORT_ALLOWED";//done
export const ACTION_VIEW_CURRENT_INFO_REPORT_ALLOWED = "CURRENT_INFO_REPORT_ALLOWED";
export const ACTION_VIEW_MASTER_TRIP_REPORT_ALLOWED = "MASTER_TRIP_REPORT_ALLOWED";//done
export const ACTION_VIEW_PASSENGER_INFO_REPORT_ALLOWED = "PASSENGER_INFO_REPORT_ALLOWED";//done
export const ACTION_VIEW_PERFORMANCE_REPORT_ALLOWED = "PERFORMANCE_REPORT_ALLOWED";
export const ACTION_VIEW_REVENUE_REPORT_ALLOWED = "ACTION_TO_VIEW_REVENUE_REPORT";
export const ACTION_TO_VIEW_MAVEN_TRANSACTION_REPORT="ACTION_TO_VIEW_MAVEN_TRANSACTION_REPORT";

export const ACTION_VIEW_SALES_REPORT_ALLOWED = "SALES_REPORT_ALLOWED";//done
export const ACTION_VIEW_TICKET_SEARCH_REPORT_ALLOWED = "TICKET_SEARCH_REPORT_ALLOWED";//done
export const ACTION_VIEW_TICKET_SEARCH_REPORT_OLD_ALLOWED = "TICKET_SEARCH_REPORT_OLD_ALLOWED";//done
export const ACTION_VIEW_TOTAL_TRANSACTION_REPORT_ALLOWED = "TOTAL_TRANSACTION_REPORT_ALLOWED";
export const ACTION_VIEW_TRIP_REPORT_ALLOWED = "TRIP_REPORT_ALLOWED";//done
export const ACTION_VIEW_WEB_TICKET_CANCELLATION_REPORT_ALLOWED = "WEB_TICKET_CANCELLATION_REPORT_ALLOWED";
export const ACTION_VIEW_WEB_TICKET_SALES_REPORT_ALLOWED = "WEB_TICKET_SALES_REPORT_ALLOWED";
export const ACTION_VIEW_ABANDONED_REPORT_ALLOWED = "ABANDONED_REPORT_ALLOWED";
export const ACTION_VIEW_TICKETS_BOOKED_AFTER_START_TIME_ALLOWED = "ACTION_VIEW_TICKETS_BOOKED_AFTER_START_TIME_ALLOWED";
export const ACTION_VIEW_ANALYTICS_DEMAND = "ACTION_VIEW_ANALYTICS_DEMAND";
export const ACTION_TO_VIEW_ACTIVE_BOOKINGS_REPORT = "ACTION_TO_VIEW_ACTIVE_BOOKINGS_REPORT";
export const ACTION_TO_VIEW_SERVICE_COUNT_REPORT = "ACTION_TO_VIEW_SERVICE_COUNT_REPORT";
export const ACTION_VIEW_RESCHEDULED_REPORT_PAGE = "VIEW_RESCHEDULED_REPORT_PAGE";
export const ACTION_TO_VIEW_OPERATOR_COST_SUMMARY = "ACTION_TO_VIEW_OPERATOR_COST_SUMMARY";
export const ACTION_TO_VIEW_OPERATOR_TRANSACT_SUMMARY = "ACTION_TO_VIEW_OPERATOR_TRANSACT_SUMMARY";
export const ACTION_TO_VIEW_OPERATOR_TRACKING_SUMMARY = "ACTION_TO_VIEW_OPERATOR_TRACKING_SUMMARY";

export const ACTION_BLOCK_BY_ADMIN_SEATS_ALLOWED = "ACTION_BLOCK_BY_ADMIN_SEATS_ALLOWED";
export const ACTION_GENERATE_PAYMENT_LINK_ALLOWED = "ACTION_GENERATE_PAYMENT_LINK_ALLOWED";
export const ACTION_CHANGE_BUS_ALLOWED = "ACTION_CHANGE_BUS_ALLOWED";

export const ACTION_SMS_REPORT_ALLOWED = "ACTION_SMS_REPORT_ALLOWED";

export const ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL = "ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL_ALLOWED";
export const ACTION_TO_EDIT_VECHICLE_INFO_FROM_AGENT_PORTAL = "ACTION_TO_EDIT_VECHICLE_INFO_FROM_AGENT_PORTAL_ALLOWED";
export const UPDATE_DAY_SPECIFIC_ROUTE_TIMING = 'UPDATE_DAY_SPECIFIC_ROUTE_TIMING';
export const UPDATE_IN_NEW_TIME_TAB ='UPDATE_IN_NEW_TIME_TAB';

export const ACTION_TO_VIEW_SEAT_DOUBLE_CLICK="ACTION_TO_VIEW_SEAT_DOUBLE_CLICK";
export const SHOW_CLIENT_SIDE_DISCOUNT="SHOW_CLIENT_SIDE_DISCOUNT";

export const TRIP_EXPENSE_PAGE = "TRIP_EXPENSE_PAGE";
export const VIEW_AGENT_TRIP_CREATE = "VIEW_AGENT_TRIP_CREATE";

export const ACTION_TO_VIEW_NOT_BOARDED_BUTTON ='ACTION_TO_VIEW_NOT_BOARDED_BUTTON';

export const VIEW_PREPAID_AGENT_ACCOUNT_DATA = "VIEW_PREPAID_AGENT_ACCOUNT_DATA";
export const ACTION_EXCEPTIONAL_REFUND_ALLOWED = "ACTION_EXCEPTIONAL_REFUND_ALLOWED";

//Admin Pages
export const ADMIN_VIEW_AGENTS_PAGE="ADMIN_VIEW_AGENTS_PAGE";
export const AGENT_VIEW_STANDARD_STOPS_PAGE="AGENT_VIEW_STANDARD_STOPS_PAGE";
export const ADMIN_VIEW_TRIPS_PAGE="ADMIN_VIEW_TRIPS_PAGE";
export const PRIORITY_ROUTES_PAGE_ALLOWED = "PRIORITY_ROUTES_PAGE_ALLOWED";
export const USER_ACTION_PAGE_ALLOWED = "USER_ACTION_PAGE_ALLOWED";
export const SHOW_OPERATOR_CONFIG_PAGE = "SHOW_OPERATOR_CONFIG_PAGE";
export const ADMIN_CHANGE_SEAT_LAYOUT = "ADMIN_CHANGE_SEAT_LAYOUT";

export const ADMIN_VIEW_CITIES_PAGE = "ADMIN_VIEW_CITIES_PAGE";
// Schedule page
export const ADMIN_VIEW_SCHEDULES_PAGE="ADMIN_VIEW_SCHEDULES_PAGE";
export const AGENT_TO_VIEW_PASSENGER_TAB="AGENT_TO_VIEW_PASSENGER_TAB";
export const AGENT_TO_VIEW_NEW_TIMING_TAB="AGENT_TO_VIEW_NEW_TIMING_TAB";
export const AGENT_TO_VIEW_LOGS_TAB = "AGENT_TO_VIEW_LOGS_TAB";
export const VIEW_ADMIN_TAB_IN_SEARCH_PAGE ="VIEW_ADMIN_TAB_IN_SEARCH_PAGE";
export const ADMIN_ACTION_TRIP_DETAILS_VIEW = "ADMIN_ACTION_TRIP_DETAILS_VIEW";

export const SHOW_REVENUE_IN_AGENT_CLIENT = "SHOW_REVENUE_IN_AGENT_CLIENT";

//SERVICE WORKER NOTIFICATION ID
export const SERVICE_WORKER_NOTIFICATION = "SWR_NOTIFICATION";
export const SERVICE_WORKER_UPDATE_TIME_INTERVAL = 1000 * 60 * 60;
 
export const AGENT_EMAIL_MESSAGE = 'We had cancelled the following services. Please issue a full refund ONLY to the PNRs mentioned below. The other seats in the buses have been re-assigned to a different service after communicating with the customers:'

export const LOGIN_ACCESS_TOKEN_KEY = "shbdhbhbdshbknanaknjknakjbkbfjhsbhjbmdandk";
export const AGENT_COLORS_ACCESS_TOKEN_KEY = "djnjfndbfkhlbhdsbfhbdshfbhdabcdcd";
export const GOOGLE_MAPS_API = "AIzaSyDrk4VeM3JL3wDvOXfOVzQbTSNm7GITmT8";

export const SESSION_TIME_OUT_MINS = 60;
export const TIME_STAMP_FOR_ACTIVE_BOOKINGS = 60;

export const STAFF_DRIVER = "Driver";

//Logs Table Action
export const LOGS_CUSTOMER_DATA = "Customer Data Updated";
export const LOGS_EDIT_FARE = "Edit Fare";
export const LOGS_SEAT_FARE = "Seat Fare";
export const LOGS_PICK_UP_TIMINGS = "Pickup Points Changed";
export const LOGS_DROP_TIMINGS = "Drop Points Changed";
export const LOGS_FOR_RELEASE_SEATS ="Release Seats";
export const LOGS_FOR_ADMIN_BLOCK_SEATS ="Admin Block Seats";
export const LOGS_FOR_DRIVER_PH_NO_UPDATE ="Driver Phone Number Update";
export const LOGS_TRACKING_SMS_SENT = "Tracking SMS Sent";
export const LOGS_MANAGEMENT_ALERT_SMS = "Management Alert";

export const ACTION_TO_VIEW_OVER_DUE_MSG="ACTION_TO_VIEW_OVER_DUE_MSG";
export const ACTION_TO_SET_DRIVER_APP_SUBSCRIPTION = "ACTION_TO_SET_DRIVER_APP_SUBSCRIPTION";

export const ACTION_TO_SET_TRIPS_CREATED="ACTION_TO_SET_TRIPS_CREATED";

export const getSameUser =(agentId)=>{
    if(agentId === global.agentId || (isValidArray(agentId)&&agentId.indexOf(global.agentId) != -1)){
        return true;
    }else{
        return false;
    }
}