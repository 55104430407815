import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table } from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { getRangeOptions, getPaginationDetails, getDefaultPageSize } from './reports.module';
import { fetchGstFilingTickets } from "../../actions/reports.action";
import { getAPIDateFormat, getLocalDate, getLocalDateAndTime } from "../../utils/date.utils";
import { roundNumber } from "../../utils/math.utils";
import { Printer } from "../../components/custom-components/custom-component";
import * as XLSX from 'xlsx';
import { isValid, isValidArray } from "../../utils/utilities";


const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const getFilteredDetails=(gstSummary)=>{
    let filteredDetails = [];
    for (let index = 0; index < gstSummary.length; index++) {
        const element = gstSummary[index];
        if(isValid(element.total)&&element.total.count>0){
            filteredDetails.push(element);
        }
    }
    return filteredDetails;
}

const renderGstSummeryTables=(gstColumns,gstSummary)=>{
    let data=[];

    for (let index = 0; index < gstSummary.length; index++) {
        const element = gstSummary[index];
        data.push(<div className="marginTop">
            <Text strong>{element.gstnumber=="nogstnumber"?"No GST Number":element.gstnumber}</Text>
            <Table
                className="bordered-table marginTop gstTable" 
                columns={gstColumns}
                dataSource={element['gstList']}
                pagination={false}
            />
        </div>);
    }

    return data

}

const generateJsonToExport=(filters,gstObj,gstTickets)=>{
    let {
        fromDate,toDate,generatedTime
    } = filters;

let reportDataXL = [
            [], // A1
            [], // A2
            ["Report","From Date", "To Date","Agent Privilege" , "Generated day/time"], // A3
            ["GST Filing Report",fromDate,toDate,generatedTime],
            [],
            [],
            ["GST summary"]
        ];

    for (let index = 0; index < gstObj.length; index++) {
        const gstSummary = gstObj[index]["gstList"];
        let gstSummaryXMlData = [] ;
        if(isValidArray(gstSummary)&&gstSummary.length>0){
            gstSummary.forEach(element => {
                gstSummaryXMlData.push([
                    element.details,
                    roundNumber(element.discounted_fare),
                    roundNumber(element.cgst),
                    roundNumber(element.sgst),
                    roundNumber(element.total_gst)]);
            });

        }
       reportDataXL.push(["Details","Fare","CGST","SGST","Total GST"]);
       reportDataXL.push(...gstSummaryXMlData)
    }

    reportDataXL.push([]);

    let gstTicketsXMLData=[];
    if(isValidArray(gstTickets)&&gstTickets.length>0){
        gstTickets.forEach((element,index) => {
            gstTicketsXMLData.push([
                index+1,
                element.agent_name,
                element.pnr,
                element.seat_count,
                roundNumber(element.discounted_fare),
                roundNumber(element.total_gst),
                roundNumber(element.amount_paid),
                getLocalDateAndTime(element.booking_time),
                getLocalDate(element.travel_date),
                element.description
            ]);
        });
    }
    reportDataXL.push(["SN","Agent Name","PNR","Seat Count","Fare","GST","Amount Paid","Booking Time","Travel Date","Trip Description"]);
    reportDataXL.push(...gstTicketsXMLData);

    var worksheet = XLSX.utils.aoa_to_sheet(reportDataXL);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'GST Filling Report');
    XLSX.writeFile(workbook, 'gst-filling-report.xlsx');
}

const RenderReport =({gstTickets,gstSummary,formValues, isPagination})=>{

    let {
        dateRange
    }=formValues;
    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const ticketColums=[
        {
            title:"SN",
            key:"index",
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title:"Agent Name",
            key:"agent_name",
            dataIndex:'agent_name'
        },
        {
            title:"PNR",
            key:"pnr",
            dataIndex:'pnr'
        },
        {
            title:"Seat Count",
            key:"seat_count",
            dataIndex:'seat_count'
        },
        {
            title:"Fare",
            key:"discounted_fare",
            dataIndex:'discounted_fare',
            render:(text)=>{
                return roundNumber(text)
            }
        },
        {
            title:"GST",
            key:"total_gst",
            dataIndex:'total_gst',
            render:(text)=>{
                return roundNumber(text)
            }
        },
        {
            title:"Amount Paid",
            key:"amount_paid",
            dataIndex:'amount_paid',
            render:(text)=>{
                return roundNumber(text)
            }
        },
        {
            title:"Booking Time",
            key:"index",
            dataIndex:'booking_time',
            render:(text)=>{
                return getLocalDateAndTime(text);
            }
        },
        {
            title:"Travel Date",
            key:"index",
            dataIndex:'travel_date',
            render:(text)=>{
                return getLocalDate(text);
            }
        },
        {
            title:"Trip Description",
            key:"index",
            dataIndex:'description'
        }
    ];

    const gstColumns=[
        {
            title:"Details",
            key:"details",
            dataIndex:'details',
            render:(text,record, index)=>{
                if (record.isBold) {
                    return <Text strong>{text}</Text>
                }
                else {
                    return <Text>{text}</Text>
                }
            }
        },
        {
            title:"Fare",
            key:"discounted_fare",
            dataIndex:'discounted_fare',
            render:(text,record, index)=>{
                if (record.isBold) {
                    return <Text strong>{roundNumber(text)}</Text>
                }
                else {
                    return <Text>{roundNumber(text)}</Text>
                }
            }
        },
        {
            title:"CGST",
            key:"cgst",
            dataIndex:'cgst',
            render:(text,record, index)=>{
                if (record.isBold) {
                    return <Text strong>{roundNumber(text)}</Text>
                }
                else {
                    return <Text>{roundNumber(text)}</Text>
                }
            }
        },
        {
            title:"SGST",
            key:"sgst",
            dataIndex:'sgst',
            render:(text,record, index)=>{
                if (record.isBold) {
                    return <Text strong>{roundNumber(text)}</Text>
                }
                else {
                    return <Text>{roundNumber(text)}</Text>
                }
            }
        },
        {
            title:"Total GST",
            key:"total_gst",
            dataIndex:'total_gst',
            render:(text,record, index)=>{
                if (record.isBold) {
                    return <Text strong>{roundNumber(text)}</Text>
                }
                else {
                    return <Text>{roundNumber(text)}</Text>
                }
            }
        }
    ];

    return(
        <div>
             <Row justify="center">
                <Col>
                     <Text strong>GST Filing Report</Text>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Text>
                        <Text strong>From Date : </Text>
                          {getAPIDateFormat(dateRange[0])}
                        </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Text>
                        <Text strong>To Date : </Text>
                         {getAPIDateFormat(dateRange[1])}
                        </Text>
                </Col>
            </Row>
            <Row justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                    <Text strong>Agent Privilege : </Text>
                        {global.privilege}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                    <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Text strong>GST summary</Text>
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Table 
                        className="bordered-table"
                        pagination={false}
                        dataSource={getFilteredDetails(gstSummary)}
                        columns={[
                            {
                                title:"Gst Number",
                                dataIndex:'gstnumber'
                            },
                            {
                                title:"E-Commerce",
                                key:"ecomm",
                                dataIndex:'ecomm',
                                children: [
                                            {
                                                title: 'Confirmed',
                                                dataIndex: 'confirmCount',
                                                key: 'confirmCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.ecomm)){
                                                        return record.ecomm.confirmCount
                                                    }else{
                                                        return 0
                                                    }
                                                }

                                            },
                                            {
                                                title: 'Cancelled',
                                                dataIndex: 'cancelCount',
                                                key: 'cancelCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.ecomm)){
                                                        return record.ecomm.cancelCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                    
                                        ]
                            },
                            {
                                title: 'NonAC',
                                children: [
                                            {
                                                title: 'Confirmed',
                                                dataIndex: 'confirmCount',
                                                key: 'confirmCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.nonAc)){
                                                        return record.nonAc.confirmCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                            {
                                                title: 'Cancelled',
                                                dataIndex: 'cancelCount',
                                                key: 'cancelCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.nonAc)){
                                                        return record.nonAc.cancelCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                        ],
                                   
                            },
                            {
                                        title: 'AC',
                                        children: [
                                            {
                                                title: 'Confirmed',
                                                dataIndex: 'confirmCount',
                                                key: 'confirmCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.ac)){
                                                        return record.ac.confirmCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                            {
                                                title: 'Cancelled',
                                                dataIndex: 'cancelCount',
                                                key: 'cancelCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.ac)){
                                                        return record.ac.cancelCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                        ]
                            },
                            {
                                title:"Total",
                                dataIndex: 'count',
                                key: 'count',
                                render : (text, record, index) => {
                                    if(isValid(record.total)){
                                        return record.total.count
                                    }else{
                                        return 0
                                    }
                                }
                            }
                        ]}
                        summary={(pageData) => {
                            let totalCount = 0,ecommConfirm = 0 , ecommCancel = 0 , nonAcConfirm = 0 ,nonAcCancel = 0;
                            let acConfirm=0,acCancel=0;
                            for (let index = 0; index < pageData.length; index++) {
                                let{
                                    total,
                                    ecomm,
                                    nonAc,
                                    ac
                                }= pageData[index];
    
                                if(isValid(total)&&isValid(total.count)){
                                    totalCount = totalCount + total.count;
                                }   
                                if(isValid(ecomm)){
                                    ecommConfirm = ecommConfirm + ecomm.confirmCount;
                                    ecommCancel = ecommCancel + ecomm.cancelCount;
                                }   
                                if(isValid(nonAc)){
                                    nonAcConfirm = nonAcConfirm + nonAc.confirmCount;
                                    nonAcCancel = nonAcCancel + nonAc.cancelCount;
                                }   
                                if(isValid(ac)){
                                    acConfirm = acConfirm + ac.confirmCount;
                                    acCancel = acCancel + ac.cancelCount;
                                }   
                            }
                            return (
                                <>
                                        <Table.Summary.Row style= {{fontWeight: 'bold'}}>
                                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}>{ecommConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}>{ecommCancel}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3}>{nonAcConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}>{nonAcCancel}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={5}>{acConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={6}>{acCancel}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={7}>{totalCount}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                </>
                            )

                        }}

                    />
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Text strong>E-Commerce</Text>
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Table 
                        className="bordered-table"
                        pagination={false}
                        dataSource={getFilteredDetails(gstSummary)}
                        columns={[
                            {
                                title:"Gst Number",
                                dataIndex:'gstnumber'
                            },
                            {
                                title:"Redbus",
                                key:"redbus",
                                dataIndex:'redbus',
                                children: [
                                            {
                                                title: 'Confirmed',
                                                dataIndex: 'confirmCount',
                                                key: 'confirmCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.redbus)){
                                                        return record.redbus.confirmCount
                                                    }else{
                                                        return 0
                                                    }
                                                }

                                            },
                                            {
                                                title: 'Cancelled',
                                                dataIndex: 'cancelCount',
                                                key: 'cancelCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.redbus)){
                                                        return record.redbus.cancelCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                    
                                        ]
                            },
                            {
                                title: 'Abhibus',
                                children: [
                                            {
                                                title: 'Confirmed',
                                                dataIndex: 'confirmCount',
                                                key: 'confirmCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.abhibus)){
                                                        return record.abhibus.confirmCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                            {
                                                title: 'Cancelled',
                                                dataIndex: 'cancelCount',
                                                key: 'cancelCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.abhibus)){
                                                        return record.abhibus.cancelCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                        ],
                                   
                            },
                            {
                                        title: 'Paytm',
                                        children: [
                                            {
                                                title: 'Confirmed',
                                                dataIndex: 'confirmCount',
                                                key: 'confirmCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.paytm)){
                                                        return record.paytm.confirmCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                            {
                                                title: 'Cancelled',
                                                dataIndex: 'cancelCount',
                                                key: 'cancelCount',
                                                render : (text, record, index) => {
                                                    if(isValid(record.paytm)){
                                                        return record.paytm.cancelCount
                                                    }else{
                                                        return 0
                                                    }
                                                }
                                            },
                                        ]
                            },
                            {
                                title:"Qwikbus",
                                children: [
                                    {
                                        title: 'Confirmed',
                                        dataIndex: 'qwikbus',
                                        key: 'qwikbus',
                                        render : (text, record, index) => {
                                            if(isValid(record.qwikbus)){
                                                return record.qwikbus.confirmCount
                                            }else{
                                                return 0
                                            }
                                        }
                                    },
                                    {
                                        title: 'Cancelled',
                                        dataIndex: 'qwikbus',
                                        key: 'qwikbus',
                                        render : (text, record, index) => {
                                            if(isValid(record.qwikbus)){
                                                return record.qwikbus.cancelCount
                                            }else{
                                                return 0
                                            }
                                        }
                                    },
                                ]
                            }
                        ]}
                        summary={(pageData) => {
                            let redbusConfirm = 0 , redbusCancel = 0 , abhibusConfirm = 0 ,abhibusCancel = 0;
                            let paytmConfirm=0,paytmCancel=0 , qwikbusConfirm = 0 ,qwikbusCancel = 0;
                            for (let index = 0; index < pageData.length; index++) {
                                let{
                                    redbus,
                                    abhibus,
                                    paytm,
                                    qwikbus
                                }= pageData[index];
    
                                if(isValid(redbus)){
                                    redbusConfirm = redbusConfirm + redbus.confirmCount;
                                    redbusCancel = redbusCancel + redbus.cancelCount;
                                }   
                                if(isValid(abhibus)){
                                    abhibusConfirm = abhibusConfirm + abhibus.confirmCount;
                                    abhibusCancel = abhibusCancel + abhibus.cancelCount;
                                }   
                                if(isValid(paytm)){
                                    paytmConfirm = paytmConfirm + paytm.confirmCount;
                                    paytmCancel = paytmCancel + paytm.cancelCount;
                                }   
                                if(isValid(qwikbus)){
                                    qwikbusConfirm = qwikbusConfirm + qwikbus.confirmCount;
                                    qwikbusCancel = qwikbusCancel + qwikbus.cancelCount;
                                }   
                            }
                            return (
                                <>
                                        <Table.Summary.Row style= {{fontWeight: 'bold'}}>
                                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}>{redbusConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}>{redbusCancel}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3}>{paytmConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}>{paytmCancel}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={5}>{abhibusConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={6}>{abhibusCancel}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={7}>{qwikbusConfirm}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={7}>{qwikbusCancel}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                </>
                            )

                        }}

                    />
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    {renderGstSummeryTables(gstColumns,gstSummary)}
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Text strong>Detailed ticket GST report</Text>
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Table
                        className="bordered-table"
                        columns={ticketColums}
                        dataSource={gstTickets}
                        pagination={getPaginationDetails(isPagination, setPage, paginationSize, setPaginationSize)}
                    /> 
                </Col>
            </Row>
        </div>
    )
}

export const GstFilingReport = () => {

    const dispatch=useDispatch();

    const [filters,setFilters] = useState({});
    const [showReport,setShowReport] = useState(false);
    const [gstTickets,setGstTickets] = useState([]);
    const [gstSummary,setGstSummary] = useState([]);

    const onSuccess=(data)=>{
        setGstTickets(data.gstTickets);
        setGstSummary(data.gstSummary);
        setShowReport(true);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const [form] = Form.useForm();

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setShowReport(false);
        setLoading(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setFilters({
            fromDate,
            toDate,
            generatedTime: getLocalDateAndTime(new Date())
        })
        fetchGstFilingTickets(fromDate,toDate,onSuccess,onFailure);
    }
    
    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <div>
                        <Row gutter={16}>
                            <Col>
                                <Printer 
                                    showComponent={false}
                                    renderComponent={()=>{
                                        return (
                                            <RenderReport 
                                                gstTickets={gstTickets}
                                                gstSummary={gstSummary}
                                                formValues={form.getFieldsValue()}
                                                isPagination = {false}
                                            />
                                        )
                                    }} 
                                    zoomValue={0.5}
                                    />
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                    generateJsonToExport(filters,gstSummary,gstTickets);
                                }}>Export</Button>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col>
                                <RenderReport 
                                    gstTickets={gstTickets}
                                    gstSummary={gstSummary}
                                    formValues={form.getFieldsValue()}
                                    isPagination = {true}
                                />
                            </Col>
                        </Row>
                    </div>
                :
                    null
                }
            </div>
    )
}