import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusDetailsAction } from "../../actions/trip-create.actions";
import { tripCreateReducerSelector } from "../../selector/trip-create.selector";
import { Button, Modal, Select, message,Row,Col } from "antd";
import { getSeatLayOut } from "../../actions/admin.action";
import './change-seat-layout.style.scss';
import { SeatLayoutContainer } from "../seat-layout/seat-layout-container.component";
import { changeSeatLayoutReq } from "./change-seat-layout.module";
import { loadingAction } from "../../actions/loading-actions";
import { showConfirm } from "../custom-components/custom-component";
import { isValid, getValuesOfJSonObject } from "../../utils/utilities";
import { fetchSeatLayout } from "../../actions/home.action";
import { passengerInfoSelector } from "../../selector/passenger-form.selector";
import { resetPassengerForm } from "../../actions/ticket.action";

const {
    Option
} = Select;

const printBusesOption = (option) => {
    return <Option  value={option.id} data={option} key={option.description}>{option.description}</Option>
};

export const ChangeSeatLayout = (props) => {

    const{
        tripId,
        tripRouteId
    }=props;

    const [isModalVisible,setModalVisible] = useState(false);
    const [seatLayoutData,setSeatLayoutData] = useState([]);
    const [busId,setBusId] = useState(null);
    const [currentSeatLayoutData,setCurrentSeatLayoutData] = useState([]);
    const [layoutMapping, setLayoutMapping] = useState({});
    const [oldLayoutNoSeats, setOldLayoutNoSeats] = useState(1);
    const [reservedSeats, setReservedSeats] = useState({});
    const [adjacentNewSeats, setAdjacentNewSeats] = useState({})

    const dispatch = useDispatch();

    const handleOk=()=>{
       
    }
    const {
        selectedSeats
    } = useSelector(passengerInfoSelector);

    const handleCancel=()=>{
        setModalVisible(false);
    }

    useEffect(() => {
        if (isValid(selectedSeats)) {
            let seatsArray=getValuesOfJSonObject(selectedSeats);
            let seatLength = seatsArray.length;
            let oldSeat, newSeat, gender, seatStatus;
            if (seatLength==1) {
                setOldLayoutNoSeats(0);
            }
            else if (seatLength>1) {
                seatsArray.map((seat)=> {
                    if(["Confirmed","Blocked"].includes(seat.seatStatus)) {
                        oldSeat = seat.seatNumber;
                        gender = seat.gender;
                        seatStatus = seat.seatStatus;
                    }
                    else {
                        newSeat = seat.seatNumber;
                    }
                })
                let tempLayoutMapping = layoutMapping;
                if (isValid(oldSeat)&&isValid(newSeat)) {
                    tempLayoutMapping[oldSeat] = newSeat;
                    setLayoutMapping(tempLayoutMapping);
                    formatNewSeatLayout(oldSeat, newSeat, gender, seatStatus);
                }
                dispatch(resetPassengerForm());
                setOldLayoutNoSeats(1);
            }
        }
    }, [selectedSeats])

    useEffect(() => {
        if (isValid(tripRouteId)) {
            dispatch(fetchSeatLayout(tripRouteId,(seatsData)=>{
                setCurrentSeatLayoutData(seatsData.seats);
                let tempReserved = {};
                seatsData.seats.map((seat) => {
                    if (["Confirmed","Blocked"].includes(seat.seat_status)) {
                        tempReserved[seat.seat_number]= seat;
                    }
                })
                setReservedSeats(tempReserved);
            },()=>{}));
        }
    },[tripRouteId])
    
    useEffect(()=>{
        dispatch(getBusDetailsAction());
    },[]);

    const formatNewSeatLayout = (oldSeat, newSeat, gender, seatStatus) => {
        let tempSeatLayout = [...seatLayoutData];
        let prevSeat = `(${oldSeat})`
        let assignSeatNum = newSeat+prevSeat;
        tempSeatLayout.map((seat) => {
            let seatNum = seat.seat_number;
            let originalNum = seat.original_seat_number;
            if (seatNum.includes(prevSeat)&&originalNum!=newSeat) {
                seat.seat_number = originalNum;
                seat.gender = null;
                seat.seat_status = '';
            }
            if (originalNum==newSeat) {
                seat.seat_number = assignSeatNum;
                seat.gender = gender;
                seat.seat_status = seatStatus;
            }
        })
        setSeatLayoutData(tempSeatLayout);
    }

    const setLoading=(val)=>{
        dispatch(loadingAction(val));
    }

    const tripCreateReducer = useSelector(tripCreateReducerSelector);
    let { busesData } = tripCreateReducer;

    const onSuccess=(data)=>{
        let fetchedSeatLayout = [...data.seat_layout];
        let tempAdjacentSeats = {};
        fetchedSeatLayout.map((seat) => {
            seat.original_seat_number = seat.seat_number;
            tempAdjacentSeats[seat.seat_number] = seat.adjacent_seat_number;
        })
        setSeatLayoutData(fetchedSeatLayout);
        setAdjacentNewSeats(tempAdjacentSeats);
    }

    const onFailure=()=>{

    }

    return(
        <div className="">
                {/* <div className="selectBusTxt">Select Bus</div> */}
                <Button 
                className="bordered-btn send-driver-details-btn"  
                onClick={()=>{
                    setModalVisible(true);
                }}
                >
                    Change Layout
                </Button>
                <Modal
                      title={"Change Seat Layout"}
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      width={750}
                      footer={null}
                >
                    <Select
                        showSearch
                            className="changeBusSelect marginBottom"
                            placeholder="Select bus"
                            onChange={(value)=>{
                                setBusId(value);
                                setSeatLayoutData([]);
                                getSeatLayOut(value,onSuccess,onFailure);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                        {busesData.map(printBusesOption)}
                    </Select>
                    <Row>
                        <Col span={8} offset={2}>
                            <span style ={{fontWeight:'bold'}}>Current Layout</span>
                            <SeatLayoutContainer
                                seatsData={currentSeatLayoutData}
                                showLegend={false}
                                showFarePopOver={false}
                                blockedSeatAllowed={true}
                                noOfSeatsAllowedToClick={oldLayoutNoSeats}
                                changeOldLayout = {true}
                            />
                        </Col>
                        <Col span={10} offset={3}>
                            <span style ={{fontWeight:'bold'}}>New Layout</span>
                            <SeatLayoutContainer
                                seatsData={seatLayoutData}
                                showLegend={false}
                                showFarePopOver={false}
                                blockedSeatAllowed={false}
                                noOfSeatsAllowedToClick={oldLayoutNoSeats==0?2:0}
                            />
                        </Col>
                    </Row>

                    {seatLayoutData.length>0&&
                    <Row justify="center" align="middle" gutter={[16,16]}>
                        <Col>
                            <Button 
                            onClick={()=>{
                                let oldReservedSeats = Object.keys(reservedSeats), mappedReservedSeats = Object.keys(layoutMapping);
                                if (oldReservedSeats.length == mappedReservedSeats.length) {
                                    let seatDataMapping = {}, dupSeat = [], newSeatsList =[], mismatchSeatList = [];
                                    if (mappedReservedSeats.length>0) {
                                        mappedReservedSeats.map((seat, ind) => {
                                            let insertAdjacentSeat = true;
                                            if (ind==0) {
                                                seatDataMapping['adjacentSeats'] = {};
                                            }
                                            let newSeat = layoutMapping[seat];
                                            if (newSeatsList.includes(newSeat)&&!dupSeat.includes(newSeat)) {
                                                dupSeat.push(newSeat);
                                            }
                                            newSeatsList.push(newSeat);
                                            seatDataMapping[newSeat] = reservedSeats[seat];
                                            if (newSeat!=adjacentNewSeats[newSeat]&&newSeatsList.includes(adjacentNewSeats[newSeat])) {
                                                let compareSeat = seatDataMapping[newSeat], adjacentCompareSeat = seatDataMapping[adjacentNewSeats[newSeat]], isAdminBlocked = false;
                                                if ((compareSeat.seat_status=="Blocked"&&compareSeat.ticket_id=="")||(adjacentCompareSeat.seat_status=="Blocked"&&adjacentCompareSeat.ticket_id=="")) {
                                                    isAdminBlocked = true;
                                                }
                                                if (!isAdminBlocked&&(compareSeat.ladies_seat!=adjacentCompareSeat.ladies_seat||compareSeat.gents_seat!=adjacentCompareSeat.gents_seat)) {
                                                    if (compareSeat.ticket_id!=adjacentCompareSeat.ticket_id){
                                                        mismatchSeatList.push(newSeat);
                                                        mismatchSeatList.push(adjacentNewSeats[newSeat]);
                                                        insertAdjacentSeat= false;
                                                    }
                                                }
                                            }
                                            if (insertAdjacentSeat&&!newSeatsList.includes(adjacentNewSeats[newSeat])) {
                                                seatDataMapping['adjacentSeats'][newSeat] = adjacentNewSeats[newSeat];
                                            }
                                        })
                                        Object.keys(seatDataMapping['adjacentSeats']).map((newSeat) => {
                                            if (newSeatsList.includes(seatDataMapping['adjacentSeats'][newSeat])) {
                                                delete seatDataMapping['adjacentSeats'][newSeat];
                                            }
                                        })
                                    }
                                    if ((dupSeat.length==0&&mismatchSeatList.length==0)||mappedReservedSeats.length==0) {
                                        showConfirm(()=>{
                                            setLoading(true);
                                            changeSeatLayoutReq(tripId,busId,seatDataMapping,()=>{
                                                setLoading(false);
                                                dispatch(resetPassengerForm());
                                                setOldLayoutNoSeats(0);
                                                setSeatLayoutData([]);
                                                handleCancel();
                                                message.success("Seat Layout Changed succuessfully!");
                                            },(err)=>{
                                                message.error(err);
                                                setLoading(false);
                                            });
                                        },"Are you sure to change seat layout?");
                                    }
                                    else {
                                        let msg;
                                        if(dupSeat>0) {
                                            msg = `Seat numbers ${dupSeat} is mapped for more than one seat.`;
                                        }
                                        else {
                                            msg = `Gender mismatch for new seat layout in seat numbers ${mismatchSeatList}. Kindly select different seats.`
                                        }
                                        message.error(msg);
                                    }
                                }
                                else {
                                    let leftSeats = oldReservedSeats.filter((seat)=> !mappedReservedSeats.includes(seat));
                                    let msg = `Kindly map seat numbers ${leftSeats} from old layout to new layout.`;
                                    message.error(msg);
                                }
                            }}
                            >Change Layout</Button>
                        </Col>
                    </Row>
                    }
                </Modal>
        </div>
    )
}
