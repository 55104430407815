import { combineReducers } from 'redux';
import { userReducer } from './user.reducer';
import { homeReducer } from './home.reducer';
import { passengerFormReducer } from './passenger-form.reducer';
import { loadingReducer } from './loading.reducer';
import { tripExpenseReducer } from './trip-expense.reducer';
import { agentsReducer } from './agents.reducer';
import {tripCreateReducer} from './trip-create.reducer';
import {dashboardReducer} from './dash-board.reducer';
import {revenueReducer} from './revenue.reducer';
import {cityReducer} from './cities.reducer';
import { adminReducer } from './admin.reducer';

export const combinedReducer = combineReducers({
    userReducer:userReducer,
    homeReducer:homeReducer,
    passengerFormReducer:passengerFormReducer,
    loadingReducer:loadingReducer,
    tripExpenseReducer:tripExpenseReducer,
    agentsReducer:agentsReducer,
    tripCreateReducer,
    dashboardReducer,
    revenueReducer,
    cityReducer,
    adminReducer
});
