import React, { useContext , useState, useEffect} from "react";
import { List , Row , Col , message} from "antd";
import { isValidArray , isValid, isValidString} from "../../utils/utilities";
import './search-results.style.scss';
import DonutChartComponent from "../donut-chart-component/donut-chart.component";
import { SelectSeatsButton , CardDescription , RouteDetails, RenderNewTime} from './../common-components/search-result-item-card/search-result-item-card.component';
import { useDispatch } from "react-redux";
import { fetchSeatLayout , setSelectedSchedule} from './../../actions/home.action';
import { SeatLayoutContainer } from './../seat-layout/seat-layout-container.component';
import { PassengerForm } from '../passenger-form-component/passenger-form';
import { loadingAction } from './../../actions/loading-actions';
import { RenderFares } from './../common-components/search-result-item-card/search-result-item-card.component';
import { BlockAllSeatsComponent } from './../block-all-seats/block-all-seats.component';
import { PrintTripSheet } from "../print-trip-sheet/print-trip-sheet.component";
import { EditVechicleComponent } from './../edit-vechicle-component/edit-vechicle.component';
import { ChangeRoutesTimingsComponent } from './../change-routes-timings/change-routes-timings.component';
import { DeviceContext } from './../device-provider/device-provider';
import { ScrollableCalendar } from "../scrollable-calendar/scrollable-calendar.component";
import { ReleaseAllSeatsComponent } from "../release-all-seats/release-all-seats.component";
import { getVechicles } from "../edit-vechicle-component/edit-vechicle.module";
import { RevenueDetails } from "../revenue-details/revenue.component";
import { ViaRoutesSeatNumbers } from "../via-routes-seat-numbers/via-routes-seat-numbers";

const getSeatInfoOfAllTrips=(searchResults)=>{
    let availableSeatCount = 0 , totalSeatCount = 0;
    if(isValidArray(searchResults)){
        searchResults.forEach(element => {
            let {
                totalSeats,
                vacant
            } = element.seatsAvailbility;
            availableSeatCount = availableSeatCount + vacant;
            totalSeatCount = totalSeatCount +totalSeats;

        });
    }
    return {
        availableSeatCount,
        totalSeatCount
    }
}

const getHeaderTxt=(searchResults)=>{
    let {
        availableSeatCount,
        totalSeatCount
    } = getSeatInfoOfAllTrips(searchResults);
    return `${searchResults.length} Trip(s) available with ${availableSeatCount}/${totalSeatCount} seats`;
}

const renderSeatLayout=(seats)=>{
    if(isValidArray(seats)){
        return <SeatLayoutContainer 
                    seatsData={seats}
                    blockedSeatAllowed={true}
                    showFarePopOver={true}
                    noOfSeatsAllowedToClick={10}
                />
    }else{
        return null
    } 
}

const getVechicleDetails = (schedule)=>{
    let vechicleId = null,phoneNo = null,vechicleInfoExits = false;
    if(isValidArray(schedule)){
        vechicleId = schedule.schedule_vechicle_id;
        phoneNo = schedule.schedule_vechicle_id;
        if(isValid(schedule.schedule_vechicle_id)){
            vechicleInfoExits = true;
        }
        if(isValid(schedule.vechicle_id)){
            vechicleId = schedule.vechicle_id;
            vechicleInfoExits = true;
        }
        if(isValid(schedule.phone_no)){
            phoneNo = schedule.phone_no;
        }
    }

    return [
        vechicleId,
        phoneNo,
        vechicleInfoExits
    ]
}

export const RenderSearchResults = ({
    setSchedule,
    searchResults,
    fetchSeats,
    seats,
    stopPoint,
    setOpenTripRouteId,
    openTripRouteId,
    setSeats,
    selectedDate,
    onFareUpdateSuccess,
    onDriverUpdateSuccess,
    isMobile,
    tabkey , setKey,
    refetchDetails,
    setSearchResults,
    seatsAvailbilityStatus
})=>{

    const [selectedTripRoute, setSelectedTripRoute]= useState(null);
    const [vechicles, setVechicles] = useState([]);

    useEffect(() => {
        if (isValid(seatsAvailbilityStatus)&&isValid(selectedTripRoute)) {
            searchResults.map((results) => {
                if (results.trip_route_id == selectedTripRoute) {
                    let seatsAvailbility=results.seatsAvailbility;
                    seatsAvailbility.booked = seatsAvailbilityStatus['booked'];
                    seatsAvailbility.vacant = seatsAvailbilityStatus['vacant'];
                    seatsAvailbility.blocked = seatsAvailbilityStatus['blocked'];
                    seatsAvailbility.adminBlocked = seatsAvailbilityStatus['adminBlocked'];
                    results.seatsAvailbility = seatsAvailbility;
                }
            })
            setSearchResults([]);
            const timeout = setTimeout(() => {
                setSearchResults([...searchResults])
                // setSelectedTripRoute(null);
            }, 1);
            return () => clearTimeout(timeout)
        }

        getVechicles((data)=>{
            setVechicles(data);
        });
    }, [seatsAvailbilityStatus, selectedTripRoute]);

    if(isValidArray(searchResults)&&searchResults.length>0){
        let isSeatsAvailable=isValidArray(seats)&&seats.length>0?true:false;
        return (
            <div className= "search-list-root">
                <List
                    dataSource={searchResults}
                    header={<div className="searchResultHeaderTxt">{getHeaderTxt(searchResults)}</div>}
                    renderItem={item => {
                        let  adminBlocked= 0 ,blocked=0,booked=0,vacant=0 , totalSeats;
                        if(isValidArray(item.seatsAvailbility)){
                            let seatsAvailbility=item.seatsAvailbility;
                            adminBlocked = seatsAvailbility.adminBlocked;
                            blocked = seatsAvailbility.blocked;
                            booked = seatsAvailbility.booked;
                            totalSeats = seatsAvailbility.totalSeats;
                            vacant = seatsAvailbility.vacant;
                        }
                        let isOpenSelectSeats=openTripRouteId===item.trip_route_id?true:false;
                        let vechicleId = null , phoneNo = null , vechicleInfoExits = false ;
                            if(isValidArray(item)&&isValidArray(item.trip)&&isValid(item.trip.start_date)){
                                [vechicleId,phoneNo,vechicleInfoExits] = getVechicleDetails(item);
                            }
                            if(isMobile){
                                return(
                                    <div className="search-item-root-div">
                                    <div className="search-item-header">
                                        <CardDescription 
                                            schedule={item}
                                        />
                                    </div>
                                    <div className="search-item-second-row">
                                                <RouteDetails 
                                                    schedule={item}
                                                    selectedDate={selectedDate}
                                                />
                                                <DonutChartComponent 
                                                    AdminBlocked={adminBlocked}
                                                    Blocked={blocked}
                                                    Booked={booked}
                                                    Vacant={vacant}
                                                    basefare={0}
                                                    sleeperFare={0}
                                                />
                                    </div>
                                    <div>
                                    <Row className="flexWrapInheriant">
                                                    <Col>
                                                        <RevenueDetails 
                                                            tripDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <PrintTripSheet 
                                                            tripId={item.trip.id}
                                                            selectedSchedule={item}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            tripSheetPermission = {item.trip_sheet_permission}
                                                        />
                                                    </Col>
                                                    <Col>
                                                    <ChangeRoutesTimingsComponent
                                                        travelDate = {item.trip.trip_start_date}
                                                        scheduleId={item.schedule_id}
                                                        tripId={item.trip.id}
                                                        onTimeUpdateSuccess={onFareUpdateSuccess}
                                                    />
                                                    </Col>
                                                    <Col>
                                                        <EditVechicleComponent 
                                                            scheduleDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            scheduleId={item.schedule_id}
                                                            selectedBus={item.bus}
                                                            selectedTrip={item.trip}
                                                            vechicleId={vechicleId}
                                                            phoneNo={phoneNo}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            busId={item.bus_id}
                                                            onVechicleUpdateSuccess={(vechicleId,phoneNo)=>{
                                                                item.vechicle_id = vechicleId;
                                                                item.phone_no = phoneNo;
                                                                onDriverUpdateSuccess(item.schedule_id,vechicleId,phoneNo);
                                                            }}
                                                            secondDriverName={item.second_driver_name}
                                                            driverName={item.driver_name}
                                                            secondPhoneNo={item.second_driver_phone_no}
                                                            vechicles={vechicles}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <ViaRoutesSeatNumbers 
                                                          tripSheetPermission = {item.trip_sheet_permission}
                                                          tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                </Row>
                                    </div>
                                    <div className="search-item-fourth-row">
                                        <RenderFares 
                                            schedule={item}                                        
                                        />
                                    </div>
                                    <div className="search-item-5th-row">
                                        <SelectSeatsButton 
                                                refetchDetails={refetchDetails}
                                                vechicleInfoExits={vechicleInfoExits}
                                                showEditFare={true}
                                                resetSeats={()=>{
                                                    setSeats(null);
                                                }}
                                                fetchSeats={()=>{
                                                    setSeats(null); 
                                                    setOpenTripRouteId(item.trip_route_id);
                                                    fetchSeats(item.trip_route_id);
                                                    setSchedule(item);
                                                }}
                                                isOpenSelectSeats={isOpenSelectSeats}
                                                item={item}
                                                onFareUpdateSuccess={onFareUpdateSuccess}
                                                openTripRouteId={openTripRouteId}
                                                setOpenTripRouteId={setOpenTripRouteId}
                                                onSelectSchedule={(schedule)=>{
                                                    setSchedule(schedule);
                                                }}
                                                tabkey={tabkey} 
                                                setKey={setKey}
                                        />
                                    </div>
                                    {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&tabkey==="Select Seats"&&
                                    <div className="expandend-block">
                                        <div className="expand-root">
                                            <RenderNewTime 
                                                schedule={item}
                                            />   
                                            <div className="seat-block">
                                                {renderSeatLayout(seats)}
                                                <PassengerForm 
                                                    selectedSchedule={item}
                                                    stopPoint = {stopPoint}
                                                    fetchSeats={fetchSeats}
                                                    selectedDate={selectedDate}
                                                    setSelectedTripRoute = {setSelectedTripRoute}
                                                />                                           
                                            </div>
                                            {isSeatsAvailable&&
                                               <div>
                                                    <BlockAllSeatsComponent 
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                                    <ReleaseAllSeatsComponent
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                               </div>
                                            }     
                                        </div>
                                    </div>
                                    }
                                </div>
                                )
                            }
                            return(
                                <div className="search-item-root-div">
                                    <div className="search-item-header">
                                        <CardDescription 
                                            schedule={item}
                                        />
                                        <RenderFares 
                                            schedule={item}                                        
                                        />
                                    </div>
                                    <div className="search-item-second-row">
                                                <RouteDetails 
                                                    schedule={item}
                                                    selectedDate={selectedDate}
                                                />
                                                <Row className="flexWrapInheriant trip-time-vechile-edit-block">
                                                    <Col>
                                                        <RevenueDetails 
                                                            tripDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <PrintTripSheet 
                                                            tripId={item.trip.id}
                                                            selectedSchedule={item}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            tripSheetPermission = {item.trip_sheet_permission}
                                                        />
                                                    </Col>
                                                    <Col>
                                                    <ChangeRoutesTimingsComponent
                                                        travelDate = {item.trip.trip_start_date}
                                                        scheduleId={item.schedule_id}
                                                        tripId={item.trip.id}
                                                        onTimeUpdateSuccess = {onFareUpdateSuccess}
                                                    />
                                                    </Col>
                                                    <Col>
                                                        <EditVechicleComponent 
                                                            scheduleDescription={item.description}
                                                            travelDate={item.trip.start_date}
                                                            scheduleId={item.schedule_id}
                                                            selectedBus={item.bus}
                                                            selectedTrip={item.trip}
                                                            vechicleId={vechicleId}
                                                            phoneNo={phoneNo}
                                                            vechicleInfoExits={vechicleInfoExits}
                                                            busId={item.bus_id}
                                                            onVechicleUpdateSuccess={(vechicleId,phoneNo)=>{
                                                                item.vechicle_id = vechicleId;
                                                                item.phone_no = phoneNo;
                                                                onDriverUpdateSuccess(item.schedule_id,vechicleId,phoneNo);
                                                            }}
                                                            secondDriverName={item.second_driver_name}
                                                            driverName={item.driver_name}
                                                            secondPhoneNo={item.second_driver_phone_no}
                                                            vechicles={vechicles}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <ViaRoutesSeatNumbers 
                                                          tripSheetPermission = {item.trip_sheet_permission}
                                                          tripId={item.trip.id}
                                                        />
                                                    </Col>
                                                </Row>
                                                <DonutChartComponent 
                                                    AdminBlocked={adminBlocked}
                                                    Blocked={blocked}
                                                    Booked={booked}
                                                    Vacant={vacant}
                                                    basefare={0}
                                                    sleeperFare={0}
                                                />
                                                <div></div>
                                    </div>
                                    <SelectSeatsButton 
                                                refetchDetails={refetchDetails}
                                                vechicleInfoExits={vechicleInfoExits}
                                                showEditFare={true}
                                                resetSeats={()=>{
                                                    setSeats(null);
                                                }}
                                                fetchSeats={()=>{
                                                    setSeats(null); 
                                                    setOpenTripRouteId(item.trip_route_id);
                                                    fetchSeats(item.trip_route_id);
                                                    setSchedule(item);
                                                }}
                                                isOpenSelectSeats={isOpenSelectSeats}
                                                item={item}
                                                onFareUpdateSuccess={onFareUpdateSuccess}
                                                openTripRouteId={openTripRouteId}
                                                setOpenTripRouteId={setOpenTripRouteId}
                                                onSelectSchedule={(schedule)=>{
                                                    setSchedule(schedule);
                                                }}
                                                tabkey={tabkey} 
                                                setKey={setKey}
                                            />
                                    {isValidArray(seats)&&seats.length>0&&isOpenSelectSeats&&tabkey==="Select Seats"&&
                                    <div className="expandend-block">
                                        <div className="expand-root">
                                            <RenderNewTime 
                                                schedule={item}
                                            />   
                                            <div className="seat-block">
                                                {renderSeatLayout(seats)}
                                                <PassengerForm 
                                                    selectedSchedule={item}
                                                    fetchSeats={fetchSeats}
                                                    stopPoint = {stopPoint}
                                                    selectedDate={selectedDate}
                                                    onFareUpdateSuccess={onFareUpdateSuccess}
                                                    setSelectedTripRoute = {setSelectedTripRoute}
                                                />                                           
                                            </div>
                                            {isSeatsAvailable&&
                                                <div>
                                                    <BlockAllSeatsComponent 
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                                    <ReleaseAllSeatsComponent
                                                        selectedSchedule={item}
                                                        fetchSeats={fetchSeats}
                                                        setSelectedTripRoute = {setSelectedTripRoute}
                                                    />
                                                </div>
                                            }     
                                        </div>
                                    </div>
                                    }
                                </div>
                            )
                    }}
                />
            </div>
        )
    }else{
        return <div>No trip is available . Please change criteria and search again.</div>;
    }

}
export const SearchResultsComponent = (props) => {
    
    const {
        schedules,
        showResults,
        seats,
        setSeats,
        openTripRouteId,
        setOpenTripRouteId,
        onFareUpdateSuccess,
        onDriverUpdateSuccess,
        onChangeDateStrip,
        selectedDate,
        refetchDetails,
        setSchedules
    } = props;

    const dispatch = useDispatch();
    const [ tabkey , setKey ] = useState(null);
    const [seatsAvailbilityStatus,setSeatAvailabilityStatus] = useState({});
    const [stopPoint, setStopPoint] = useState({});


    const {
        isMobile
    } = useContext(DeviceContext);

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }


    const fetchSeats=(tripRouteId)=>{
        setLoading(true);
        dispatch(fetchSeatLayout(tripRouteId,(seatsData)=>{
            let tempSeatAvailability = {'adminBlocked': 0 ,'blocked':0,'booked':0,'vacant':0,'totalSeats' : 0};
            let fetchedSeats = seatsData.seats;
            fetchedSeats.map((seat) => {
                let seatStatus = seat.seat_status;
                if(["Empty", "empty"].indexOf(seat.seat_number) == -1){
                    tempSeatAvailability['totalSeats']++;
                    if(!isValidString(seatStatus)) {
                        tempSeatAvailability['vacant']++;
                    }
                    else {
                        if (seatStatus == "Confirmed") {
                            tempSeatAvailability['booked']++;
                        }
                        else {
                            if (isValid(seat.ticket_id)) {
                                tempSeatAvailability['blocked']++;
                            }
                            else {
                                tempSeatAvailability['adminBlocked']++;
                            }
                        }
                    }
                }
            })
            let tempStop = {'pickupDetails': seatsData.pickupDetails, 'dropDetails': seatsData.dropDetails};
            setStopPoint(tempStop);
            setSeatAvailabilityStatus({...tempSeatAvailability});
            setSeats(fetchedSeats);
            setLoading(false);
        },(errmsg)=>{
            message.error(errmsg);
            setLoading(false);
        }));
    }

    const setSchedule = (schedule)=>{
        dispatch(setSelectedSchedule(schedule));
    }



    if(showResults){
            return (
                    <div>
                        <div className="scrollable-block-root">
                            <ScrollableCalendar 
                            changeScrollableDate={onChangeDateStrip}
                            selectedDate={selectedDate}
                            />
                        </div>
                        <RenderSearchResults 
                        searchResults={schedules}
                        seats={seats}
                        fetchSeats={fetchSeats}
                        stopPoint = {stopPoint}
                        setOpenTripRouteId={setOpenTripRouteId}
                        openTripRouteId={openTripRouteId}
                        setSeats={setSeats}
                        setLoading={setLoading}
                        setSchedule={setSchedule}
                        onFareUpdateSuccess={onFareUpdateSuccess}
                        onDriverUpdateSuccess={onDriverUpdateSuccess}
                        isMobile={isMobile}
                        selectedDate={selectedDate}
                        onChangeDateStrip={onChangeDateStrip}
                        tabkey={tabkey} 
                        setKey={setKey}
                        refetchDetails={refetchDetails}
                        setSearchResults={setSchedules}
                        seatsAvailbilityStatus = {seatsAvailbilityStatus}
                        />
                    </div>
            )
    }else{
        return null
    }
}