import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { getJsonKeys, isValidArray } from '../../utils/utilities';



const generateJsonToExport=(ticketDetailsObj)=>{

    let excelData = [];

    for (const key in ticketDetailsObj) {
        if (Object.hasOwnProperty.call(ticketDetailsObj, key)) {
            const element = ticketDetailsObj[key];
            excelData.push([
                element.pnr,element.phoneNumber, element.email,element.noOfSeats, element.seatNumbers,
                element.agent,element.origin,element.destination,
                element.refundAmount,element.amountPaid,element.balance
            ]);
        }
    }


        var worksheet = XLSX.utils.aoa_to_sheet([
            [], // A1
            [], // A2
            ["Report"], // A3
            ["Consolidated Trip Sheet"],
            [],
            [],
            [
                "PNR", "Phone", "Email" , "No. Of seats" , "Seat Nums",
                "Agent" , "Origin" , "Destination" , 
                "Refund amount","Amount Paid" , "Balance"
            ],
            ...excelData
        ]);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Consolidated Trip Sheet Report');
        XLSX.writeFile(workbook, 'consolidated-trip-sheet.xlsx');
}

export const TicketExcelReport = (props) =>{
    
    const {
        tripSheet
    } = props;

    const [ticketDetailsObj , setTicketDetailsObj]=useState({});


    useEffect(()=>{
        if(isValidArray(tripSheet)){
            let ticketObject={};
            for (let index = 0; index < tripSheet.length; index++) {
                const element = tripSheet[index];
                if(isValidArray(ticketObject[element.pnr])){
                    ticketObject[element.pnr]['amountPaid'] = ticketObject[element.pnr]['amountPaid'] + element.amount_paid;
                    ticketObject[element.pnr]['noOfSeats'] = ticketObject[element.pnr]['noOfSeats'] + 1; 
                    ticketObject[element.pnr]['seatNumbers'] = ticketObject[element.pnr]['seatNumbers']+", "+element.seat_number;
                }else{
                    ticketObject[element.pnr]={
                        pnr:element.pnr,
                        phoneNumber:element.phone_number,
                        noOfSeats:1,
                        agent:element.agent_name,
                        origin:element.pick_up_city,
                        destination:element.drop_city,
                        refundAmount:'',
                        amountPaid:element.amount_paid,
                        balance:'',
                        email: element.email_id,
                        seatNumbers: element.seat_number
                    };
                }
            }
            setTicketDetailsObj(ticketObject);
        }
    },[tripSheet]);

    return (
        <div className='marginTop'>
           {/* {generateJsonToExport(ticketDetailsObj)} */}
        </div>
    )
}