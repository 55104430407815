import ReactDOM from 'react-dom';
import './index.css';
import { mountApp } from './app/App'; 
import reportWebVitals from './reportWebVitals';
import { getStore } from './app/store/store';
import { combinedReducer } from './app/reducers/combine.reducer';
import { rootSaga } from './app/sagas/root.saga';
import createSagaMiddleware from 'redux-saga';
import { Font } from '@react-pdf/renderer';

import latoRegular from './app/pages/pdfs/fonts/Lato-Italic.ttf';
import latoItalic from './app/pages/pdfs/fonts/Lato-Italic.ttf';
import latoBold from './app/pages/pdfs/fonts/Lato-Bold.ttf';
import latoBlack from './app/pages/pdfs/fonts/Lato-Black.ttf';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const store = getStore(combinedReducer,initialState,sagaMiddleware);
sagaMiddleware.run(rootSaga);

Font.register({
  family: 'Lato',
  fonts: [
    { src: latoRegular },
    { src: latoItalic, fontStyle: 'italic' },
    { src: latoBold, fontWeight: 'bold' },
    { src: latoBlack, fontWeight: 'heavy' }
  ]
})

const root = ReactDOM.createRoot(document.getElementById('root-index-html-file'));
root.render(
  mountApp(store)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 