import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { fetchcancelTickets } from './../../actions/reports.action';
import { Printer } from './../../components/custom-components/custom-component';
import { isValidArray } from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDateAndTime , getLocalDate , convertDateStringToDate} from './../../utils/date.utils';
import { roundNumber } from './../../utils/math.utils';
import { isValid } from "../../utils/utilities";
import { getRangeOptions , getPaginationDetails, getDefaultPageSize} from './reports.module';
import * as XLSX from 'xlsx';


const generateJsonToExport=(form,cancelledTickets,transactionDetailsByTicketId)=>{

    let filters=form.getFieldsValue();
    let reportsCancelledXmlData=[];

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach(element => {
            let bookingTIme=getLocalDateAndTime(element.booking_time);
            let startDate , cancelTime;
            if(isValid(element.start_date)){
                 startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]))
            } 
            if(isValid(element.cancellation_time)){
                cancelTime=getLocalDate(convertDateStringToDate(element.cancellation_time.split('T')[0]));
            }           
            let refund = roundNumber(element.amount_paid-element.cancellation_penalty);
            let amount_paid=isValid(element.amount_paid)?element.amount_paid:element.total_ticket_cost;
            let automaticRefund = element.automatic_refund === 1? 'Yes': 'No';

            let orderNo = '';
            if(isValid(transactionDetailsByTicketId)&&isValid(transactionDetailsByTicketId[element.ticket_id])){
                orderNo = transactionDetailsByTicketId[element.ticket_id].order_no
            }

            reportsCancelledXmlData.push([
                element.pnr,element.description,element.seat_count,element.cancel_seat_count,
                element.seat_number,element.total_ticket_cost,amount_paid,
                element.cancellation_penalty,refund,bookingTIme,startDate,cancelTime,
                orderNo,element.cancelled_by,automaticRefund
            ])
        });
    }

    let agentName=filters.isSelectAllAgents?'all':filters.agentName;
    let dateType =filters.isBookingDate?"Booking":"Travel";
    let generatedTime = getLocalDateAndTime(new Date());


            var worksheet = XLSX.utils.aoa_to_sheet([
                [], // A1
                [], // A2
                ["Report","Agents","From Date", "To Date","Type of date" , "Generated day/time"], // A3
                ["Web Ticket Cancelled Report",agentName,filters.fromDate,filters.toDate,dateType,generatedTime],
                [],
                [],
                [
                    "PNR","Trip description", "Total seats" , "Cancelled seats count" ,  
                    "Seat nos" , "Total amount" , "Amount Paid" , 
                    "Cancellation penalty","Refund Amount" , "Booked Date",
                    "Travel Date","Cancelled Date","Transaction details","Cancelled by","Automatic refund"
                ],
                ...reportsCancelledXmlData
            ]);

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Web Ticket Cancellation Report');
            XLSX.writeFile(workbook, 'web-ticket-cancel-report.xlsx');
}


const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const RenderReport=({cancelledTickets,formValues,pagination,transactionDetailsByTicketId})=>{

    let {
        dateRange
    }=formValues;

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const cancelTicketColumns = [
        {
            title:"SN",
            key:"index",
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title:'Agent Name',
            key:'name',
            dataIndex:'name'
        },
        {
            title:"Trip name",
            key:"description",
            dataIndex:"description"
        },
        {
            title:"PNR",
            key:"pnr",
            dataIndex:'pnr'
        },
        {
            title:"Total seats",
            key:"seat_count",
            dataIndex:'seat_count'
        },
        {
            title:"Cancelled seats count",
            key:"cancel_seat_count",
            dataIndex:'cancel_seat_count'
        },
        {
            title:"Seat nos",
            key:"seat_number",
            dataIndex:"seat_number"
        },
        {
            title:"Total amount",
            key:"total_ticket_cost",
            dataIndex:"total_ticket_cost",
            render : (text, record, index) => {
                return text;
            }
        },
        {
            title:"Amount Paid",
            key:"amount_paid",
            dataIndex:"amount_paid",
            render : (text, record, index) => {
                return isValid(text)?text:record.total_ticket_cost;
            }
        },
        {
            title:"Cancellation penalty",
            key:"cancellation_penalty",
            dataIndex:"cancellation_penalty"
        },
        {
            title:"Refund Amount",
            key:"cancellation_penalty",
            render : (text, record, index) => roundNumber(record.amount_paid-record.cancellation_penalty)
        },
        {
            title:"Booked Date",
            key:"booking_time",
            render : (text, record, index) => {
                if(isValid(record.booking_time)){
                    return getLocalDate(convertDateStringToDate(record.booking_time.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:"Travel Date",
            key:"start_date",
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:"Cancelled Date",
            key:"cancellation_time",
            render : (text, record, index) => {
                if(isValid(record.cancellation_time)){
                    return getLocalDate(convertDateStringToDate(record.cancellation_time.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:"Transaction details",
            key:'ticket_id',
            dataIndex : 'ticket_id',
            render : (text, record, index) => {
                if(isValid(text)&&isValid(transactionDetailsByTicketId)&&isValid(transactionDetailsByTicketId[text])){
                    return transactionDetailsByTicketId[text].order_no
                }else{
                    return ''
                }
            }
        },
        {
            title:"Cancelled by",
            key:"cancelled_by",
            dataIndex:"cancelled_by"
        },
        {
            title:"Automatic refund",
            key:"automatic_refund",
            dataIndex:"automatic_refund",
            render : (text, record, index) => {
                return record.automatic_refund === 1? 'Yes': 'No'
            }
        }
    ]

    if(isValidArray(cancelledTickets)&&isValidArray(dateRange)){
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>Web ticket cancelled  report</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text> 
                                </Col>
                            </Row>
                            <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Cancelled tickets</Text>
                                    </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table 
                                        className="bordered-table"
                                        columns={cancelTicketColumns}
                                        dataSource={cancelledTickets}
                                        pagination={getPaginationDetails(pagination,setPage, paginationSize, setPaginationSize)}
                                    />
                                </Col>
                            </Row>
                        </div>
            )
    }else{
        return null
    }
}

export const WebTicketCancelReport = () => {


    const [cancelledTickets,setCancelledTickets]=useState(undefined);
    const [showReport,setShowReport]=useState(false);
    const [transactionDetailsByTicketId,setTransactionDetailsByTicketId]=useState({});

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        let {
            cancelticketSearchSheet
        }= data;
        setLoading(false);
        setShowReport(true);
        setCancelledTickets(cancelticketSearchSheet.canceledTickets);
        setTransactionDetailsByTicketId(cancelticketSearchSheet.transactionDetailsByTicketId);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setShowReport(false);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setLoading(true);
        fetchcancelTickets(fromDate,toDate,true,onSuccess,onFailure);
    }

    const [form] = Form.useForm();
    
    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <Row gutter={16}>
                        <Col>
                              <Button onClick={()=>{
                                   generateJsonToExport(form,cancelledTickets,transactionDetailsByTicketId);
                                }}>Export</Button>
                        </Col>
                        <Col>
                            <Printer 
                                showComponent={false}
                                renderComponent={()=>{
                                    return (
                                        <RenderReport 
                                            cancelledTickets={cancelledTickets}
                                            transactionDetailsByTicketId={transactionDetailsByTicketId}
                                            formValues={form.getFieldsValue()}
                                            pagination={false}
                                        />
                                    )
                                }} 
                                zoomValue={0.5}
                                />
                        </Col>
                    </Row>
                    :
                    null
                }
                <RenderReport 
                    cancelledTickets={cancelledTickets}
                    transactionDetailsByTicketId={transactionDetailsByTicketId}
                    formValues={form.getFieldsValue()}
                    pagination={true}
                 />
            </div>
    )
}