import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table ,Radio , Tooltip } from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { fetchBookingSheet } from './../../actions/reports.action';
import { Printer } from './../../components/custom-components/custom-component';
import { insertAtPosition, isValidArray, isValidNumber, removeArrayIndexValue } from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDateAndTime , getLocalDate , convertDateStringToDate, getCurrentDateInIST, getReadableDate, isDateInRange, decrementDays, incrementDays} from './../../utils/date.utils';
import { subtractFloatingPoints as SFP , addFloatingPoints as AFP, roundNumber, getValueFromPercentage } from './../../utils/math.utils';
import { isValid } from "../../utils/utilities";
import { getRangeOptions , getPaginationDetails, getDefaultPageSize} from './reports.module';
import * as XLSX from 'xlsx';
import { InfoCircleOutlined } from '@ant-design/icons';


const getColsForDiscountedTickets=(confimColumns)=>{
    let cols = [...confimColumns];
    removeArrayIndexValue(cols,4);
    insertAtPosition(cols,4,{
            title:"Fare",
            key:"fare",
            dataIndex:"fare",
            render : (text, record, index) => {
                if(record.traveller_status == "Confirmed"){                    
                    return text;
                }else{
                    return null;
                }
            }
            
    });
    insertAtPosition(cols,5,{
        title:"Discounted Fare (A)",
        key:"discounted_fare",
        dataIndex:"discounted_fare",
        render : (text, record, index) => {
            if(record.traveller_status == "Confirmed"){                    
                return text;
            }else{
                return null;
            }
        }
    })
    return cols;
}

const getConfirmData=(confirmedTickets)=>{
    let confirmData = [],discountData=[];
    let summaryTotalAmount = 0,summaryTotalComm = 0,summaryQwikBusComm = 0;
    let netAmountDebited = 0, summaryDiscountedFare = 0;
    for (let index = 0; index < confirmedTickets.length; index++) {
        const element = confirmedTickets[index];
        let {amount_paid , commission,comm_for_qwikbus,discounted_fare} = element;
        if(element.fare - element.discounted_fare == 0){
            confirmData.push(element);
            if(isValidNumber(amount_paid)){
                summaryTotalAmount = summaryTotalAmount + amount_paid;
                netAmountDebited = netAmountDebited+discounted_fare-commission;
            }
            if(isValidNumber(commission)){
                summaryTotalComm = summaryTotalComm + commission;
            }
            if(isValid(comm_for_qwikbus)){
                summaryQwikBusComm = comm_for_qwikbus + summaryQwikBusComm;
            }
            if(isValid(discounted_fare)){
                summaryDiscountedFare = summaryDiscountedFare + discounted_fare;
            }
        }else{
            discountData.push(element);
        }
    }
    let confirmedSummaryData = {summaryTotalAmount,summaryTotalComm,summaryQwikBusComm, netAmountDebited, summaryDiscountedFare}
    return {
        confirmData,discountData, confirmedSummaryData
    }
}

const getCancelledTicketSummary = (cancelledTicket, dateRange) => {
    let summaryTotalAmount = 0, summaryCancellationPenality = 0, summaryOfRefund = 0, discFareSummary = 0, cancellationPenalitySummary = 0;
    cancelledTicket.map((element) => {
        let {amount_paid , commission,cancellation_penalty , cancellation_penality_percentage, discounted_fare, booking_time} = element;
        if(isValidNumber(amount_paid)){
            summaryTotalAmount = summaryTotalAmount + amount_paid;
        }
        if(isValidNumber(discounted_fare)){
            discFareSummary = discFareSummary + discounted_fare;
        }
        if(isValidNumber(cancellation_penalty)){
            summaryCancellationPenality = summaryCancellationPenality + cancellation_penalty;
            let fareAmount = global.operatorCode == "DAFIDY"?discounted_fare:amount_paid
            summaryOfRefund = summaryOfRefund + fareAmount - cancellation_penalty;
        }
        if (isDateInRange(getAPIDateFormat(getCurrentDateInIST(booking_time)), getAPIDateFormat(decrementDays(dateRange[0],1)), getAPIDateFormat(incrementDays(dateRange[1],1)))) {
            cancellationPenalitySummary+=(cancellation_penalty*75)/100;
        }
        else {
            let agentPenality = getValueFromPercentage(cancellation_penalty,cancellation_penality_percentage);
            cancellationPenalitySummary+= (cancellation_penalty+commission-agentPenality-discounted_fare)
        }
    })
    return {
        summaryTotalAmount, summaryCancellationPenality, summaryOfRefund, discFareSummary, cancellationPenalitySummary
    }
}

const getNameByRecord=(text,record)=>{
    if(global.operatorCode == "DAFIDY"){
        return record.operator_name;
    }else{
        return text;
    }
}

const getTitleByRecord=()=>{
    if(global.operatorCode == "DAFIDY"){
        return "Operator name";
    }else{
        return "Agency";
    }
}

const getFareByRecord=(text,record)=>{
        return text;
}

const getAmountTitleByRecord=()=>{
    if(global.operatorCode == "DAFIDY"){
        return "Fare";
    }else{
        return "Total Amount";
    }
}


const generateJsonToExport=(form,confirmedTickets,cancelledTickets,confirmedDiscountedTickets)=>{

    let filters=form.getFieldsValue();
    let reportsConfirmedXmlData=[];
    let reportsCancelledXmlData=[];
    let reportDiscountXmlData=[];
    let confimColumns = [] , cancelColumns = [] , discountColumns = [];

    let agentName=filters.isSelectAllAgents?'all':filters.agentName;
    let dateType =filters.isBookingDate?"Booking":"Travel";
    let generatedTime = getLocalDateAndTime(new Date());

    if(isValidArray(confirmedTickets)&&confirmedTickets.length>0){
        confirmedTickets.forEach(element => {
            let startDate , bookingTIme;
            let netAmount=AFP(SFP(element.amount_paid,element.net_commission), 0);
            if(isValid(element.start_date)){
                 startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]))
            }
            if(isValid(element.booking_time)){
                bookingTIme = getLocalDate(convertDateStringToDate(element.booking_time.split('T')[0]))
            }
            if(global.operatorCode == "DAFIDY"){
                reportsConfirmedXmlData.push([
                    element.pnr,element.seat_number,element.operator_name,element.payment_type,
                    element.amount_paid,element.discounted_fare,element.commission,
                    roundNumber(element.amount_paid - element.commission),
                    element.description,bookingTIme,startDate
                ]);
            }else{
                reportsConfirmedXmlData.push([
                    element.pnr,element.seat_number,element.name,element.payment_type,
                    element.discounted_fare,element.commission,
                    element.tds_on_commission,element.net_commission,
                    netAmount,element.description,bookingTIme,startDate
                ]);
            }
        });
    }

    if(isValidArray(confirmedDiscountedTickets)&&confirmedDiscountedTickets.length>0){
        confirmedDiscountedTickets.forEach(element => {
            let startDate , bookingTIme;
            let netAmount=AFP(SFP(element.amount_paid,element.net_commission), 0);
            if(isValid(element.start_date)){
                 startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]))
            }
            if(isValid(element.booking_time)){
                bookingTIme = getLocalDate(convertDateStringToDate(element.booking_time.split('T')[0]))
            }
            if(global.operatorCode == "DAFIDY"){
                reportDiscountXmlData.push([
                    element.pnr,element.seat_number,element.operator_name,element.payment_type,
                    element.fare,element.discounted_fare,element.commission,roundNumber(element.amount_paid - element.commission),
                    element.description,bookingTIme,startDate
                ]);
            }else{
                reportDiscountXmlData.push([
                    element.pnr,element.seat_number,element.name,element.payment_type,
                    element.fare,element.discounted_fare,element.commission,
                    netAmount,element.description,bookingTIme,startDate
                ]);
            }
        });
    }

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach(element => {
            let bookingTIme=getLocalDateAndTime(element.booking_time);
            let startDate;
            if(isValid(element.start_date)){
                 startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]))
            }            
            let refund = SFP(element.amount_paid,element.cancellation_penalty);
            let agentPenality = getValueFromPercentage(element.cancellation_penalty,element.cancellation_penality_percentage);
             
            if(global.operatorCode == "DAFIDY"){
                reportsCancelledXmlData.push([
                    element.pnr,element.seat_number,element.operator_name,element.payment_type,
                    element.amount_paid,element.commission,
                    element.cancellation_penalty,refund,roundNumber(element.cancellation_penalty - agentPenality),element.description,bookingTIme,startDate
                ]);
            }else{
                reportsCancelledXmlData.push([
                    element.pnr,element.seat_number,element.name,element.payment_type,
                    element.amount_paid,element.commission,element.tds_on_commission,element.net_commission,
                    element.cancellation_penalty,refund,roundNumber(element.cancellation_penalty - agentPenality),element.description,bookingTIme,startDate
                ]);
            }
        });

    }

    if(global.operatorCode == "DAFIDY"){
        confimColumns = [
            "PNR", "Seat nos" , "Operator name" , 
            "Payment type" , "Total amount" ,"Fare" ,"Commission including GST" , "Net Amount Deducted",
            "Trip Description","Booked date","Travel date"
        ];
        cancelColumns = [
            "PNR", "Seat nos" , "Opertor name" ,  
            "Payment type" , "Total amount" , "Commision" , 
            "Cancellation penality",
            "Refund","Net Amount Deducted (C - 25% of C)","Trip description","Booked date","Travel date"
        ];
        discountColumns = [
            "PNR", "Seat nos" , "Operator name" , 
            "Payment type" ,"Fare" ,"Discounted Fare","Commission including GST" , "Net Amount Deducted",
            "Trip Description","Booked date","Travel date"
        ];
    }else{
        confimColumns = [
            "PNR", "Seat nos" , "Agency" , 
            "Payment type" , "Total amount" , "Comm" , 
            "TDS","Net Comm" , "Net Amount",
            "Trip Description","Booked date","Travel date"
        ];
        cancelColumns = [
            "PNR", "Seat nos" , "Agency" ,  
            "Payment type" , "Total amount" , "Commision" , 
            "TDS","Net Comm" , "Cancellation penality",
            "Refund","Net Amount Deducted (C - 25% of C)","Trip description","Booked date","Travel date"
        ];
        discountColumns = [
            "PNR", "Seat nos" , "Agency" ,"Payment type" ,
            "Fare" ,"Discounted Fare","Commission including GST" , "Net Amount Deducted",
            "Trip Description","Booked date","Travel date"
        ];
    }

    
        var worksheet = XLSX.utils.aoa_to_sheet([
            [], // A1
            [], // A2
            ["Report","Agents","From Date", "To Date","Type of date" , "Generated day/time"], // A3
            ["Booking report",agentName,filters.fromDate,filters.toDate,dateType,generatedTime],
            [],
            [],
            ["Confirmed Tickets"],
            confimColumns,
            ...reportsConfirmedXmlData,
            [],
            [],
            ["Cancelled Tickets"],
            cancelColumns,
            ...reportsCancelledXmlData,
            [],
            [], 
            ["Exceptional discount"],
            discountColumns,
            ...reportDiscountXmlData
        ]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Booked Seats Report');
        XLSX.writeFile(workbook, 'booked-tickets-report.xlsx');
}


const getFilterOptions=(data)=>{
    let key = 'name';
    if(global.operatorCode == "DAFIDY"){
        key = 'operator_name';
    }

    if(isValidArray(data)&&data.length>0){
        let filterOptions=[],insertedEntries=[];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if(insertedEntries.indexOf(element[key]) == -1){
                insertedEntries.push(element[key]);
                filterOptions.push({
                    text:element[key],
                    value:element[key]
                })
            }
        }
        return filterOptions;
    }else{
        return []
    }
}



const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const RenderReport=({cancelledTickets,confirmedTickets,confirmedDiscountedTickets,formValues,confirmedTicketSummary,cancelledTicketSummary,pagination})=>{

    let {
        dateRange,
        isBooking
    }=formValues;

    const [cancelledPage, setCancelledPage] = useState(1);
    const [confirmedPage, setConfirmedPage] = useState(1);
    const [cancelPaginationSize, setCancelPaginationSize] = useState(getDefaultPageSize);
    const [confirmPaginationSize, setConfirmPaginationSize] = useState(getDefaultPageSize);

    const cancelTicketColumns = [
        {
            title:"SN",
            key:"index",
            render : (text, record, index) => (cancelledPage - 1) * cancelPaginationSize + index+1
        },
        {
            title:"PNR",
            key:"pnr",
            dataIndex:'pnr'
        },
        {
            title:"Seat nos",
            key:"seat_number",
            dataIndex:"seat_number"
        },
        {
            title:getTitleByRecord(),
            key:"name",
            dataIndex:"name",
            filters:getFilterOptions(cancelledTickets),
            onFilter: (value, record) => {
                let key = 'name';
                if(global.operatorCode == "DAFIDY"){
                    key = 'operator_name';
                }
                return record[key].indexOf(value) === 0;
            },
            render:(text,record)=>(getNameByRecord(text,record))
        },
        {
            title:"Payment type",
            key:"payment_type",
            dataIndex:"payment_type"
        },
        {
            title:getAmountTitleByRecord(),
            key:global.operatorCode == "DAFIDY"?"discounted_fare":"amount_paid",
            dataIndex:global.operatorCode == "DAFIDY"?"discounted_fare":"amount_paid",
            render:(text,record)=>(getFareByRecord(text,record))
        },
        {
            title:"Cancellation Penalty",
            key:"cancellation_penalty",
            dataIndex:"cancellation_penalty"
        },
    ];

    if(global.operatorCode == "DAFIDY"){
        cancelTicketColumns.push({
            title:`Refund Amount`,
            key:"refund_amount",
            dataIndex:"refund_amount",
            render: (text, record) => {
                return roundNumber(record.discounted_fare-record.cancellation_penalty)
            }
        });

        cancelTicketColumns.push({
            title:`Net Amount Deducted`,
            key:"net_deducted",
            dataIndex:"net_deducted",
            render : (text, record, index) => {
                let deductedAmount = 0;
                if (isDateInRange(getAPIDateFormat(getCurrentDateInIST(record.booking_time)), getAPIDateFormat(decrementDays(dateRange[0],1)), getAPIDateFormat(incrementDays(dateRange[1],1)))) {
                    deductedAmount = (record.cancellation_penalty*75)/100;
                }
                else {
                    let agentPenality = getValueFromPercentage(record.cancellation_penalty,record.cancellation_penality_percentage);
                    deductedAmount = (record.cancellation_penalty+record.commission-agentPenality-record.discounted_fare)
                }
                return roundNumber(deductedAmount)
            }

        });
    }
    else {
        cancelTicketColumns.push({
            title:"Refund Amount (B)",
            key:"cancellation_penalty",
            render : (text, record, index) => SFP(record.amount_paid,record.cancellation_penalty)
        })
    }

    cancelTicketColumns.push({
        title:"Trip Description",
        key:"description",
        dataIndex:"description"
    });

    cancelTicketColumns.push(  {
        title:"Booked Date",
        key:"booking_time",
        render : (text, record, index) => {
            if(isValid(record.booking_time)){
                return getReadableDate(getCurrentDateInIST(record.booking_time))
            }else{
                return ''
            }
        }
    });

    cancelTicketColumns.push( {
        title:"Travel Date",
        key:"start_date",
        render : (text, record, index) => {
            if(isValid(record.start_date)){
                return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
            }else{
                return ''
            }
        }
    });
    

    const confirmedTicketColumns=[
        {
            title:"SN",
            key:"index",
            render : (text, record, index) => (confirmedPage - 1) * confirmPaginationSize + index+1
        },
        {
            title:"PNR",
            key:"pnr",
            dataIndex:'pnr',
            width:150,
            render:(text,record)=>{
                if(record.traveller_status == "Rescheduled"){                    
                    return(
                        <Tooltip title={`Rescheduled to ${record.original_pnr}`}>
                            <span style={{marginRight:5}}>
                                {text}
                            </span>
                            <InfoCircleOutlined />
                        </Tooltip>
                    )                     
                }else{
                    
                    if(isValid(record.rescheduled_pnr)&&record.traveller_status == "Confirmed"){
                        return (
                            <Tooltip title={`Rescheduled from ${record.rescheduled_pnr}`}>
                                <span style={{marginRight:5}}>
                                    {text}
                                </span>
                                <InfoCircleOutlined />
                            </Tooltip>
                        );
                    }else{
                        return text;
                    }
                }
            }
        },
        {
            title:"Seat nos",
            key:"seat_number",
            dataIndex:"seat_number"
        },
        {
            title:getTitleByRecord(),
            key:"name",
            dataIndex:"name",
            filters:getFilterOptions(confirmedTickets),
            onFilter: (value, record) => {
                let key = 'name';
                if(global.operatorCode == "DAFIDY"){
                    key = 'operator_name';
                }
                return record[key].indexOf(value) === 0;
            },
            render:(text,record)=>(getNameByRecord(text,record))
        },
        {
            title:"Payment type",
            
            key:"payment_type",
            dataIndex:"payment_type"
        },
        {
            title:"Fare (A)",
            key:"discounted_fare",
            dataIndex:"discounted_fare",
            render : (text, record, index) => {                
                if(record.traveller_status == "Confirmed"){                    
                    return text;
                }else{
                    return null;
                }
            }
        },
        {
            title:"Commission including GST (B)",
            key:"commission",
            dataIndex:"commission",
            render : (text, record, index) => {
                if(record.traveller_status == "Confirmed"){                    
                    return text;
                }else{
                    return null;
                }
            }
        },
        {
            title:"TDS",
            key:"tds_on_commission",
            dataIndex : "tds_on_commission",
            render : (text, record, index) => {
                if(record.traveller_status == "Confirmed"){                    
                    return text;
                }else{
                    return null;
                }
            }
        },
        {
            title:"Net Comm",
            key:"net_commission",
            dataIndex:"net_commission",
            render : (text, record, index) => {
                if(record.traveller_status == "Confirmed"){                    
                    return text;
                }else{
                    return null;
                }
            }
        },
        {
            title:"Net Amount",
            key:"id",
            render : (text, record, index) => {
                if(record.traveller_status == "Confirmed"){                    
                    return AFP(SFP(record.amount_paid,record.net_commission), 0)
                }else{
                    return null
                }
            }
        }];
        if(global.operatorCode == "DAFIDY"){
            confirmedTicketColumns.push({
                title:"Net Amount Deducted (A-B)",
                key:"commission",
                dataIndex:"commission",
                render:(text,record)=>{
                    if(record.traveller_status == "Confirmed"){                    
                        return roundNumber(record.discounted_fare-record.commission);
                    }else{
                        return null;
                    }
                }
            });
        }
        confirmedTicketColumns.push({
            title:"Trip Description",
            key:"description",
            dataIndex:"description"
        });
        confirmedTicketColumns.push({
            title:"Booked Date",
            key:"booking_time",
            render : (text, record, index) => {
                if(isValid(record.booking_time)){
                    return getLocalDate(convertDateStringToDate(record.booking_time.split('T')[0]))
                }else{
                    return ''
                }
            }
        });
        confirmedTicketColumns.push({
            title:"Travel Date",
            key:"start_date",
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        });

        confirmedTicketColumns.push({
            title:"Rescheduled",
            key:"start_date",
            render : (text, record, index) => {
                if(record.traveller_status == "Rescheduled"){
                    return "Yes"
                }else{
                    return "No"
                }
            }
        });

    if(global.operatorCode == "DAFIDY"){
        confirmedTicketColumns.splice(7,3);
        
        confirmedTicketColumns.splice(4,1);
        cancelTicketColumns.splice(4,1);//payment type
    }

    if((isValidArray(cancelledTickets)||isValidArray(confirmedTickets))&&isValidArray(dateRange)){
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>Booking report</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text> 
                                </Col>
                            </Row>
                            {isBooking!="cancel"&&<Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Confirmed tickets</Text>
                                    </Col>
                            </Row>}
                            {isBooking!="cancel"&&isValid(confirmedTicketSummary)&&
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Total Fare : </Text>
                                        {roundNumber(confirmedTicketSummary.summaryDiscountedFare)}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Total Commission : </Text>{roundNumber(confirmedTicketSummary.summaryTotalComm)}
                                    </Text>
                                </Col>
                            </Row>
                            }
                            {isBooking!="cancel"&&<Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table 
                                        className="bordered-table"
                                        columns={confirmedTicketColumns}
                                        dataSource={confirmedTickets}
                                        pagination={getPaginationDetails(pagination, setConfirmedPage, confirmPaginationSize, setConfirmPaginationSize)}
                                        summary={(pageData ) => {
                                            let summaryTotalAmount = 0;
                                            let summaryTotalComm = 0;
                                            let summaryQwikBusComm = 0;
                                            let netAmountDebited = 0;
                                            let summaryDiscountedFare = 0;
                                            pageData.forEach(({ amount_paid , commission,comm_for_qwikbus,discounted_fare }) => {
                                              if(isValidNumber(amount_paid)){
                                                summaryTotalAmount = summaryTotalAmount + amount_paid;
                                                netAmountDebited = netAmountDebited+discounted_fare-commission;
                                              }
                                              if(isValidNumber(commission)){
                                                summaryTotalComm = summaryTotalComm + commission;
                                              }
                                              if(isValid(comm_for_qwikbus)){
                                                summaryQwikBusComm = comm_for_qwikbus + summaryQwikBusComm;
                                              }
                                              if(isValid(discounted_fare)){
                                                summaryDiscountedFare = summaryDiscountedFare + discounted_fare;
                                              }
                                            });
                                                                                        
                                            return(
                                                <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                  <Table.Summary.Cell ></Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                    {global.operatorCode != "DAFIDY"&&
                                                        <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                    }
                                                  {/* <Table.Summary.Cell >{roundNumber(summaryTotalAmount)}</Table.Summary.Cell> */}
                                                  <Table.Summary.Cell >{roundNumber(summaryDiscountedFare)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{roundNumber(summaryTotalComm)}</Table.Summary.Cell>
                                                    {global.operatorCode == "DAFIDY"&&
                                                        <Table.Summary.Cell >{roundNumber(netAmountDebited)}</Table.Summary.Cell>
                                                    }
                                               <Table.Summary.Cell >{}</Table.Summary.Cell>
                                               <Table.Summary.Cell >{}</Table.Summary.Cell>
                                               <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                </Table.Summary.Row>
                                              </Table.Summary>
                                            )
                                    }}
                                    // rowClassName={(record) => {
                                    //     if(!isValid(record.original_pnr) && record.traveller_status == "Confirmed"){
                                    //         return "rescheduledTickets"
                                    //     }else{
                                    //         return ""
                                    //     }
                                    // }}
                                    />
                                </Col>
                            </Row>}
                            <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Cancelled tickets</Text>
                                    </Col>
                            </Row>
                            {isBooking!="cancel"&&isValid(cancelledTicketSummary)&&<>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>{global.operatorCode == "DAFIDY"?"Total Fare":"Total Amount"} : </Text>
                                        {roundNumber(global.operatorCode == "DAFIDY"?cancelledTicketSummary.discFareSummary: cancelledTicketSummary.summaryTotalAmount)}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Cancel Penalty : </Text>{roundNumber(cancelledTicketSummary.summaryCancellationPenality)}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Refund : </Text>{roundNumber(cancelledTicketSummary.summaryOfRefund)}
                                    </Text>
                                </Col>
                            </Row>
                            </>}
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table
                                        className="bordered-table"
                                        columns={cancelTicketColumns}
                                        dataSource={cancelledTickets}
                                        pagination={getPaginationDetails(pagination, setCancelledPage, cancelPaginationSize, setCancelPaginationSize)}
                                        summary={(pageData ) => {
                                            let summaryTotalAmount = 0;
                                            let summaryCancellationPenality = 0;
                                            let summaryOfRefund = 0;
                                            let discFareSummary = 0;

                                            let cancellationPenalitySummary = 0 ;
                                            pageData.forEach(({ amount_paid , commission,cancellation_penalty , cancellation_penality_percentage, discounted_fare, comm_for_qwikbus, booking_time}) => {
                                              if(isValidNumber(amount_paid)){
                                                summaryTotalAmount = summaryTotalAmount + amount_paid;
                                              }
                                              if(isValidNumber(discounted_fare)){
                                                discFareSummary = discFareSummary + discounted_fare;
                                              }
                                              if(isValidNumber(cancellation_penalty)){
                                                summaryCancellationPenality = summaryCancellationPenality + cancellation_penalty;
                                                let fareAmount = global.operatorCode == "DAFIDY"?discounted_fare:amount_paid
                                                summaryOfRefund = summaryOfRefund + fareAmount - cancellation_penalty;
                                              }
                                                if (isDateInRange(getAPIDateFormat(getCurrentDateInIST(booking_time)), getAPIDateFormat(decrementDays(dateRange[0],1)), getAPIDateFormat(incrementDays(dateRange[1],1)))) {
                                                    cancellationPenalitySummary+=(cancellation_penalty*75)/100;
                                                }
                                                else {
                                                    let agentPenality = getValueFromPercentage(cancellation_penalty,cancellation_penality_percentage);
                                                    cancellationPenalitySummary+= (cancellation_penalty+commission-agentPenality-discounted_fare)
                                                }
                                            });
                                            
                                            
                                            return(
                                                <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                  <Table.Summary.Cell ></Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                    {global.operatorCode != "DAFIDY"&&
                                                        <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                    }
                                                  <Table.Summary.Cell >{roundNumber(global.operatorCode == "DAFIDY"?discFareSummary: summaryTotalAmount)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{roundNumber(summaryCancellationPenality)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{roundNumber(summaryOfRefund)}</Table.Summary.Cell>
                                                  {global.operatorCode == "DAFIDY"&&
                                                  <Table.Summary.Cell >{roundNumber(cancellationPenalitySummary)}</Table.Summary.Cell>
                                                  }
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                </Table.Summary.Row>
                                              </Table.Summary>
                                            )
                                    }}
                                  />
                                </Col>
                            </Row>
                            {isValidArray(confirmedDiscountedTickets)&&confirmedDiscountedTickets.length>0?
                                <div>
                                     <Row gutter={[16,16]} className="marginTop">
                                            <Col>
                                                <Text strong>Exceptional discount</Text>
                                            </Col>
                                    </Row>
                                    <Row gutter={[16,16]} className="marginTop">
                                        <Col>
                                            <Table 
                                                className="bordered-table"
                                                // rowClassName={(record) => {
                                                //     if(!isValid(record.original_pnr) && record.traveller_status == "Confirmed"){
                                                //     return "rescheduledTickets"
                                                // }else{
                                                //     return ""
                                                // }}}
                                                columns={getColsForDiscountedTickets(confirmedTicketColumns)}
                                                dataSource={confirmedDiscountedTickets}
                                                pagination={getPaginationDetails(pagination, setConfirmedPage, confirmPaginationSize, setConfirmPaginationSize)}
                                                summary={(pageData ) => {
                                                    let summaryTotalAmount = 0;
                                                    let summaryTotalComm = 0;
                                                    let summaryQwikBusComm = 0;
                                                    let netAmountDebited = 0;
                                                    let summaryDiscountedFare = 0;
                                                    pageData.forEach(({ fare , commission,comm_for_qwikbus,discounted_fare }) => {
                                                    if(isValidNumber(fare)){
                                                        summaryTotalAmount = summaryTotalAmount + fare;
                                                        netAmountDebited = netAmountDebited+discounted_fare-commission;
                                                    }
                                                    if(isValidNumber(commission)){
                                                        summaryTotalComm = summaryTotalComm + commission;
                                                    }
                                                    if(isValid(comm_for_qwikbus)){
                                                        summaryQwikBusComm = comm_for_qwikbus + summaryQwikBusComm;
                                                    }
                                                    if(isValid(discounted_fare)){
                                                        summaryDiscountedFare = summaryDiscountedFare + discounted_fare;
                                                    }
                                                    });
                                                                                                
                                                    return(
                                                        <Table.Summary fixed>
                                                        <Table.Summary.Row>
                                                        <Table.Summary.Cell ></Table.Summary.Cell>
                                                        <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                        <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                        <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                            {global.operatorCode != "DAFIDY"&&
                                                                <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                            }
                                                        <Table.Summary.Cell >{roundNumber(summaryTotalAmount)}</Table.Summary.Cell>
                                                        <Table.Summary.Cell >{roundNumber(summaryDiscountedFare)}</Table.Summary.Cell>
                                                        <Table.Summary.Cell >{roundNumber(summaryTotalComm)}</Table.Summary.Cell>
                                                            {global.operatorCode == "DAFIDY"&&
                                                                <Table.Summary.Cell >{roundNumber(netAmountDebited)}</Table.Summary.Cell>
                                                            }
                                                    <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                    <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                    <Table.Summary.Cell >{}</Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    </Table.Summary>
                                                    )
                                            }}
                                        />
                                        </Col>
                                    </Row>
                                </div>
                            :
                                null
                            }
                        </div>
            )
    }else{
        return null
    }
}

export const BookedTicketsReport = () => {


    const [cancelledTickets,setCancelledTickets]=useState([]);
    const [confirmedTickets,setConfirmedTickets]=useState([]);
    const [confirmedDiscountedTickets,setConfirmedDiscountedTickets]=useState([]);
    const [confirmedTicketSummary, setConfirmedTicketSummary] = useState({});
    const [cancelledTicketSummary, setCancelledTicketSummary] = useState({})

    const [showReport,setShowReport]=useState(false);

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        let {
            cancelledTransactions,
            confirmedTransactions
        }= data;
        setLoading(false);
        setShowReport(true);
        let {
            confirmData,discountData, confirmedSummaryData
        } = getConfirmData(confirmedTransactions);
        setConfirmedTickets(confirmData);
        setConfirmedDiscountedTickets(discountData);
        setCancelledTickets(cancelledTransactions);
        setConfirmedTicketSummary(confirmedSummaryData);
        setCancelledTicketSummary(getCancelledTicketSummary(cancelledTransactions, form.getFieldsValue().dateRange));
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange,
            isBooking
        }=values;
        setShowReport(false);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setLoading(true);
        fetchBookingSheet(fromDate,toDate,isBooking,onSuccess,onFailure);
    }

    const [form] = Form.useForm();
    
    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                                rules={[
                                    { required: true , message: 'Please input your date range!'},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                        },
                                    })
                                ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="isBooking" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                                <Radio.Group>
                                    <Radio value={'book'}>Booking Date</Radio>
                                    <Radio value={'travel'}>Travel Date</Radio>
                                    <Radio value={'cancel'}>Cancellation Date</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <Row gutter={16}>
                        <Col>
                            <Button onClick={()=>{
                                generateJsonToExport(form,confirmedTickets,cancelledTickets,confirmedDiscountedTickets);
                            }}>Export</Button>
                        </Col>
                        <Col>
                            <Printer 
                                showComponent={false}
                                renderComponent={()=>{
                                    return (
                                        <RenderReport 
                                            cancelledTickets={cancelledTickets}
                                            confirmedTickets={confirmedTickets}
                                            confirmedDiscountedTickets={confirmedDiscountedTickets}
                                            formValues={form.getFieldsValue()}
                                            confirmedTicketSummary = {confirmedTicketSummary}
                                            cancelledTicketSummary = {cancelledTicketSummary}
                                            pagination={false}
                                        />
                                    )
                                }} 
                                zoomValue={0.5}
                                />
                        </Col>
                    </Row>
                    :
                    null
                }
                <RenderReport 
                    cancelledTickets={cancelledTickets}
                    confirmedTickets={confirmedTickets}
                    confirmedDiscountedTickets={confirmedDiscountedTickets}
                    formValues={form.getFieldsValue()}
                    confirmedTicketSummary = {confirmedTicketSummary}
                    cancelledTicketSummary = {cancelledTicketSummary}
                    pagination={true}
                 />
            </div>
    )
}