
import { message } from "antd";
import { 
    GET_ADMIN_SCHEDULES_URL, 
    REMOVE_SCHEDULES_URL, 
    GET_SCHEDULE_TRIP_CITY_URL , 
    GET_SEAT_LAYOUT_BY_BUS_ID_URL ,
    GET_DISTANCE_BETWEEN_TWO_CITIES_URL,
    GET_LAT_LNG_OF_ADDRESS_URL,
    GET_SCHEDULES_BY_ID_FOR_DATES_URL,
    VALIDATE_TRIP_URL, ADD_AMENITIES_TO_SCHEDULE_URL,
    BLOCK_SCHEDULES_URL, FETCH_AMENITIES_URL,
    ACTIVATE_SCHEDULES_URL, GET_AMENITIES_FOR_SCHEDULE_URL, TRIP_ACTIVATION_MAIL_OTAS_URL,
    GET_TRIP_ROUTES_BY_SCHEDULE_ID_AND_DATE_URL, GET_DEMAND_DAYS_URL, GET_UNIQUE_ACTIVE_ROUTES_URL, UPDATE_ROUTE_PRIORITY_URL, RE_ACTIVATE_TRIP_ROUTE_BY_TRIP_ID,
    UPDATE_TRIP_CUT_ROUTE_URL, UPDATE_TRIP_BP_CHECK_URL, GET_BP_FROM_ADMIN_URL, GET_DP_FROM_ADMIN_URL, UPDATE_BOARDING_POINT_TIME_URL, UPDATE_DROP_POINT_TIME_URL,
    UPDATE_TRIP_DYNAMIC_PRICING_URL,
    UPDATE_PHONE_NUMBERS_OF_ADDRESS_BOOK_URL,
    GET_BUS_OPERATORS_URL,
    GET_TRAVELS_DETAILS_BY_OPERATOR_CODE_URL,
    UPDATE_TRAVEL_CONFIG_URL
} from "../utils/api-url";
import { getDate, getMonth, getYear } from "../utils/date.utils";
import { getCall, postCall } from "../utils/network.util";
import { isValid, sortJsonObject , isValidArray , isValidIndex } from "../utils/utilities";
import moment from 'moment';
import { GET_BUS_OPERATORS, GET_TRAVELS_DETAILS_BY_OPERATOR_CODE, UPDATE_TRAVEL_CONFIG } from "./action-types";

export const getActiveOperators=(onSuccess=()=>{},onFailure=()=>{})=>{
    return{
        type:GET_BUS_OPERATORS,
        payload: {
            url:GET_BUS_OPERATORS_URL,
            api:postCall,
            apiPayload:{
            },
            onSuccess,
            onFailure
        }
    }
}

export const getTravelsDetailsByOperatorCode=(operatorCode,onSuccess=()=>{},onFailure=()=>{})=>{
    return{
        type:GET_TRAVELS_DETAILS_BY_OPERATOR_CODE,
        payload: {
            url:GET_TRAVELS_DETAILS_BY_OPERATOR_CODE_URL,
            api:postCall,
            apiPayload:{
                operatorCode
            },
            onSuccess,
            onFailure
        }
    }
}

export const updateTravelConfig =(operatorCode,data,onSuccess=()=>{},onFailure=()=>{})=>{
    return {
        type:UPDATE_TRAVEL_CONFIG,
        payload:{
            url:UPDATE_TRAVEL_CONFIG_URL,
            api:postCall,
            apiPayload:{
                travelInfo:data,
                operatorCode:operatorCode
            },
            onSuccess,
            onFailure
        }
    }
}


export const updatePhoneNumbersOfAddressBook=(ids,phoneNumber,onSuccess=()=>{},onFailure=()=>{})=>{
    var updateAction = postCall(UPDATE_PHONE_NUMBERS_OF_ADDRESS_BOOK_URL, {
        ids:ids,
        phoneNumber:phoneNumber,
        operator_code:global.operatorCode,
        updatedBy:global.userName
      });
      updateAction.then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                onSuccess(clientPayload.Message);
            }else{
                onFailure(clientPayload.Message);
            }
        }
      })
      .catch((reason)=> {
        console.error({reason});
        onFailure("Failed to update address!");
      })
}

export const getViacitiesDetails=(scheduleId,selectedDate,setViaCitiesDetails,onFailure=()=>{},getDisabledRoutesAsWell=0)=>{
    let dateUrl='';
    if(isValid(selectedDate)){
        var month = getMonth(selectedDate)+1;
        var date = getDate(selectedDate);
        var year = getYear(selectedDate);
        dateUrl = "&date="+month+"/"+date+"/"+year
    }
    var url=GET_TRIP_ROUTES_BY_SCHEDULE_ID_AND_DATE_URL+"?scheduleId="+scheduleId+dateUrl+"&getDisabledRoutesAsWell="+getDisabledRoutesAsWell;
    getCall(url)
    .then((findresponce)=>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload!=false){
        setViaCitiesDetails(findresponce.payload.tripRoutes);
      }else{
        setViaCitiesDetails([]);
      }
    })  
    .catch((reason)=> {
        onFailure();
      })
  }

  export const getCities = (routes)=>{

    let cities=[] , citiesObject={};
    if(isValidArray(routes)){
        routes.forEach(element => {
            if(isValidIndex(citiesObject[element.origin])){
                if(element.trip_route_active){
                    cities[citiesObject[element.origin]].is_active=element.trip_route_active;
                }
                cities[citiesObject[element.origin]].isOrigin=true;
            }else{
                citiesObject[element.origin]=cities.length;
                cities.push({
                    city:element.origin,
                    time:moment(element.start_time,'HH:mm'),
                    timing: element.start_time,
                    isOrigin:true,
                    day:element.origin_day,
                    is_active:element.trip_route_active
                })
            }
            if(isValidIndex(citiesObject[element.destination])){
                if(element.trip_route_active){
                    cities[citiesObject[element.destination]].is_active=element.trip_route_active;
                }
                cities[citiesObject[element.destination]].isDestination=true;
            }else{
                citiesObject[element.destination]=cities.length;
                cities.push({
                    city:element.destination,
                    time:moment(element.end_time,'HH:mm'),
                    timing: element.end_time,
                    isDestination:true,
                    day:element.destination_day,
                    is_active:element.trip_route_active
                })
            }
        });
    }
    let sortByTime= sortJsonObject(cities,'time',true); 
    let finalSort= sortJsonObject(sortByTime,'day',true);
    return finalSort;
}

export const validateTripAction=(scheduleId,onSuccess,onFailure)=>{
    if(!isValid(scheduleId)){
        message.error("Please select trip!");
    }else{
        var cloneBus = postCall(VALIDATE_TRIP_URL, {
            scheduleId : scheduleId
          });
          cloneBus.then((data) => {
            if(data.status === "SUCCESS"){
                let clientPayload = data.payload;
                if(clientPayload.Status==="SUCCESS"){
                    onSuccess(clientPayload.payload.warningList);
                }else{
                   onFailure();
                }
            }
          })
          .catch((reason)=> {
            console.error({reason});
            onFailure('Failed to validate schedule!');
          })
    }
}

export const blockSchedules =(scheduleId,selectedDays,comment,confirmBlockingValue,onSuccess=()=>{},onFailure=()=>{})=>{
        var blockAction = postCall(BLOCK_SCHEDULES_URL, {
            scheduleId:scheduleId,
            dates:selectedDays,
            comment:comment,
            confirmBlocking:confirmBlockingValue,
            operator_code:global.operatorCode,
            updatedBy:global.userName
          });
          blockAction.then((data) => {
            if(data.status === "SUCCESS"){
                let clientPayload = data.payload;
                if(clientPayload.Status==="SUCCESS"){
                    onSuccess(clientPayload.Message);
                }else{
                    onFailure(clientPayload.Message);
                }
            }
          })
          .catch((reason)=> {
            console.error({reason});
            onFailure("Failed to block schedule!");
          })
}

export const activateSchedules =(scheduleId,visibleOutside,selectedDays,onSuccess=()=>{
    return true;
},onFailure=()=>{
    return false;
})=>{
    return postCall(ACTIVATE_SCHEDULES_URL, {
        scheduleId:scheduleId,
        visibleOutside:visibleOutside,
        dates:selectedDays,
        operatorCode: global.operatorCode,
        updatedBy:global.userName
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Message);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to activate schedule!");
        }
      })
      .catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to activate schedule!");
      })
}



export const getSchedulesByIdForDates = (scheduleId,startDate,endDate,onSuccess,onFailure) => {
    var fecthed = postCall(GET_SCHEDULES_BY_ID_FOR_DATES_URL,{
        operator_code:global.operatorCode,
        travelsId:global.travelsId,
        scheduleId: scheduleId,
        startDate: startDate,
        endDate: endDate
    });
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to get schedule status");
    })
}

export const getActiveSchedules = (month,year,onSuccess,onFailure) => {
    var updated = postCall(GET_ADMIN_SCHEDULES_URL,{
        operator_code:global.operatorCode,
        travelsId:global.travelsId,
        month: month,
        onlyTripsCreated: true,
        year: year
    });
    updated.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.payload);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to incomplete trips");
    })
}

export const getInActiveSchedules = (onSuccess,onFailure) => {
    var updated = postCall(GET_ADMIN_SCHEDULES_URL,{
        operator_code:global.operatorCode,
        travelsId:global.travelsId,
        onlyWithoutTrips:true
    });
    updated.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.payload);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to incomplete trips");
    })
}

export const getAllSchedules = (onSuccess,onFailure) => {
    var updated = postCall(GET_ADMIN_SCHEDULES_URL,{
        operator_code:global.operatorCode,
        travelsId:global.travelsId,
        onlyWithoutTrips:true
    });
    updated.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.payload);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to fetch trips");
    })
}
    
export const removeSchedules=(scheduleIds,onSuccess,onFailure)=>{
    var removed = postCall(REMOVE_SCHEDULES_URL,{
        operator_code:global.operatorCode,
        travelsId:global.travelsId,
        scheduleId:scheduleIds
    });
    removed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Message);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to remove incomplete trips");
    })
}

export const getScheduleTripCityAction = (scheduleId,onSuccess,onFailure) =>{
    var fetched = postCall(GET_SCHEDULE_TRIP_CITY_URL,{
        scheduleId: scheduleId
    });
    fetched.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.payload);
        }else{
            onFailure(data.payload.Message);
        }
    }).catch((err) => {
        console.log(err);
        onFailure("Failed to fetch trips city");
    })
}

export const getSeatLayOut = (busId,onSuccess,onFailure) =>{
    var fetched = getCall(GET_SEAT_LAYOUT_BY_BUS_ID_URL+"?busId="+busId);
    fetched.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            onFailure(data.payload.Message);
        }
    }).catch((err) => {
        onFailure("Failed to fetch seat layout failed!");
    })
}

export const getDistanceDataBetweenCities = (cityList,onSuccess=()=>{},onFailure=()=>{})=>{
    var fetched = postCall(GET_DISTANCE_BETWEEN_TWO_CITIES_URL,{
        cityList: cityList
    });
    fetched.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            let distance = data.payload.Payload.distance;
            let duration = data.payload.Payload.duration;
            onSuccess(distance,duration);
        }else{
            onFailure(data.payload.Message);
        }
    }).catch((err) => {
        console.log({err});
        onFailure("Failed to fetch duration");
    })
}

export const getLatLngofAddress=(address,onSuccess=()=>{},onFailure=()=>{})=>{
    var fetched = postCall(GET_LAT_LNG_OF_ADDRESS_URL,{
        address: address
    });
    fetched.then((data) => {
        if(data.status == 'SUCCESS' && data.payload.Status == 'SUCCESS'){
            let lat = data.payload.Payload.lat;
            let lng = data.payload.Payload.lng;
            onSuccess(lat,lng);
        }else{
            onFailure(data.payload.Message);
        }
    }).catch((err) => {
        onFailure("Failed to fetch lat , lng.",err);
    })
}


export const getDemandDaysAction = (originList, destinationList ,onSuccess,onFailure) => {
    var fecthed = postCall(GET_DEMAND_DAYS_URL,{
        operator_code:global.operatorCode,
        originList, destinationList
    });
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to get demand Days");
    })
}

export const fetchAmenitiesAction = (onSuccess) => {
    var fecthed = postCall(FETCH_AMENITIES_URL,{});
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload.amenities);
        }else{
            message.error(data.payload.Message);
        }
    })
    .catch((reason)=> {
        message.error("Failed to get amenities for schedule");
    })
}

export const getAmenitiesForScheduleAction = (busId, scheduleId, onSuccess) => {
    var fecthed = postCall(GET_AMENITIES_FOR_SCHEDULE_URL,{
        busId:busId,
        scheduleId, scheduleId
    });
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            message.error(data.payload.Message);
        }
    })
    .catch((reason)=> {
        message.error("Failed to get amenities for schedule");
    })
}

export const addAmenitiesToScheduleAction = (busId, scheduleId, amenities) => {
    var fecthed = postCall(ADD_AMENITIES_TO_SCHEDULE_URL,{
        busId:busId,
        scheduleId, scheduleId,
        amenities: amenities
    });
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            message.success(data.payload.Message);
        }else{
            message.error(data.payload.Message);
        }
    })
    .catch((reason)=> {
        message.error("Failed to get amenities for schedule");
    })
}

export const getUniqueActiveRoutesByOperatorCode = (onSuccess=()=>{},onFailure=()=>{}) => {
    var fecthed = postCall(GET_UNIQUE_ACTIVE_ROUTES_URL,{
    });
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            message.error(data.payload.Message);
            onFailure();
        }
    })
    .catch((reason)=> {
        message.error("Failed to get unique active routes");
    })
}

export const updateRoutePriority = (
    origin,
    destination,
    priority,
    onSuccess=()=>{},
    onFailure=()=>{}
    ) => {
    var updated = postCall(UPDATE_ROUTE_PRIORITY_URL,{
        origin,
        destination,
        priority
    });
    updated.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            message.error(data.payload.Message);
            onFailure();
        }
    })
    .catch((reason)=> {
        message.error("Failed to update route priority");
    })
}

export const reActivateTripRoutesByTripId=(tripId,cities,onSuccess,onFailure)=>{
    var updated = postCall(RE_ACTIVATE_TRIP_ROUTE_BY_TRIP_ID,{
        tripId,
        cities
    });
    updated.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload);
        }else{
            message.error(data.payload.Message);
            onFailure();
        }
    })
    .catch((reason)=> {
        message.error("Failed to update route priority");
    })
}


export const sendTripActivateEmailToOtas = (scheduleData,onSuccess,onFailure) => {
    var fecthed = postCall(TRIP_ACTIVATION_MAIL_OTAS_URL,{
        operator_code:global.operatorCode,
        travelsId:global.travelsId,
        scheduleData: scheduleData
    });
    fecthed.then((data) => {
        if(data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Message);
        }else{
            onFailure(data.payload.Message);
        }
    })
    .catch((reason)=> {
        onFailure("Failed to get schedule status");
    })
}


export const updateTripCutRouteByDate =(scheduleId,dates,checked,onSuccess,onFailure)=>{
    return postCall(UPDATE_TRIP_CUT_ROUTE_URL, {
        scheduleId:scheduleId,
        dates:dates,
        checked: checked,
        operatorCode: global.operatorCode,
        updatedBy:global.userName
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Message);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to update seat sharing!");
        }
      })
      .catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to update seat sharing!");
      })
}

export const updateTripBpCheckByDate =(scheduleId,dates,checked,onSuccess,onFailure)=>{
    return postCall(UPDATE_TRIP_BP_CHECK_URL, {
        scheduleId:scheduleId,
        dates:dates,
        checked: checked,
        operatorCode: global.operatorCode,
        updatedBy:global.userName
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Message);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to update avoid standing passenger!");
        }
      })
      .catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to update avoid standing passenger!");
      })
}

export const getBPfromAdmin=(scheduleId,tripId,onSuccess,onFailure)=>{
    return postCall(GET_BP_FROM_ADMIN_URL, {
        scheduleId:scheduleId,
        tripId:tripId,
        operatorCode: global.operatorCode,
        updatedBy:global.userName
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Payload);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to get pickup timings!");
        }
      })
      .catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to get pickup timings!");
      })
}

export const getDPfromAdmin=(scheduleId,tripId,onSuccess,onFailure)=>{
    return postCall(GET_DP_FROM_ADMIN_URL, {
        scheduleId:scheduleId,
        tripId:tripId,
        operatorCode: global.operatorCode,
        updatedBy:global.userName
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Payload);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to get drop timings!");
        }
      })
      .catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to get drop timings!");
      })
}

export const updateBoardingPointTimeById=(scheduleId,tripId,boaringPointId,timing,day,onSuccess,onFailure)=>{
    return postCall(UPDATE_BOARDING_POINT_TIME_URL,{
        scheduleId,tripId,boaringPointId,timing,day
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Payload);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to boarding timings!");
        }
      }).catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to boarding timings!");
      })
}

export const updateDropPointTimeById=(scheduleId,tripId,dropPointId,timing,day,onSuccess,onFailure)=>{
    return postCall(UPDATE_DROP_POINT_TIME_URL,{
        scheduleId,tripId,dropPointId,timing,day
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Payload);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to drop timings!");
        }
      }).catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to drop timings!");
      })
}

export const updateTripDynamicPricing=(scheduleId, travelDate, checked, onSuccess, onFailure) => {
    return postCall(UPDATE_TRIP_DYNAMIC_PRICING_URL,{scheduleId,travelDate,checked})
    .then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Message);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to update trip dynamic pricing!");
        }
      }).catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to update trip dynamic pricing!");
      })
}