import React, { useState } from "react";
import { Form , Button , Radio ,Row ,Col , DatePicker , Checkbox, message , Typography , Table} from 'antd';
import { getRangeOptions } from './reports.module';
import { fetchPassengerInfoSheet} from './../../actions/reports.action';
import { getAPIDateFormat , getLocalDateAndTime } from './../../utils/date.utils';
import { isValid, isValidArray } from "../../utils/utilities";
import { AgentsSelect } from './../../components/search-components/agents-select/agents-select.component';
import {  divideFloatingPoints as DFP, roundNumber} from './../../utils/math.utils';
import { Printer } from './../../components/custom-components/custom-component';
import { getJsonKeys } from './../../utils/utilities';
import { getPaginationDetails, getDefaultPageSize } from './reports.module';
import * as XLSX from 'xlsx';


const {Text} =Typography;

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;



const generateJsonToExport=(filters,passengerInfo)=>{

    let reportsXmlData=[];
    if(isValidArray(passengerInfo)&&passengerInfo.length>0){
        passengerInfo.forEach(element => {
            let avgFare = DFP(element.totalTicketCost,element.ticketCount);
            reportsXmlData.push([
                element.emailId,element.fullName,element.phoneNumber,element.ticketCount,
                element.formatedCoach,avgFare,element.formatedbookingSource,
                element.formatedOriginDestination
            ])
        });
    }

    let agentName=filters.isSelectAllAgents?'all':filters.agentName;
    let dateType =filters.isBookingDate?"Booking":"Travel";
    let generatedTime = getLocalDateAndTime(new Date());


        var worksheet = XLSX.utils.aoa_to_sheet([
            [], // A1
            [], // A2
            ["Report","Agents","From Date", "To Date","Type of date" , "Generated day/time"], // A3
            ["View passenger report",agentName,filters.fromDate,filters.toDate,dateType,generatedTime],
            [],
            [],
            [
                "Email","Passenger name", "Phone number" , "Total seats booked" , 
                "Bus types" , "Average fare" , "Booking source" , 
                "Origin-Destination"
            ],
            ...reportsXmlData
        ]);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'View Passenger Info');
        XLSX.writeFile(workbook, 'view-passenger-info.xlsx');
}

const validateAgent=(agentName,agentKeys)=>{
    let bool=false;

    for (let index = 0; index < agentKeys.length; index++) {
        const element = agentKeys[index];
        if(agentName===element){
            bool = true;
            break;
        }
    }

    return bool;
  
}

const getTicketsByAgentName=(tickets,agentName,isSelectAllAgents)=>{
    let formatedTickets=[], filterTripDescription = [], insertedDescription = [];
    if(isValidArray(tickets)){
        for (var key in tickets) {
            if (tickets.hasOwnProperty(key)) {
              var userTickets = tickets[key];
              let agentKeys=getJsonKeys(userTickets.agent);
              if(isSelectAllAgents||validateAgent(agentName,agentKeys)){
                let coach= "";
                let originDestination = "";
                let bookingSource = "";
                Object.keys(userTickets.originDestination).forEach((OD)=>{
                    originDestination+= OD + '('+userTickets.originDestination[OD] +')';
                })
                Object.keys(userTickets.coach).forEach((type)=>{
                    if (!insertedDescription.includes(type)) {
                        insertedDescription.push(type);
                        filterTripDescription.push({text: type,value: type});
                    }
                    coach+= type + '('+userTickets.coach[type] +')';
                })
                Object.keys(userTickets.agent).forEach((name)=>{
                    bookingSource+= name + '('+userTickets.agent[name] +')';
                })
                    formatedTickets.push({
                        formatedCoach:coach,
                        formatedbookingSource:bookingSource,
                        formatedOriginDestination:originDestination,
                        emailId:key,
                        ...userTickets  
                    });
                }
            }
          }
    }
    return {dataSource: formatedTickets, filterTripDescription};
}


const RenderReport=({fromDate,toDate,isBookingDate,agentName,dataSource,pagination,isSelectAllAgents, filterTripDescription})=>{

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const passengerTicketsInfoColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title:'Email',
            key:'Email',
            dataIndex : 'emailId'
        },
        {
            title:'Passenger name',
            key:'fullName',
            dataIndex:'fullName'
        },
        {
            title:'Phone number',
            key:'phoneNumber',
            dataIndex : 'phoneNumber',
            render : (text,record) => {
                if (isValidArray(text)) {
                    return text[0];
                }
                else {
                    return text;
                }
            }
        },
        {
            title:'Seat Numbers',
            key:'seatNumber',
            dataIndex : 'seatNumber'
        },
        {
            title:'Total Seats Booked',
            key:'ticketCount',
            dataIndex : 'ticketCount'
        },
        {
            title:'Trip Description',
            key:'formatedCoach',
            dataIndex : 'formatedCoach',
            filters: filterTripDescription,
            onFilter: (value, record) => record.formatedCoach.indexOf(value) === 0,
        },
        {
            title:'Average Fare',
            key:'Average fare',
            render : (text, record, index) => roundNumber(DFP(record.totalTicketCost,record.ticketCount))
    
        },
        {
            title:'Booking Source',
            key:'formatedbookingSource',
            dataIndex : 'formatedbookingSource'
        },
        {
            title:'Origin-Destination',
            key:'formatedOriginDestination',
            dataIndex : 'formatedOriginDestination'
        }
    ];
    

    return(
        <div>
            <Row justify="center" gutter={[16,16]}>
                <Col>
                    <Text strong>View passengers report</Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>From date : </Text>{fromDate}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>To date : </Text>{toDate}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Type of date : </Text>{isBookingDate?"Booking Date":"Travel Date"}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                            <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,0]}>
                <Col>
                    <Text strong>Agent : {isSelectAllAgents?"All agents":agentName}</Text>
                </Col>
            </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table  
                        className="bordered-table"
                        columns={passengerTicketsInfoColumns}
                        dataSource={dataSource}
                        pagination={getPaginationDetails(pagination,setPage, paginationSize, setPaginationSize)}
                    />
               </Col>
           </Row>
        </div>
    )
}

export const ViewPassengersInfoReport = () => {

    const [form] = Form.useForm();
    const [agentsForm] = Form.useForm();

    const [showReport,setShowReport]=useState(false);
    const [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
        isSelectAllAgents:true,
        agentNames:[]
    });
    const [passengerInfo,setPassengerInfo]=useState(undefined);

    const onSuccess=(data)=>{
        setPassengerInfo(data.passengerStats);
        filters.agentNames=getJsonKeys(data.agentStats);
        setFilters({...filters});
        setShowReport(true);
    }

    const onFailure=()=>{
        setPassengerInfo(undefined);
        setShowReport(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange,
            isBooking
        }=values;
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setShowReport(false);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        filters.isBookingDate=isBooking;
        setFilters({...filters});
        fetchPassengerInfoSheet(fromDate,toDate,isBooking,onSuccess,onFailure)
    }


    let {dataSource, filterTripDescription} = getTicketsByAgentName(passengerInfo,filters.agentName,filters.isSelectAllAgents);

    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}t>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                        <Form.Item name="isBooking" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                            <Radio.Group>
                                <Radio value={true}>Booking Date</Radio>
                                <Radio value={false}>Travel Date</Radio>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport&&
                <div>
                    <Form
                    form={agentsForm}
                    initialValues={{isSelectAllAgents:true}}
                    onFinish={(values)=>{
                        let {
                            agentName,
                            isSelectAllAgents
                        }=values;
                        if(isValid(agentName)||isSelectAllAgents){
                            filters.agentName=agentName;
                            filters.isSelectAllAgents=isSelectAllAgents;
                            setFilters({...filters});
                        }else{
                            message.error("Select agent!");
                        }
                    }}
                    >
                        <Row gutter={[16,16]}>
                            <Col>
                                <AgentsSelect 
                                    agents={filters.agentNames}
                                    isManditory={false}
                                    showLabel={true}
                                />
                            </Col>
                            <Col>
                                <Form.Item name="isSelectAllAgents"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button htmlType="submit">Filter</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row gutter={16}>
                        <Col>
                              <Button onClick={()=>{
                                   generateJsonToExport(filters,dataSource);
                                }}>Export</Button>
                        </Col>
                        <Col>        
                        <Printer 
                            showComponent={false}
                            zoomValue={0.8}
                            renderComponent={()=>{
                                return (
                                    <RenderReport 
                                        fromDate={filters.fromDate}
                                        toDate={filters.toDate}
                                        isBookingDate={filters.isBookingDate}
                                        agentName={filters.agentName}
                                        isSelectAllAgents={filters.isSelectAllAgents}
                                        dataSource={dataSource}
                                        pagination={false}
                                    />
                            )
                        }} />
                        </Col>
                    </Row>
                    <RenderReport 
                        fromDate={filters.fromDate}
                        toDate={filters.toDate}
                        isBookingDate={filters.isBookingDate}
                        agentName={filters.agentName}
                        isSelectAllAgents={filters.isSelectAllAgents}
                        dataSource={dataSource}
                        pagination={true}
                        filterTripDescription = {filterTripDescription}
                    />
                </div>
                }
            </div>
    )
}