import { Button,Modal ,Table } from "antd";
import React from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";
import { checkActionAllowed, isValidNumber,isValid, isValidArray} from "../../utils/utilities";
import './via-routes-seat-numbers.style.scss';
import { ACTION_VIEW_TRIP_REPORT_ALLOWED } from "../../utils/constant";
import RouteIcon from './../../images/routeicon.png';
import Icon from '@ant-design/icons';
import { fetchTripSheet } from "../../actions/reports.action";

const renderComponent=(tripSheetData)=>{
    
    return(
        <div>
            <Table 
                columns ={[
                    {
                        title: 'Route',
                        dataIndex: 'route',
                        key: 'route'
                    },
                    {
                        title: 'Seats',
                        dataIndex: 'seatsCount',
                        key: 'seatsCount',
                        render:(text,report)=>{
                            return(
                                <div>
                                    {report.seatNumbers} ({report.seatsCount})
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={tripSheetData}
                pagination={false}
            />
        </div>
    )
}

const formateDataForToolTip =(data)=>{
    let formatedInfo = [],rounteInfo={};
    let {
        tripSheet
    } = data;
    if(isValidArray(tripSheet)){
        tripSheet.forEach(element => {
            if(isValid(rounteInfo[element.trip_route_id])){
                formatedInfo[rounteInfo[element.trip_route_id].index].seatsCount = formatedInfo[rounteInfo[element.trip_route_id].index].seatsCount +1
                formatedInfo[rounteInfo[element.trip_route_id].index].seatNumbers = formatedInfo[rounteInfo[element.trip_route_id].index].seatNumbers + `, ${element.seat_number}`
            }else{
                rounteInfo[element.trip_route_id] = {index:formatedInfo.length}
                
                formatedInfo.push({
                    route:`${element.origin} to ${element.destination}`,
                    seatsCount:1,
                    seatNumbers:`${element.seat_number}`
                });
            }
        });
    }
    return formatedInfo;
    
}



export const ViaRoutesSeatNumbers = (props) => {

    const {
        tripId,
        tripSheetPermission
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tripSheetData, setTripSheetData] = useState(null);

    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }

    const onSuccess=(data)=>{
        setTripSheetData(formateDataForToolTip(data));
        setLoading(false);
        showModal(true);
    }

    

    const onFailure=()=>{
        setLoading(false);
    }

    if(checkActionAllowed(ACTION_VIEW_TRIP_REPORT_ALLOWED)&&(!isValidNumber(tripSheetPermission)||tripSheetPermission === 1)){

        return (
            <div>
                <Button 
                    onClick={async()=>{
                        setLoading(true);
                        if(isValid(tripId)){
                           await fetchTripSheet(tripId,onSuccess,onFailure);
                        }else{
                            setLoading(false);
                        }
                    }} 
                    ghost 
                    className="open-select-seat-btn viaroutes-btn">
                    <Icon component={() => (<img src={RouteIcon} style={{width:'23px',padding:'1px'}} />)} />
                </Button>
                <Modal 
                    centered={true}
                    visible={isModalVisible} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    okText="Update"
                    footer={null}
                    >
                        {renderComponent(tripSheetData)}
                    </Modal>
            </div>
          );
    }else{
        return null;
    }
};
