import React, {useState,useEffect} from 'react';
import {SeatLayout} from "./seat-layout.component";
import {getSeatGrid} from "./seat-layout-container.module";
import {SeatLegend} from "./seat-legend.component";
import './seats.style.scss';
import { updateSelectedSeats } from './../../actions/passenger-form.action';
import { useDispatch , useSelector } from 'react-redux';
import { selectedSeatsSelector } from './../../selector/passenger-form.selector';
import { getJsonKeys, isValid } from '../../utils/utilities';
import { message } from 'antd';
import { GET_TRAVELLER_INFO_TICKETID_AND_SEAT_NUMBER_URL} from './../../utils/api-url';
import{blockedSeatTicketIdSelector} from './../../selector/passenger-form.selector';

const addBlockedSeat = (ticketId,seatNumber,onSuccess=()=>{

})=>{
    fetch(GET_TRAVELLER_INFO_TICKETID_AND_SEAT_NUMBER_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "ticketId" : ticketId,
            "seatNumber" : seatNumber
        })
        })
        .then(response => response.json())
        .then(data =>{
            onSuccess(data);
        }).catch((err)=>{
            console.log({err});
            message.error("Failed to get seater info");
        })
} 

export const SeatLayoutContainer = props => {

    const{
        seatsData,
        noOfSeatsAllowedToClick,
        blockedSeatAllowed,
        onSeatClickSuccess,
        showLegend=true,
        showFarePopOver,
        changeOldLayout = false,
    } = {...props};


    const dispatch=useDispatch();
    const selectedSeats=useSelector(selectedSeatsSelector);
    const blockedSeatTicketId=useSelector(blockedSeatTicketIdSelector);

    const [showSeatLayout, updateShowSeatLayout] = useState(false);
    const [seatGrid, updateSeatGrid] = useState([[]]);

    useEffect(() => {
        document.getElementById('seat-layout').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    },[]);

    const onDispatchSeatClick=(object,passengerInfo)=>{
        let selectedSeatCount = getJsonKeys(selectedSeats).length;
        let alreadySelectedSeat = false;
        if(selectedSeatCount>0){
            alreadySelectedSeat = isValid(selectedSeats[object.id])?true:false;
        }
        if(!isValid(noOfSeatsAllowedToClick)||noOfSeatsAllowedToClick>selectedSeatCount||alreadySelectedSeat){
            dispatch(updateSelectedSeats(object.id,object,passengerInfo));
            if(isValid(onSeatClickSuccess)){
                onSeatClickSuccess(object);
            }
        }else{
            message.error(`Maximum seats to select is : ${noOfSeatsAllowedToClick}`);
        }
    }

    const onSeatCLick = (object) => {
        if(noOfSeatsAllowedToClick!=0){
            if(isValid(object.ticket_id)&&object.seat_status==="Blocked"){
                if(blockedSeatAllowed){
                    if(!isValid(blockedSeatTicketId)||(blockedSeatTicketId===object.ticket_id)){
                        addBlockedSeat(object.ticket_id,object.seat_number,(passengerInfo)=>{
                            onDispatchSeatClick(object,passengerInfo)
                        });
                    }else{
                        message.error("Ticket blocked separately not allowed to book together");
                    }
                }else{
                    message.error("Blocked for some other customer");
                }
            }else{
                onDispatchSeatClick(object,{});
            }
        }
        return true;
    };

    useEffect(() => {
        if(seatsData !== undefined && seatsData.length > 0) {
            const {
                seatGridLayout
            } = getSeatGrid(seatsData,onSeatCLick,selectedSeats,showFarePopOver,false, changeOldLayout);
            const seatGridSeats = seatGridLayout.lowerSeatGrid;
            if(seatGridSeats.length > 1) {
                updateSeatGrid(seatGridLayout);
                updateShowSeatLayout(true);
            }
        }
        else {
            updateSeatGrid([[]]);
            updateShowSeatLayout(false);
        }
    },[seatsData,selectedSeats,noOfSeatsAllowedToClick]);


    return (
        <div id="seat-layout">
            {showSeatLayout &&
                <div className="seat-layout-parent-container">
                        <SeatLayout seatGridLayout={seatGrid} changeOldLayout = {changeOldLayout}/>
                </div>
            }
            {showLegend?
                <SeatLegend/>
                :null
            }
        </div>
    );
};

SeatLayoutContainer.defaultProps = {

};

SeatLayoutContainer.propTypes = {

};
