import { takeLatest, put , all ,fork,call } from 'redux-saga/effects';
import {
    GET_BUS_OPERATORS,
    GET_TRAVELS_DETAILS_BY_OPERATOR_CODE,
    LOADING_ACTION,
    SET_BUS_OPERATORS,
    SET_SELECTED_OPERATOR,
    UPDATE_TRAVEL_CONFIG
} from '../actions/action-types';
import { message } from 'antd';
import { isValidArray } from '../utils/utilities';

export function* getBusOperatorSaga(action) {
    try {
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
    const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
    if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){
        yield put({
            type: SET_BUS_OPERATORS,
            payload: apiResult.payload.Payload
        });
        onSuccess();
    }else{
        onFailure("Failed to get operator details"); 
    }
    yield put({
        type: LOADING_ACTION,
        payload: false
    });
}
    catch (error) {
        console.log("fetching cities:",{error});
        message.error("Failed to get operator details")
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
};

function* watchBusOperatorSaga() {
    yield takeLatest(GET_BUS_OPERATORS, getBusOperatorSaga)
}


export function* getTravelsDetailsByOperatorCodeSaga(action) {
    try {
        yield put({
            type: LOADING_ACTION,
            payload: true
        });
    const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
    
    if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){
        yield put({
            type:SET_SELECTED_OPERATOR,
            payload: apiResult.payload.Payload
        });
        onSuccess();
    }else{
        onFailure("Failed to get operator details"); 
    }
    yield put({
        type: LOADING_ACTION,
        payload: false
    });
}
    catch (error) {
        console.log("fetching cities:",{error});
        message.error("Failed to get operator details");
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
};

function* watchGetTravelsDetailsByOperatorCodeSaga() {
    yield takeLatest(GET_TRAVELS_DETAILS_BY_OPERATOR_CODE, getTravelsDetailsByOperatorCodeSaga)
}

export function* updateTravelConfig(action) {
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        });

        const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){
            onSuccess();
        }else{
            onFailure("Failed to get operator details"); 
        }
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
    catch(error){
        yield put({
            type: LOADING_ACTION,
            payload: false
        });
    }
}

function* watchUpdateTravelConfigSaga() {
    yield takeLatest(UPDATE_TRAVEL_CONFIG, updateTravelConfig)
}

export default function* watchAdminPageSaga() {
    yield all([
        fork(watchBusOperatorSaga),
        fork(watchGetTravelsDetailsByOperatorCodeSaga),
        fork(watchUpdateTravelConfigSaga)
    ])
}

