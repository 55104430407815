/**
 * @author Rejo Varghese
 * @description Ticket search sub category expansion of rows report summary
 */

import React, { useEffect, useState } from "react";
import './ticket-search.style.scss';
import { Divider, Table, Typography, Timeline, Collapse, Row, Col, message, Tag, Button } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';
import { isValid, isValidArray, isValidArrayWithValues, checkActionAllowed, deleteIndexValue, isValidString } from "../../utils/utilities";
import { getTimeStampInReadableFormat, getLocalDateAndTime, getSecsBetweenTwoTimes, getTimeFormat, getUIDateFormat, getDateTimeFormat } from "../../utils/date.utils";
import { fetchTicketDetailsByPNR, cancelTicket, getTicketById, getRefundARNByOrderId } from "../../actions/ticket.action";
import { roundNumber } from "../../utils/math.utils";
import { getSelectedTravellerInfo, CancelAlertBody, getFormatedTravells, validateTraveller } from "./ticket-search-module";
import { CustomModal } from "../custom-components/custom-component";
import { ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED, ACTION_CANCEL_BKBS_TICKET_ALLOWED, getSameUser, PRIVILEGE_HEAD_OFFICE, QB_USER, ACTION_CANCEL_TICKET_ALLOWED } from '../../utils/constant';
import { NotifyButton, NotifyComponent } from "../ticket-summary-components/notify.component";
const { Text } = Typography;
const { Panel } = Collapse;

export const TicketSearchRowExpand = (props) => {
    const {
        TicketData,
        pnrFetchedData,
        setPnrFetchedData,
        selectedPNR,
        assignStatusColor,
        setLoading
    } = props;

    const [ticketLogsData, setTicketLogsData] = useState(null);
    const [travellerData, setTravellerData] = useState(null)
    const [ selectedTravellers,setSelectedTravellers] = useState(null);
    const [ checkedRowKeys, setCheckedRowKeys] = useState(null);
    const [ cancelDetails,setCancelDetails] = useState({});
    const [ cancellationPenality,setCancellationPenality] = useState(0);
    const [ sendNotification,setSendNotification] = useState(true);
    const [ selectedNotifyTickets, setSelectedNotifyTickets] = useState([]);
    const [returnTicketId, setReturnTicketId] = useState(null);
    const [returnTicketData, setReturnTicketData] = useState([]);

    let commonData = TicketData.travellerData[0];

    const populateTravellerData = (fetchedData) => {
        let travellers = fetchedData['traveller'];
        if(isValidArray(travellers)){
            travellers.map((traveller) => {
                if(isValidArray(fetchedData['pickupDetails'])){
                    traveller.start_time = fetchedData['pickupDetails']['timing'];
                    traveller.location = fetchedData['pickupDetails']['location'];
                }
                if(isValidArray(fetchedData['dropDetails'])){
                    traveller.drop_time = fetchedData['dropDetails']['timing'];
                    traveller.drop_location = fetchedData['dropDetails']['location'];
                }
            })
            setTravellerData(travellers);
        }
    }
    const fetchTicketData = (pnr) => {
        if (isValid(pnrFetchedData[pnr])) {
            let tmpData = pnrFetchedData;
            tmpData[pnr] = null;
            setPnrFetchedData(tmpData);
        }
        fetchTicketDetailsByPNR(pnr, (tktLogs) => {            
            let formattedTicketLogs = {};
            formattedTicketLogs["travellerLogs"] = tktLogs["logs"]["travellerLogs"];
            let tmpData = pnrFetchedData;
            tmpData[pnr] = tktLogs
            tmpData[pnr]['pnrLogs'] = formattedTicketLogs;
            populateTravellerData(tktLogs);
            fetchTimelineLogs(formattedTicketLogs)
            validateReturnTicket(tktLogs.transactionDetails, tktLogs.ticket.id)
            setPnrFetchedData(tmpData);
            setLoading(false);
        })
    }

    function validateReturnTicket(transactionDetails, selectedTicketId) {
        if (isValid(transactionDetails.order_reference_no)) {
            let refList = transactionDetails.order_reference_no.split('-');
            let ticketId = refList[1], returnTktId = refList[2];
            if (isValidString(returnTktId)) {
                if (ticketId == selectedTicketId) {
                    setReturnTicketId(returnTktId);
                }
                else {
                    setReturnTicketId(ticketId);
                }
            }
        }
    }

    const fetchReturnTicketById = (ticketId) => {
        setLoading(true);
        getTicketById(ticketId, (data) => {
            setLoading(false);
            let seatNums = [], passName;
            data.traveller.map((traveller, ind) => {
                seatNums.push(traveller.seat_number);
                if (ind == 0) {
                    passName = traveller.full_name
                }
            })
            let ticket = data.ticket;
            let returnData = {
                "key": 1,
                "pnr": ticket.pnr,
                "doj": getUIDateFormat(data.trip.start_date),
                "origin": data.route.origin,
                "destination": data.route.destination,
                "full_name": passName,
                "phone_number": ticket.phone_number,
                "seat": seatNums.toString(),
                "agent_name": data.agent.name,
                "booking_time": getDateTimeFormat(ticket.booking_time),
                "ticket_status": ticket.ticket_status,
                "amount_paid": ticket.amount_paid,
            }
            setReturnTicketData([returnData]);
        }, ()=> {setLoading(false)})
    }

    useEffect(() => {
        if (isValid(pnrFetchedData[selectedPNR])) {
            populateTravellerData(pnrFetchedData[selectedPNR]);
            fetchTimelineLogs(pnrFetchedData[selectedPNR]['pnrLogs']);
            validateReturnTicket(pnrFetchedData[selectedPNR]['transactionDetails'], pnrFetchedData[selectedPNR]['ticket'].id);
        }
        else {
            setLoading(true);
            fetchTicketData(selectedPNR);
        }
    },[selectedPNR]);

    useEffect(()=>{
        let {
            totalTicketCost,
            totalCancellationPenalty,
            totalRefundAmount,
            totalCancellationPenaltyPercentage,
            totalFare,
            totalAmountPaid
        } = getSelectedTravellerInfo(selectedTravellers);
        if(totalCancellationPenaltyPercentage<0){
            setCancellationPenality(100);
        }else{
            setCancellationPenality(totalCancellationPenaltyPercentage);
        }
        setCancelDetails({
            totalTicketCost,
            totalCancellationPenalty,
            totalRefundAmount,
            totalFare,
            totalAmountPaid,
            defalutPenalityPercentage:totalCancellationPenaltyPercentage
        })
      },[selectedTravellers]);

    const timeLineStatus = (status) => {
        if (status == "Auto-Refund") {
            return (
                <tr>
                    <td><span style= {{fontWeight: 'bold'}}>{status}</span></td>
                    <td><Tag color={"#2BB193"} key = {'1'}>Success</Tag></td>
                </tr>
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="2"><span style= {{fontWeight: 'bold'}}>{status}</span></td>
                </tr>
            )
        }
    }

    const insertTicketChangeData = (validatedValueList) => {
        let returnVal = [];
        validatedValueList.map((validatedValue) => {
            returnVal.push(<tr>
                            <td style= {{width: '50%'}}>{validatedValue.subHeader}</td>
                            <td style= {{width: '50%'}}>{validatedValue.subValue}</td>
                        </tr>)
        })
        return returnVal;
    }

    const fetchRefundArn = () => {
        getRefundARNByOrderId(pnrFetchedData[selectedPNR].transactionDetails.order_reference_no, (data) => {
            let tmpData = pnrFetchedData;
            let refundARNObject = data.Payload;
            let refundARNs = Object.entries(refundARNObject).values();
            if (refundARNs.length==1) {
                refundARNObject['common'] = refundARNs[0];
            }
            tmpData[selectedPNR]['pnrLogs']['refundARNs'] = refundARNObject;
            setPnrFetchedData(tmpData)
            fetchTimelineLogs(tmpData[selectedPNR]['pnrLogs'])
        })
    }

    const insertTimeLineData = (timeLineData) => {

        return (
            <Timeline.Item label={getLocalDateAndTime(timeLineData['operation_time'])}>
                <table style= {{width: '55%', maxWidth: 400}}>
                    {timeLineStatus(timeLineData.status)}
                    {isValidArrayWithValues(timeLineData.seats)&&
                    <tr>
                        <td style= {{width: '50%'}}>Seats</td>
                        <td style= {{width: '50%'}}>{timeLineData.seats.toString()}</td>
                    </tr>
                    }
                    {isValid(pnrFetchedData[selectedPNR])&&isValid(pnrFetchedData[selectedPNR].transactionDetails.order_no)&&timeLineData.status=="Confirmed"&&<tr>
                        <td style= {{width: '50%'}}>Transaction Id</td>
                        <td style= {{width: '50%'}}>{pnrFetchedData[selectedPNR].transactionDetails.order_no}</td>
                        </tr>
                    }
                    {isValid(timeLineData.cancelled_by)&&<tr>
                        <td style= {{width: '50%'}}>Cancel Agent</td>
                        <td style= {{width: '50%'}}>{timeLineData.cancelled_by}</td>
                        </tr>
                    }
                    {isValid(timeLineData.refund_id)?<tr>
                        <td style= {{width: '50%'}}>Refund ARN</td>
                        <td style= {{width: '50%'}}>{timeLineData.refund_id}</td>
                        </tr>:isValid(timeLineData.refund_traveller_id)&&(TicketData.agent_name).toLowerCase()=="website"&&<tr>
                        <td colSpan= {2} style= {{width: '100%'}}><Button onClick= {()=> {fetchRefundArn()}}>Get Refund ARN</Button></td>
                        </tr>
                    }
                    {isValidArrayWithValues(timeLineData.validatedValue)&&insertTicketChangeData(timeLineData.validatedValue)}
                </table>
            </Timeline.Item>
        );
    }

    const initialTimelineObj = (status, operation_time) => {
        return {
            'seats': [],
            'status': status,
            'operation_time': operation_time,
            'sort_time': new Date(operation_time),
            'ip_address': null,
            'cancelled_by': null,
            'refund_id': null,
            'refund_traveller_id': null,
            'validatedValue': []
        }
    }

    const validateAndInsertTimeline = (timelineData, operationTime, travLog, validatedValue) => {
        let seatNumStatus = ["Blocked", "Cancelled"];
        if (isValidArrayWithValues(timelineData[operationTime]['seats']) || seatNumStatus.includes(travLog.traveller_status)) {
            timelineData[operationTime]['seats'].push(travLog.seat_number)
        }
        if (travLog.traveller_status == "Cancelled") {
            timelineData[operationTime]['cancelled_by'] = travLog.cancelled_by
        }
        if (isValidArray(validatedValue)) {
            timelineData[operationTime]['validatedValue'].push(validatedValue);
        }
    }

    const fetchTimelineLogs = (logData) => {
        let travellerLogs = logData.travellerLogs;
        let fetchedRefundARN = logData.refundARNs;
        let operationTime, insertedTimes = [], timelineData = {}, travellerObj = {}, tempRefundARN = {};
        travellerLogs.map((travLog, travInd) => {
            if (travInd == 0) {
                operationTime = travLog.operation_time;
                insertedTimes.push(operationTime);
                travellerObj[travLog.traveller_id] = travLog;
                timelineData[operationTime] = initialTimelineObj(travLog.traveller_status, operationTime);
                timelineData[operationTime]['seats'].push(travLog.seat_number)
            }
            else {
                let timeDiff = getSecsBetweenTwoTimes(getTimeFormat(travLog.operation_time), getTimeFormat(operationTime));
                if (timeDiff>=-2 && timeDiff<3) {
                    let newOperationTime = new Date(new Date(operationTime).getTime() + 1*60000);
                    if (isValid(timelineData[operationTime])||isValid(timelineData[newOperationTime])) {
                        if (travLog.traveller_status == "Cancelled"&&travLog.automatic_refund) {
                            if (!isValid(timelineData[newOperationTime])) {
                                insertedTimes.push(newOperationTime);
                                travellerObj[travLog.traveller_id] = travLog;
                                timelineData[newOperationTime] = initialTimelineObj("Auto-Refund", operationTime);
                                timelineData[newOperationTime]['refund_id'] = travLog.refund_id;
                                timelineData[newOperationTime]['refund_traveller_id'] = travLog.traveller_id;
                            }
                        }
                        else {
                            let validatedValue = validateTraveller(travellerObj[travLog.traveller_id],travLog);
                            let prevStatus = timelineData[insertedTimes.at(-1)].status;
                            if (prevStatus == "Traveller Update") {
                                prevStatus = travLog.traveller_status;
                            }
                            if (isValid(timelineData[newOperationTime])) {
                                validateAndInsertTimeline(timelineData, newOperationTime, travLog, validatedValue['validStatus']?validatedValue:null);
                            }
                            else if (prevStatus!=travLog.traveller_status && !isValid(timelineData[newOperationTime])) {
                                insertedTimes.push(newOperationTime);
                                timelineData[newOperationTime] = initialTimelineObj(travLog.traveller_status, operationTime);
                                timelineData[newOperationTime]['seats'].push(travLog.seat_number);
                            }
                            else {
                                validateAndInsertTimeline(timelineData, operationTime, travLog, validatedValue['validStatus']?validatedValue:null);
                            }
                            travellerObj[travLog.traveller_id] = travLog;
                        }
                    }
                    else {
                        let validatedValue = validateTraveller(travellerObj[travLog.traveller_id],travLog);
                        if (validatedValue['isTravStatus']||validatedValue['validStatus']) {
                            insertedTimes.push(operationTime);
                            travellerObj[travLog.traveller_id] = travLog;
                            timelineData[operationTime] = initialTimelineObj(validatedValue['statusLabel'], operationTime);
                            validateAndInsertTimeline(timelineData, operationTime, travLog, validatedValue['isTravStatus']?null:validatedValue)
                        }
                    }
                }
                else {
                    operationTime = travLog.operation_time;
                    let validatedValue = validateTraveller(travellerObj[travLog.traveller_id],travLog);
                    if (validatedValue['isTravStatus']||validatedValue['validStatus']) {
                        insertedTimes.push(operationTime);
                        timelineData[operationTime] = initialTimelineObj(validatedValue['statusLabel'], operationTime);
                        validateAndInsertTimeline(timelineData, operationTime, travLog, validatedValue['isTravStatus']?null:validatedValue)
                    }
                }
            }
            if (!isValid(fetchedRefundARN)&&isValid(travLog.refund_id)) {
                tempRefundARN[travLog.traveller_id] = travLog.refund_id;
                tempRefundARN['common'] = travLog.refund_id;
            }
        })
        if (isValid(fetchedRefundARN)) {
            tempRefundARN = fetchedRefundARN;
        }
        let formattedTimeline = [];
        insertedTimes.map((time) => {
            let insertedTimeLineData = timelineData[time];
            if (insertedTimeLineData['status'] == "Auto-Refund" && (isValid(tempRefundARN[insertedTimeLineData.refund_traveller_id])||isValid(tempRefundARN['common']))) {
                let refundId = isValid(tempRefundARN[insertedTimeLineData.refund_traveller_id])?tempRefundARN[insertedTimeLineData.refund_traveller_id]:isValid(tempRefundARN['common'])?tempRefundARN['common']:null;
                insertedTimeLineData['refund_id'] = refundId;
            }
            formattedTimeline.push(insertTimeLineData(insertedTimeLineData))
        })
        setTicketLogsData(formattedTimeline);
    }

    const pannelHeader = (text) => {
        return (
            <h2>
                <span style = {{fontWeight: 'bold'}}>
                    {text}
                </span>
            </h2>
        )
    }

    const passengerRowSelection = {
        selectedRowKeys:checkedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedTravellers(selectedRows);
            setCheckedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.traveller_status !== 'Confirmed', // Column configuration not to be checked
          })
      };
    const onSuccess=()=>{
        setCheckedRowKeys(null);
        setSelectedTravellers(null);
        fetchTicketData(selectedPNR);
    }

    const passengerColumns = [
        {
            title:'Seat Number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Name',
            key:'full_name',
            dataIndex : 'full_name'
        },
        {
            title:'Age',
            key:'age',
            dataIndex : 'age'
        },
        {
            title:'Gender',
            key:'gender',
            dataIndex : 'gender'
        },
        {
            title:'Boarding',
            key:'location',
            dataIndex : 'location',
            render : (text, record, index) => {
                if(isValid(text)){
                    return text+", "+getTimeStampInReadableFormat(record.start_time)
                }else{
                    return ''
                }
            }
        },
        {
            title:'Drop',
            key:'drop_location',
            dataIndex : 'drop_location',
            render : (text, record, index) => {
                if(isValid(text)){
                    return text+", "+getTimeStampInReadableFormat(record.drop_time)
                }else{
                    return ''
                }
            }
        },
        {
            title:'Traveller status',
            key:'traveller_status',
            dataIndex : 'traveller_status',
            render :(text, record) => {
                return assignStatusColor({'ticket_status': text, "isPartial": false}, false)
            }
        }
    ];


    const paymentColumns = [
        {
            title:'Seat Number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Name',
            key:'full_name',
            dataIndex : 'full_name'
        },
        {
            title:'Fare',
            key:'discounted_fare',
            dataIndex : 'discounted_fare',
            render: (text,record, index) => {
                if(isValid(record.cancellation_time)) {
                    return <Text style={{textDecoration: "line-through"}}>{text}</Text>
                }
                else {
                    return <Text>{text}</Text>
                }
            }
        },
        {
            title:'GST',
            key:'total_gst',
            dataIndex : 'total_gst',
            render: (text,record, index) => {
                if(isValid(record.cancellation_time)) {
                    return <Text style={{textDecoration: "line-through"}}>{text}</Text>
                }
                else {
                    return <Text>{text}</Text>
                }
            }
        },
        {
            title:'Total',
            key:'amount_paid',
            dataIndex : 'amount_paid',
            render: (text,record, index) => {
                if(isValid(record.cancellation_time)) {
                    return <Text style={{textDecoration: "line-through"}}>{text}</Text>
                }
                else {
                    return <Text>{text}</Text>
                }
            }
        },
        {
            title:'Penalty',
            key:'cancellation_penalty',
            dataIndex : 'cancellation_penalty',
            render: (text,record) => {
                if (isValid(record.cancellation_time)) {
                    return <Text>{record.cancellation_penalty}</Text>
                }else {
                    return <Text></Text>
                }
            }
        },
        {
            title:'Refund',
            key:'refund',
            dataIndex : 'refund',
            render : (text, record, index) => {
                if (isValid(record.cancellation_time)) {
                    return roundNumber(record.amount_paid - record.cancellation_penalty)
                }
                else {
                    return null
                }
            }
        },
        {
            title:'Commission',
            key:'net_commission',
            dataIndex : 'net_commission',
            render : (text, record, index) => {
                if (!isValid(record.cancellation_time)) {
                    return roundNumber(record.net_commission)
                }
                else {
                    return null
                }
            }
        },
        {
            title:'Net Amount',
            key:'net_amount',
            dataIndex : 'net_amount',
            render : (text, record, index) => {
                if (isValid(record.cancellation_time)) {
                    return record.cancellation_penalty
                }
                else {
                    if (isValid(record.net_commission)) {
                        return  roundNumber(record.amount_paid - record.net_commission)
                    }
                    else {
                        return record.amount_paid
                    }
                }
            }
        }
    ];

    const returnColumns=[
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'DOJ',
            key:'doj',
            dataIndex : 'doj'
        },
        {
            title:'Origin',
            key:'origin',
            dataIndex : 'origin'
        },
        {
            title:'Destination',
            key:'destination',
            dataIndex : 'destination'
        },
        {
            title:'Passenger name',
            key:'full_name',
            dataIndex : 'full_name'
        },
        {
            title:'Mobile number',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Seats',
            key:'seat',
            dataIndex : 'seat'
        },
        {
            title:'Agent',
            key:'agent_name',
            dataIndex : 'agent_name'
        },
        {
            title:'Booking Date & Time',
            key:'booking_time',
            dataIndex : 'booking_time'
        },
        {
            title:'Status',
            key:'ticket_status',
            dataIndex : 'ticket_status'
        },
        {
            title:'Amount Paid',
            key:'amount_paid',
            dataIndex : 'amount_paid'
        }
    ];
    const getCancelAllowed=(traveller,selectedTravellers)=>{
        if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
            if(global.privilege === PRIVILEGE_HEAD_OFFICE || global.privilege === QB_USER){
                return true;
            }else{
                let isCancelTicketAllowed = false;
                if(isValidArray(traveller)&&traveller.length>0){
                    traveller.forEach((element)=>{
                        if(element.cancellation_penalty>-1){
                            isCancelTicketAllowed = true;
                        }
                    });
                }
                return isCancelTicketAllowed;
            }
        }else{
            return false
        }
    }

    

    let isCancelTicketAllowed=isValid(global.allowedActions[ACTION_CANCEL_TICKET_ALLOWED])||(isValid(global.allowedActions[ACTION_CANCEL_BKBS_TICKET_ALLOWED]) && getSameUser(pnrFetchedData[selectedPNR]['agent'].id))?true:false;
    let cancelledAllowed = getCancelAllowed(travellerData,selectedTravellers);

    return (
        <div>
            <h2>
                <span style = {{fontWeight: 'bold'}}>
                    Booking Details
                </span>
            </h2>
            <div>
                <table style= {{width: '75%'}}>
                    <tr>
                        <td>PNR</td>
                        <td>{TicketData.pnr}</td>
                        <td>Name</td>
                        <td>{TicketData.full_name}</td>
                    </tr>
                    <tr>
                        <td>DOJ</td>
                        <td>{TicketData.doj}</td>
                        <td>Phone</td>
                        <td>{TicketData.phone_number}</td>
                    </tr>
                    <tr>
                        <td>Booking Date/Time</td>
                        <td>{TicketData.booking_time}</td>
                        <td>Email</td>
                        <td>{commonData.email_id}</td>
                    </tr>
                    <tr>
                        <td>Agent</td>
                        <td>{TicketData.agent_name}</td>
                        <td>Transaction Details</td>
                        <td>{isValid(pnrFetchedData[selectedPNR])&&isValid(pnrFetchedData[selectedPNR].transactionDetails.order_no)?pnrFetchedData[selectedPNR].transactionDetails.order_no:''}</td>
                    </tr>
                    <tr>
                        <td>Origin/Destination</td>
                        <td>{TicketData.origin} <ArrowRightOutlined /> {TicketData.destination}</td>
                        <td>Amount Paid</td>
                        <td>₹ {isValid(pnrFetchedData[selectedPNR])&&isValid(pnrFetchedData[selectedPNR].ticket)?pnrFetchedData[selectedPNR].ticket.amount_paid:''}</td>
                    </tr>
                    <tr>
                        <td>Driver Name</td>
                        <td>{isValid(pnrFetchedData[selectedPNR])&&isValid(pnrFetchedData[selectedPNR].trip.driver_name)?pnrFetchedData[selectedPNR].trip.driver_name:''}</td>
                        <td>Driver Phone</td>
                        <td>{isValid(pnrFetchedData[selectedPNR])&&isValid(pnrFetchedData[selectedPNR].trip.phone_no)?pnrFetchedData[selectedPNR].trip.phone_no:''}</td>
                    </tr>
                    <tr>
                        <td>Bus</td>
                        <td colSpan="3">{commonData.description}</td>
                    </tr>
                    {isValid(TicketData.ticket_status)&&TicketData.ticket_status!= "Cancelled"&&<tr>
                        <td colSpan="4">
                            <NotifyButton
                                onClick={()=>{
                                    let indexTicket = selectedNotifyTickets.indexOf(pnrFetchedData[selectedPNR].ticket.id);
                                    if(selectedNotifyTickets.indexOf(pnrFetchedData[selectedPNR].ticket.id) == -1){
                                        selectedNotifyTickets.push(pnrFetchedData[selectedPNR].ticket.id);
                                        setSelectedNotifyTickets([...selectedNotifyTickets]);
                                    }else{
                                        setSelectedNotifyTickets([...deleteIndexValue(selectedNotifyTickets,indexTicket)]);
                                    }
                                }}
                                color="#08216A"
                            />
                            <NotifyComponent ticketInfo={pnrFetchedData[selectedPNR]} selectedNotifyTickets={selectedNotifyTickets}/>
                        </td>
                    </tr>}
                </table>
            </div>
            <Divider />
            <div>
                <Collapse collapsible="icon" expandIconPosition="right" defaultActiveKey={['1']} ghost>
                    <Panel header={pannelHeader("Passenger Details")} key="1">
                        {isValidArrayWithValues(travellerData)&&<div>
                        <Table
                            dataSource={travellerData}
                            columns={passengerColumns}
                            rowSelection={passengerRowSelection}
                            rowKey={(record) => {
                                return record.id;
                            }}
                            bordered = {false}
                            pagination = {false}
                        />
                        {isCancelTicketAllowed&&cancelledAllowed&&cancelDetails.totalTicketCost>0&&
                        <div>
                            <Row gutter={[16,0]}>
                                <Col>Amount Paid : {cancelDetails.totalTicketCost}</Col>
                            </Row>
                            {cancelDetails.totalCancellationPenalty>0&&
                                <Row gutter={[16,0]}>
                                    <Col>Cancellation Penalty : {cancelDetails.totalCancellationPenalty}</Col>
                                </Row>
                            }
                            {cancelDetails.totalCancellationPenalty>0&&
                                <Row gutter={[16,0]}>
                                    <Col>Refund Amount : {cancelDetails.totalRefundAmount}</Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <CustomModal
                                        buttonName={"Cancel"}
                                        buttonClassName={""}
                                        onButtonClick={()=>{
                                            if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
                                                return true;
                                            }else{
                                                message.error("Please atleast one traveller!")
                                                return false;
                                            }
                                        }}
                                        children={<CancelAlertBody totalCancellationPenalty={cancelDetails.totalCancellationPenalty} sendNotification={sendNotification} setSendNotification={setSendNotification} setCancellationPenality={setCancellationPenality} cancellationPenality={cancellationPenality} isNotBoardedBody={false} />}
                                        onOk={async ()=>{
                                            setLoading(true);
                                            let partialCancelStatus=selectedTravellers.length===travellerData.length?false:true;
                                            let formatedTrallers=getFormatedTravells(selectedTravellers)
                                            let isSendNotication=checkActionAllowed(ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED)?sendNotification:false;
                                            let response=await cancelTicket(pnrFetchedData[selectedPNR].ticket,formatedTrallers,partialCancelStatus,isSendNotication,cancellationPenality,null,onSuccess);
                                            setLoading(false);
                                            return response;
                                        }}
                                        okButtonName={"Yes"}
                                        exitButtonName={"No"}
                                        modalTitle="Cancel Ticket"
                                    />
                                </Col>
                            </Row>
                        </div>
                        }
                        </div>
                        }
                    </Panel>
                </Collapse>
            </div>
            <Divider />
            <div>
                <Collapse collapsible="icon" expandIconPosition="right" defaultActiveKey={['2']} ghost>
                    <Panel header={pannelHeader("Payment Details")} key="2">
                    {isValidArrayWithValues(travellerData)&&<Table
                            dataSource={travellerData}
                            // dataSource={TicketData.travellerData}
                            columns={paymentColumns}
                            bordered = {false}
                            pagination = {false}
                            summary={(pageData) => {
                                let totalFare = 0, totalPenalty = 0;
                                let totalGST = 0, totalRefund = 0, totalCommission = 0;
                                let totalTicketCost = 0, netAmount = 0;
                                pageData.forEach((rowData) => {
                                    const {
                                        discounted_fare, total_gst, amount_paid, cancellation_penalty, net_commission, net_amount, cancellation_time
                                    } = rowData;
                                    if (isValid(cancellation_time)) {
                                        totalPenalty+=cancellation_penalty;
                                        totalRefund+=(amount_paid-cancellation_penalty);
                                        netAmount+=cancellation_penalty
                                    }
                                    else {
                                        totalFare += discounted_fare;
                                        totalGST += total_gst;
                                        totalTicketCost+= amount_paid
                                        totalCommission+= net_commission
                                        if (isValid(net_commission)) {
                                            netAmount+=(amount_paid-net_commission)
                                        }
                                        else {
                                            netAmount+=amount_paid
                                        }
                                    }
                                });
                                return (
                                <>
                                    <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={2}>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                        <Text style={{fontWeight: 'bold'}}>{totalFare}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        <Text style={{fontWeight: 'bold'}}>{totalGST}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        <Text style={{fontWeight: 'bold'}}>{roundNumber(totalTicketCost)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                        <Text style={{fontWeight: 'bold'}}>{roundNumber(totalPenalty)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                        <Text style={{fontWeight: 'bold'}}>{roundNumber(totalRefund)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                        <Text style={{fontWeight: 'bold'}}>{roundNumber(totalCommission)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                        <Text style={{fontWeight: 'bold'}}>{roundNumber(netAmount)}</Text>
                                    </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                                );
                            }}
                        />}
                    </Panel>
                </Collapse>
            </div>
            <Divider />
            {
                isValid(returnTicketId)&&
                <div>
                    <div>
                        <Collapse collapsible="icon" expandIconPosition="right" ghost onChange= {()=> {
                                if (!isValidArrayWithValues(returnTicketData)) {
                                    fetchReturnTicketById(returnTicketId);
                                }
                            }}>
                            <Panel header={pannelHeader("Same Transaction Tickets")} key="3">
                                <Table
                                    dataSource={returnTicketData}
                                    columns={returnColumns}
                                    bordered = {false}
                                    pagination = {false}
                                />
                            </Panel>
                        </Collapse>
                    </div>
                    <Divider />
                </div>
            }
            {isValidArrayWithValues(ticketLogsData)&&<div>
                <div>
                    <Collapse collapsible="icon" expandIconPosition="right" defaultActiveKey={['4']} ghost>
                        <Panel header={pannelHeader("Timeline")} key="4">
                            <div className= "timeline_container">
                                <Timeline mode={"left"}>
                                    {ticketLogsData}
                                </Timeline>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <Divider />
            </div>}
        </div>
    )
}